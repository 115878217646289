import axios from 'axios';

import localDB from './localDB';
import { updateProfileData, updateLocations, updateLeadsTypeFilter, logOut } from '../stores/profileSlice';
import { updateLeadsList, updateIsLoadingLeads } from '../stores/leadsSlice';
import { URLS, ERRORS, EVENTS, LEADS } from '../appConfig';
import { formatCNPJ } from '../uteis/formatters';

const { URL_API } = URLS;

const checkLocalData = async (dispatch) => {
  const { getStorageData } = localDB(); //
  const { profileData, locationsData, leadsProfileData } = await getStorageData();

  if (profileData.id) {
    dispatch(updateProfileData(profileData));
    dispatch(updateLocations(locationsData));
    dispatch(updateLeadsTypeFilter(leadsProfileData));
  }

  return { profileData, locationsData, leadsProfileData };
};

const saveLocalData = async (dispatch, data) => {
  const { profileData, locationsData, leadsProfileData } = data;

  if (!profileData && !locationsData && !leadsProfileData) return;

  const { saveStorageData } = localDB();

  await saveStorageData({ profileData, locationsData, leadsProfileData });

  if (profileData) dispatch(updateProfileData(profileData));
  if (locationsData) dispatch(updateLocations(locationsData));
  if (leadsProfileData) dispatch(updateLeadsTypeFilter(leadsProfileData));
};

const checkLeadsData = async (dispatch, profileData) => {
  if (!profileData?.id) return;

  dispatch(updateIsLoadingLeads(true));

  const leadsData = await getLeads(profileData);

  if (leadsData?.pedidos) {
    // Filtra os pedidos para remover os que já foram todos vistos (status == -1)
    const filteredLeads = leadsData.pedidos.filter((lead) => lead.status !== -1);
    dispatch(updateLeadsList(filteredLeads));
  } else {
    if (leadsData?.erro?.code === ERRORS.PERFIL_INVALIDO) {
      EVENTS.globalEvents.logOut({
        title: 'Perfil desconectado',
        message: leadsData?.erro?.message,
      });
    } else {
      dispatch(updateLeadsList(null));
    }
  }

  dispatch(updateIsLoadingLeads(false));
};

const doLogout = async (dispatch) => {
  const { clearData } = localDB();

  await clearData();

  dispatch(logOut());
};

////////////////////////////////////////////////////////////
// API CALLS
const checkConnection = async () =>
  axios
    .get(URL_API.CHK_CONNECT)
    .then((response) => {
      return true;
    })
    .catch((err) => {
      return false;
    });

const validateLogin = async (cnpj, pass, fbAppToken) => {
  const dataForm = new FormData();
  dataForm.append('cnpj', formatCNPJ(cnpj));
  dataForm.append('senha', pass);
  // console.log('fbAppToken', fbAppToken);
  if (fbAppToken) dataForm.append('fbapptoken', fbAppToken);

  return axios
    .post(URL_API.LOGIN, dataForm)
    .then((response) => {
      // console.log('DADOS... ', response.data);
      return response.data;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      return { erro };
    });
};

const sendPassword = async (cnpj) => {
  const dataForm = new FormData();
  dataForm.append('cnpj', formatCNPJ(cnpj));

  return axios
    .post(URL_API.REENVIA_SENHA, dataForm)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      return { erro };
    });
};

/**
 * Recebe
 * dados.id : INT - id do parceiro
 * dados.cnpj : STRING
 * dados.pass : STRING
 */
const getCurrentBalance = async (profileData) => {
  const dataForm = new FormData();
  dataForm.append('id', profileData.id);
  dataForm.append('cnpj', formatCNPJ(profileData.cnpj));
  dataForm.append('senha', profileData.pass);

  return axios
    .post(URL_API.PEGA_SALDO, dataForm)
    .then((response) => {
      // response.data.saldo
      return response.data;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      return { erro };
    });
};

/**
 * Recebe
 * data.id : INT - id do parceiro
 * data.cnpj : STRING
 * data.senha : STRING
 */
const getLeads = async (profileData) => {
  const dataForm = new FormData();

  dataForm.append('id', profileData.id);
  dataForm.append('cnpj', formatCNPJ(profileData.cnpj));
  dataForm.append('senha', profileData.pass);

  return axios
    .post(URL_API.LISTA_PEDIDOS, dataForm)
    .then((response) => {
      // response.data.pedidos
      // console.log('RESULTADO: ',response.data)
      return response.data;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      console.error('ERR ', err);
      return { erro: erro };
    });
};

/**
 * Recebe
 * data.id : INT - id do parceiro
 * leadId : INT - id do pedido aberto
 */
const getLeadStatus = async (profileData, leadId) => {
  const dataForm = new FormData();
  dataForm.append('id', profileData.id);
  dataForm.append('idpedido', leadId);

  return axios
    .post(URL_API.PEGA_STATUS_PEDIDO, dataForm)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      return { erro: erro };
    });
};

/**
 * Recebe
 * data.id : INT - id do parceiro
 * data.cnpj : STRING
 * data.pass : STRING
 */
const getLocations = async (profileData) => {
  const dataForm = new FormData();
  dataForm.append('id', profileData.id);
  dataForm.append('cnpj', formatCNPJ(profileData.cnpj));
  dataForm.append('senha', profileData.pass);

  return axios
    .post(URL_API.CARREGA_LOCAIS, dataForm)
    .then((response) => {
      return response.data; // return results;
    })
    .catch((err) => {
      const erro = new Error('Erro no serviceApi');
      return { erro: erro };
    });
};

/**
 * Recebe
 * profileData.id : INT - id do parceiro
 * profileData.cnpj : STRING
 * profileData.senha : STRING
 * location.idl : INT - id do local dentro da lista ( 1 | 2 | 3 | 4 | 5 )
 * location.iduf : INT - id do estado
 * location.idcdd : INT - id da cidade
 */
const saveLocations = async (profileData, location, fbAppToken, fbChannelTo_Remove, fbChannelTo_Subscribe) => {
  const dataForm = new FormData();
  // console.log('{saveLocations} location', location);
  // console.log('{saveLocations} fbAppToken', fbAppToken);
  // console.log('{saveLocations} fbChannelTo_Remove', fbChannelTo_Remove);
  // console.log('{saveLocations} fbChannelTo_Subscribe', fbChannelTo_Subscribe);
  // dataForm.append('cnpj', formatCNPJ(profileData.cnpj));
  dataForm.append('cnpj', formatCNPJ(profileData.cnpj));
  dataForm.append('senha', profileData.pass);
  dataForm.append('id', profileData.id);
  dataForm.append('idlocal', location.idl);
  dataForm.append('iduf', location.iduf);
  dataForm.append('idcdd', location.idcdd);

  if (fbAppToken) {
    dataForm.append('fbapptoken', fbAppToken);
    dataForm.append('fbchannelto_remove', fbChannelTo_Remove);
    dataForm.append('fbchannelto_subscribe', fbChannelTo_Subscribe);
  }

  return axios
    .post(URL_API.SALVA_LOCAIS, dataForm)
    .then((response) => {
      return response.data; // return results;
    })
    .catch((err) => {
      // console.log('no error SALVAR LOCAIS ', err.message);
      const erro = new Error('Erro no serviceApi');
      return { erro };
    });
};

const API = {
  checkConnection,
  validateLogin,
  sendPassword,
  getCurrentBalance,
  getLeads,
  getLeadStatus,
  getLocations,
  saveLocations,
};

export { checkLocalData, saveLocalData, checkLeadsData, doLogout };

export default API;
