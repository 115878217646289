import { configureStore } from '@reduxjs/toolkit';
import profileReducer from './profileSlice';
import leadsReducer from './leadsSlice';

export default configureStore({
  reducer: {
    profile: profileReducer,
    leads: leadsReducer,
  },
});
