import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const LoginStyled = styled.div`
  /* background-color: #463858; */
  height: 100vh;
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: flex-start;

  // gera um background com degrade vertical partindo da cor inicial com opacidade 1 até a cor final com opacidade 0
  background: linear-gradient(
    180deg,
    rgba(70, 56, 88, 1) 0%,
    rgba(70, 56, 88, 1) 25%,
    rgba(70, 56, 88, 0) 25%,
    rgba(70, 56, 88, 0) 100%
  );

  .contents {
    width: 100%;
    height: auto;
    /* margin-top: 100px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo-container {
      height: 55px;
      width: 100%;
      margin-top: 30px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
`;

const LoginFieldsStyled = styled.div`
  width: 80%;
  border-radius: 3px;
  background-color: #fff;
  padding: 25px;
  color: black;
  padding: 20px;

  .title {
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 1.3;
  }

  .text-info {
    margin-top: 10px;
    font-size: 17px;
    color: ${COLORS.app.greyText};
    line-height: 1.3;
  }

  .buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const LoginTextResponseStyled = styled.div`
  width: 90%;
  color: ${(props) => (props.alert ? COLORS.app.redAlert : COLORS.app.white)};
  text-align: center;
  font-size: 16px;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .title {
    font-weight: bold;
    margin-bottom: 6px;
    max-width: 100%;
    border-bottom: 1px solid ${(props) => (props.alert ? COLORS.app.redAlert : COLORS.app.greyTextButton)};
    padding: 0 10px;
  }
`;

const LoginLoadingStyled = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;

  .info {
    font-size: 18px;
    margin-top: 15px;
    color: ${COLORS.app.greyTextButton};
    text-align: center;
  }
`;

export { LoginStyled, LoginFieldsStyled, LoginTextResponseStyled, LoginLoadingStyled };
