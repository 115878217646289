import React, { useState, useEffect } from 'react';
import { IoMdArrowRoundForward } from 'react-icons/io';
import { NotificationContentStyled } from './NotificationContentStyled';

const NotificationContent = ({ leadId, cityFrom, cityTo }) => {
  return (
    <NotificationContentStyled>
      <div>
        Novo Pedido{' '}
        <span className="notification-lead">
          <b>{leadId ?? ''}</b>
        </span>
      </div>
      <div className="notification-route">
        {cityFrom ?? ''}
        <div className="progress-arrow-icon">
          <IoMdArrowRoundForward size={15} />
        </div>
        {cityTo ?? ''}
      </div>
    </NotificationContentStyled>
  );
};

export default NotificationContent;
