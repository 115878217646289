import React, { useEffect, useState, useRef } from 'react';

import { HeaderStyled } from './HeaderStyled';

const Header = ({ title, bgColor, iconButton, onClickButton, addOn }) => {
  const [rotateIcon, setRotateIcon] = useState(false);

  useEffect(() => {
    if (addOn && rotateIcon) {
      setTimeout(() => {
        setRotateIcon(false);
      }, 1000);
    }
  }, [rotateIcon]);

  return (
    <HeaderStyled bgColor={bgColor}>
      <div
        className="header-icon"
        onClick={onClickButton}
      >
        {iconButton}
      </div>
      <div className="header-content">{title}</div>
      {addOn && (
        <div
          className="header-addon"
          onClick={() => {
            setRotateIcon(true);
            addOn.onClick();
          }}
        >
          <div className={'header-addon--icon ' + (rotateIcon ? 'rotate-icon' : '')}>{addOn.icon}</div>
        </div>
      )}
    </HeaderStyled>
  );
};
export default Header;
