import React from 'react';

import { ModalM, ButtonM } from '../../../components/misc/muimm';
import { BsFillExclamationTriangleFill } from 'react-icons/bs';

import { LAYOUT } from '../../../appConfig';
import { ModalLogoutStyled } from './ModalLogoutStyled';

const { COLORS, SIZES } = LAYOUT;

const ModalLogout = ({ isOpen, onClose, onLogout, forcedLogout }) => {
  return (
    <ModalM isOpen={isOpen} onClose={onClose}>
      <ModalLogoutStyled>
        <div className="modal-logout--icon">
          <BsFillExclamationTriangleFill size={40} />
        </div>
        <div className="modal-logout--text">
          {forcedLogout ? (
            <>
              <div>
                <b>{forcedLogout?.title ?? ''}</b>
              </div>
              <div>{forcedLogout?.message ?? ''}</div>
            </>
          ) : (
            'Confirma desconectar sua conta deste aplicativo?'
          )}
        </div>
        <div className="modal-logout--buttons">
          {!forcedLogout ? <ButtonM onClick={onLogout}>Desconectar</ButtonM> : <div></div>}
          <ButtonM onClick={onClose} textColor={COLORS.app.greyTextButton}>
            {forcedLogout ? 'Fechar' : 'Cancelar'}
          </ButtonM>
        </div>
      </ModalLogoutStyled>
    </ModalM>
  );
};

export default ModalLogout; //
