import { forEach } from 'lodash';
import { getStateName, getCityName } from './locations';

const slugify = (str) => {
  str = str.replace(/^\s+|\s+$/g, ''); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = 'àáãäâèéëêìíïîòóöôùúüûñç·/_,:;';
  var to = 'aaaaaeeeeiiiioooouuuunc------';

  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str;
};

const formatCNPJ = (nros) => {
  let mask = 'oo.ooo.ooo/oooo-oo';
  var saida = '';
  var ct = 0;

  mask.split('').map((a, i) => (saida += a === 'o' ? nros.charAt(ct++) : a));

  return saida;
};

const formatChannels = (locations) => {
  var channels = [];

  locations.forEach((item) => {
    const { idcdd, iduf } = item;

    if (idcdd !== 0) {
      const uf = getStateName(iduf).toLowerCase();
      const cdd = slugify(getCityName(iduf, idcdd));
      channels.push(`${uf}-${cdd}-${idcdd}`);
    }
  });

  return channels;
};

export { slugify, formatCNPJ, formatChannels };
