import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useBlockTouchMove from '../../uteis/useBlockTouchMove';
import API from '../../services';
import { selectProfileData, selectPurchaseBlocked, updateCurrentBalance } from '../../stores/profileSlice';
import { selectLeadsList, updateLeadsList } from '../../stores/leadsSlice';

import { FiArrowLeft } from 'react-icons/fi';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

import Header from '../Header';
import { formatCNPJ } from '../../uteis/formatters';
import { LAYOUT, EVENTS, URLS, LEADS } from '../../appConfig';
import { LeadContentWindowStyled } from './LeadContentWindowStyled';

const { COLORS, SIZES } = LAYOUT;
const { EVENTS_TYPES, globalEvents } = EVENTS;
const { URL_API } = URLS;

const FrameContent = ({ postData, url, onLoad }) => {
  useEffect(() => {
    if (postData && url && onLoad) sendData();
  }, [postData, url, onLoad]);

  const iframeRef = useRef(null);

  const sendData = () => {
    // inicia removendo todos os elementos filhos do elemento referenciado por 'iframeRef'.
    while (iframeRef.current.firstChild) {
      iframeRef.current.removeChild(iframeRef.current.firstChild);
    }

    const form = document.createElement('form');
    const iframe = document.createElement('iframe');

    iframeRef.current.appendChild(iframe); // Adiciona o 'iframe' ao elemento referenciado por 'iframeRef'.

    form.method = 'POST';
    form.action = url;

    for (const key in postData) {
      if (postData.hasOwnProperty(key)) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = key;
        input.value = postData[key];
        form.appendChild(input);
      }
    }

    iframe.onload = () => {
      onLoad();
    };

    // configura as propriedades de altura, largura e borda do 'iframe'

    iframe.style.width = '100%';
    iframe.style.height = '100%';
    iframe.style.border = 'none';

    iframe.contentDocument.body.appendChild(form);

    form.submit();

    return () => {
      iframe.onload = null; // Cleanup to avoid memory leaks.
    };
  };

  return (
    <div
      ref={iframeRef}
      style={{ border: 'none', width: '100%', height: '100%' }}
    ></div>
  );
};

const LeadContentWindow = () => {
  const hRef = useRef(null);
  const cRef = useRef(null);
  useBlockTouchMove(hRef);
  useBlockTouchMove(cRef, true);

  const [leadData, setLeadData] = useState(null); // { leadId, cost }
  const [isOpenLeadWindow, setIsOpenLeadWindow] = useState(false);
  const [leadOpenedId, setLeadOpenedId] = useState(0);
  const [leadOpenedStatus, setLeadOpenedStatus] = useState(undefined);
  const [isLoaded, setIsLoaded] = useState(false);

  const profileData = useSelector(selectProfileData);
  const isPurchaseBlocked = useSelector(selectPurchaseBlocked);
  const leadsList = useSelector(selectLeadsList);
  const dispatch = useDispatch();

  useEffect(() => {
    if (leadData) loadLeadData();
  }, [leadData]);

  useEffect(() => {
    document.addEventListener(EVENTS_TYPES.openLeadDetails, onOpenLeadWindow);
    return () => {
      document.removeEventListener(EVENTS_TYPES.openLeadDetails, onOpenLeadWindow);
    };
  }, []);

  const updateLeadStatus = (status) => {
    const newLeadsList = leadsList.map((lead) => {
      if (lead.idpedido === leadOpenedId) {
        const pedidosVistos = lead.pedidosVistos + (status === LEADS.status.already_viewed ? 1 : 0);

        return { ...lead, status, pedidosVistos };
      }

      return lead;
    });

    dispatch(updateLeadsList(newLeadsList));
  };

  const onOpenLeadWindow = (e) => {
    const { leadId, cost, status } = e.detail;
    const { cnpj, id, pass } = profileData;

    const ldt = {
      id,
      idpedido: leadId,
      custo: cost,
      cnpj: formatCNPJ(cnpj),
      senha: pass,
      bloqueado: isPurchaseBlocked,
    };

    setLeadData((prevState) => ({ ...prevState, ...ldt }));
    setIsOpenLeadWindow(true);
    setLeadOpenedId(leadId);
    setLeadOpenedStatus(status);
  };

  const onCloseLeadWindow = async () => {
    // ao fechar a janela atualiza o novo status do lead aberto
    const leadOpened = await API.getLeadStatus(profileData, leadOpenedId);

    if (!leadOpened?.erro && leadOpened.status !== leadOpenedStatus) {
      updateLeadStatus(leadOpened.status);

      // se atualizar o status também atualiza o saldo
      dispatch(updateCurrentBalance(leadOpened.saldo));
    }

    setIsOpenLeadWindow(false);
    setLeadData(null);
    setLeadOpenedId(0);
    setLeadOpenedStatus(undefined);

    globalEvents.closeLeadDetails();

    setTimeout(() => {
      setIsLoaded(false);
    }, 1000);
  };

  const onLoadLeadData = () => {};

  const loadLeadData = () => {
    setIsLoaded(true);
  };

  return (
    <LeadContentWindowStyled isOpen={isOpenLeadWindow}>
      <div
        className="content-header"
        ref={hRef}
      >
        <Header
          bgColor={COLORS.lead.violet_3}
          title="Detalhes do Pedido"
          iconButton={<FiArrowLeft size={SIZES.icons} />}
          onClickButton={onCloseLeadWindow}
        />
      </div>
      <div
        className="content-window"
        ref={cRef}
      >
        <div className={'content-window--frame ' + (isLoaded ? 'frame-loaded' : '')}>
          <FrameContent
            postData={leadData}
            url={URL_API.PEGA_PEDIDO}
            onLoad={onLoadLeadData}
          />
        </div>
        <div className={'content-window--loading ' + (isLoaded ? 'frame-loaded' : '')}>
          <UseAnimations
            animation={loading}
            size={100}
            strokeColor={COLORS.lead.violet_3}
          />
          <div className="window-loading--text">Carregando dados do pedido...</div>
        </div>
      </div>
    </LeadContentWindowStyled>
  );
};

export default LeadContentWindow;
