import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import {
  selectFirebaseDeviceToken,
  selectLocations,
  selectProfileData,
  updateFirebaseDeviceToken,
  PROFILE_DEFAULT_DATA,
} from '../../stores/profileSlice';
import API, { saveLocalData } from '../../services';
import { getAppToken } from '../../services/firebase';
import { notificationStatus, getNotificationStatus } from '../../services/firebase';

import { ERRORS, EVENTS } from '../../appConfig';
import { DataConfigStyled } from './DataConfigStyled';
import { ModalLocation } from './ModalLocation';
import { loop } from '../../uteis/helpers';
import { formatChannels } from '../../uteis/formatters';

import SelectLocation from './SelectLocation';

const totalLocations = Object.keys(PROFILE_DEFAULT_DATA.locations).length;

const locationOperationsTypes = {
  UPDATE: 'UPDATE',
  REMOVE: 'REMOVE',
};

const locationOperations = {
  [locationOperationsTypes.UPDATE]: {
    title: 'Aplicar alerta de cidades',
    text: 'Você confirma que DESEJA RECEBER alertas para pedidos relacionados a cidade de $LOCALIDADE?',
  },
  [locationOperationsTypes.REMOVE]: {
    title: 'Remover alerta de cidades',
    text: 'Você confirma REMOVER O RECEBIMENTO de alertas para pedidos relacionados a cidade de $LOCALIDADE?',
  },
};

const modalResultInfos = {
  success: {
    title: 'Operação realizada',
    text: 'Suas configurações de alerta foram atualizadas com sucesso.',
  },
  error: {
    title: 'Operação não executada!',
    text: 'Ocorreu um erro ao atualizar as informações.',
  },
};

const parseLocationsList = (locations) => {
  const locationsList = [];

  loop(1, totalLocations).forEach((i) => {
    locationsList.push(locations['local_' + i]);
  });

  return locationsList;
};

const formatLocationText = (text, location) => {
  const [initText, endText] = text.split('$LOCALIDADE');
  return (
    <>
      {initText} <b>{location}</b>
      {endText}
    </>
  );
};

const DataConfig = () => {
  const fbDeviceToken = useSelector(selectFirebaseDeviceToken);

  const stateLocations = useSelector(selectLocations);
  const profileData = useSelector(selectProfileData);
  const dispatch = useDispatch();

  const [locations, setLocations] = useState([...parseLocationsList(stateLocations)]);
  const [locationUpdateData, setLocationUpdateData] = useState(null);

  // modal
  const [isModalDialogOpen, setIsModalDialogOpen] = useState(false);
  const [isModalResultOpen, setIsModalResultOpen] = useState(false);
  const [modalResultMsg, setModalResultMsg] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [modalText, setModalText] = useState(null);

  useEffect(() => {
    setLocations([...parseLocationsList(stateLocations)]);
  }, [stateLocations]);

  useEffect(() => {
    if (locationUpdateData) setIsModalDialogOpen(true);
  }, [locationUpdateData]);

  useEffect(() => {
    setIsModalResultOpen(modalResultMsg ? true : false);
  }, [modalResultMsg]);

  const doUpdateLocation = async () => {
    if (!locationUpdateData) return;

    // Atualiza dados locais

    const { idl, iduf, idcdd } = locationUpdateData;
    const updatedLocations = {};
    const channelsToRemove = [];
    const channelsToSubscribe = [];

    let channelTo_remove = '';
    let channelTo_subscribe = '';

    locations.forEach((location) => {
      const locId = 'local_' + location.idl;

      updatedLocations[locId] = {
        idl: location.idl,
        iduf: location.idl === idl ? iduf : location.iduf,
        idcdd: location.idl === idl ? idcdd : location.idcdd,
      };

      if (location.idl === idl) {
        if (idcdd !== 0) channelsToSubscribe.push(updatedLocations[locId]);
        if (location.idcdd !== 0) channelsToRemove.push({ ...location });
      }
    });

    // Atualiza locais no firebase
    if (channelsToRemove.length) channelTo_remove = formatChannels(channelsToRemove)[0];
    if (channelsToSubscribe.length) channelTo_subscribe = formatChannels(channelsToSubscribe)[0];

    let fbAppToken = null;

    if (fbDeviceToken) {
      fbAppToken = fbDeviceToken;
    } else {
      fbAppToken = await getAppToken();
      dispatch(updateFirebaseDeviceToken(fbAppToken));
    }

    // Atualiza location no endpoint
    const result = await API.saveLocations(
      profileData,
      locationUpdateData,
      fbAppToken,
      channelTo_remove ?? '',
      channelTo_subscribe ?? ''
    );

    setIsModalDialogOpen(false);

    if (result?.erro) {
      if (result.erro.code === ERRORS.PERFIL_INVALIDO) {
        EVENTS.globalEvents.logOut({
          title: 'Perfil desconectado',
          message: result.erro.message,
        });
      } else {
        setModalResultMsg({
          ...modalResultInfos.error,
          text: result.erro.message,
        });
      }
    } else {
      saveLocalData(dispatch, { locationsData: updatedLocations });

      // feedback
      setModalResultMsg({
        ...modalResultInfos.success,
      });
    }
  };

  const onUpdateLocation = (idl, iduf, idcdd, locationName, onRestoreData) => {
    const { title, text } = locationOperations[locationOperationsTypes.UPDATE];

    setModalTitle(title);
    setModalText(formatLocationText(text, locationName));
    setLocationUpdateData((prevState) => ({ ...prevState, idl, iduf, idcdd, onRestoreData }));
  };

  const onRemoveLocation = (idl, locationName, onRestoreData) => {
    const { title, text } = locationOperations[locationOperationsTypes.REMOVE];
    setModalTitle(title);
    setModalText(formatLocationText(text, locationName));
    setLocationUpdateData((prevState) => ({ ...prevState, idl, iduf: 0, idcdd: 0, onRestoreData }));
  };

  const doCancelUpdateLocation = () => {
    locationUpdateData.onRestoreData();
    closeModalOperation();
  };

  const closeModalOperation = (callFeedback) => {
    setIsModalDialogOpen(false);
    setLocationUpdateData(null);

    if (callFeedback) {
      setTimeout(() => {
        setIsModalResultOpen(true);
      }, 500);
    }
  };

  return (
    <>
      <DataConfigStyled>
        <div className="dataconfig-container--header">
          {getNotificationStatus() === notificationStatus.DENIED ? (
            <>
              Você optou por <b>não permitir o recebimento de alerta</b> de pedidos de mudanças para cidades
              selecionadas. Para alterar essa permissão,{' '}
              <b>atualize diretamente nas configurações do seu dispositivo</b>.
            </>
          ) : (
            <>
              Defina <b>até {totalLocations} cidades diferentes</b>. Quando chegar um pedido novo, envolvendo qualquer
              uma delas, seu celular vai receber um alerta.
              {/* <div className="info-hightlight">
                <b>IMPORTANTE:</b> O alerta será recebido conforme as configurações definidas para{' '}
                <b>Região de Atuação</b>, localizada na aba <b>Seus dados</b> da área restrita de sua empresa, no site
                Muda Muda.
              </div> */}
            </>
          )}
        </div>

        <div className="dataconfig-container--content">
          {locations.map((o) => (
            <div
              key={'lct_' + o.idl}
              className="dataconfig-content--select"
            >
              <SelectLocation
                idl={o.idl}
                iduf={o.iduf}
                idcdd={o.idcdd}
                onUpdateData={onUpdateLocation}
                onRemove={onRemoveLocation}
                isDisabled={getNotificationStatus() !== notificationStatus.GRANTED}
              />
            </div>
          ))}
        </div>
      </DataConfigStyled>
      <ModalLocation
        isOpen={isModalDialogOpen}
        title={modalTitle}
        text={modalText}
        onCancel={doCancelUpdateLocation}
        onConfirm={doUpdateLocation}
      />
      <ModalLocation
        isOpen={isModalResultOpen}
        title={modalResultMsg?.title ?? ''}
        text={modalResultMsg?.text ?? ''}
        labelBtConfirm="OK"
        onConfirm={() => {
          setModalResultMsg(null);
        }}
      />
    </>
  );
};

export default DataConfig;
