import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const MainContentStyled = styled.div`
  // utiliza display grid para inserir header e footer fixos e o conteúdo centralizado

  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-rows: 60px auto 70px;
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'content'
    'footer';

  // header
  .maincontent-header {
    grid-area: header;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    z-index: 10;
  }

  // content
  .maincontent-content {
    grid-area: content;
    background-color: ${COLORS.app.greyBg};
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    overflow-y: auto;
  }

  // footer
  .maincontent-footer {
    grid-area: footer;
    background-color: ${COLORS.app.pink};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
  }
`;

const DrawerListStyled = styled.div`
  color: ${COLORS.app.greyText};
`;

const DrawerHeaderStyled = styled.div`
  height: 165px;
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${COLORS.app.blue};

  .drawer-header-avatar {
    width: 70%;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    .drawer-logo {
      margin-bottom: -10px;
    }

    .drawer-close-bt {
      color: white;
      position: absolute;
      top: 10px;
      left: 10px;
    }

    .drawer-app-version {
      color: white;
      opacity: 0.3;
      position: absolute;
      top: 10px;
      right: 10px;
      font-size: 12px;
    }
  }

  .drawer-header-bar {
    width: 100%;
    height: 42px;
    background-color: ${COLORS.app.pink};
  }
`;

const DrawerFooterStyled = styled.div`
  // aplica a largura conforme parametro passado
  width: ${(props) => (props.width ? props.width : '100%')};
  height: 70px;
  background-color: ${COLORS.app.pink};
  color: white;

  // fixa o footer no final da página
  position: fixed;
  bottom: 0;

  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50px auto;
  grid-template-areas: 'icon content';

  // ícone
  .drawer-footer-icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // conteúdo
  .drawer-footer-content {
    grid-area: content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
  }
`;

const FooterMenuStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
`;

export { MainContentStyled, DrawerListStyled, DrawerHeaderStyled, DrawerFooterStyled, FooterMenuStyled };
