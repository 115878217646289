import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const NotificationContentStyled = styled.div`
  line-height: 1.2;

  .notification-lead {
    color: white;
  }

  .notification-route {
    display: flex;
    align-items: center;
    font-weight: bold;

    .progress-arrow-icon {
      width: 21px;
      text-align: center;
      display: flex;
      justify-content: center;
    }
  }
`;

export { NotificationContentStyled };
