import React from 'react';
import { ModalM } from '../../components/misc/muimm';
import { ModalLocationStyled } from './DataConfigStyled';

export const ModalLocation = ({ isOpen, title, text, labelBtCancel, labelBtConfirm, onCancel, onConfirm }) => {
  return (
    <ModalM isOpen={isOpen} width="80%">
      <ModalLocationStyled>
        <div className="modal-location--title">{title}</div>
        <div className="modal-location--text">{text}</div>
        <div className="modal-location--buttons">
          {onCancel && (
            <div className="location-button" onClick={onCancel}>
              {labelBtCancel ?? 'NÃO'}
            </div>
          )}
          <div className="location-button" onClick={onConfirm}>
            {labelBtConfirm ?? 'SIM'}
          </div>
        </div>
      </ModalLocationStyled>
    </ModalM>
  );
};
