import React, { useEffect, useState } from 'react';

import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';
import { LAYOUT } from '../../appConfig';

import LogoMM from '../misc/LogoMM';

import { SplashScreenStyled } from './SplashScreenStyles';

const { COLORS } = LAYOUT;

const CONNECTION = {
  NOT_CHECKED: 0,
  CHECKED_NOT_FOUND: -1,
  CHECKED_FOUND: 1,
};

const SpashScreen = ({ checkConnection, setConnection }) => {
  const [foundConnection, setFoundConnection] = useState(CONNECTION.NOT_CHECKED);

  useEffect(() => {
    handleConnection();
  }, []);

  const handleConnection = async () => {
    // Verifica se o app possui conexão com a internet
    const hasInternet = await checkConnection();
    if (hasInternet) {
      setConnection(true);
      setFoundConnection(CONNECTION.CHECKED_FOUND);
    } else {
      setFoundConnection(CONNECTION.CHECKED_NOT_FOUND);
      // cria uma variável setInterval para verificar a conexão com a internet
      const interval = setInterval(async () => {
        const hasInternet = await checkConnection();
        if (hasInternet) {
          setConnection(true);
          setFoundConnection(CONNECTION.CHECKED_FOUND);
          clearInterval(interval);
        }
      }, 2000);
    }
  };

  return (
    <SplashScreenStyled>
      <div className="block">
        <div className="logo">
          <LogoMM />
        </div>
      </div>
      <div className="block pink">
        <div className="loading">
          {foundConnection !== CONNECTION.CHECKED_FOUND && (
            <UseAnimations
              size={100}
              animation={loading}
              strokeColor={COLORS.app.white}
            />
          )}

          {foundConnection === CONNECTION.NOT_CHECKED && <div className="info">conectando...</div>}
          {foundConnection === CONNECTION.CHECKED_NOT_FOUND && (
            <div className="info">
              Não foi possível conectar.
              <br />
              Aguardando restabelecer conexão...
            </div>
          )}
        </div>
      </div>
    </SplashScreenStyled>
  );
};

export default SpashScreen;
