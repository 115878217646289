import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const SplashScreenStyled = styled.div`
  background-color: ${COLORS.app.blue};
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .block {
    width: 100%;
    height: 50%;
    display: flex;
    justify-content: center;
    align-items: flex-end;

    &.pink {
      background-color: ${COLORS.app.pink};
    }

    .logo {
      width: 70%;
      margin-bottom: -10px;
    }

    .loading {
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-bottom: 50px;
      color: white;
      text-align: center;
    }
  }
`;

export { SplashScreenStyled };
