import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl } from '@mui/material';
import { AiFillMinusCircle } from 'react-icons/ai';

import { LAYOUT } from '../../appConfig';
import { getUFsList, getCitiesList, getCityName } from '../../uteis/locations';
import { SelectLocationStyled } from './DataConfigStyled';

const { COLORS, SIZES } = LAYOUT;

const sxSelect = {
  height: '40px',
  color: COLORS.app.greyTextButton,
  '&:after': { borderBottomColor: COLORS.app.green },
};

const ufList = getUFsList();

const SelectLocation = ({ idl, iduf, idcdd, onUpdateData, onRemove, isDisabled }) => {
  const [uf, setUf] = useState(0);
  const [ufTemp, setUfTemp] = useState(0);
  const [city, setCity] = useState(0);
  const [cityTemp, setCityTemp] = useState(0);
  const [citiesList, setCitiesList] = useState([]);
  const [citiesListTemp, setCitiesListTemp] = useState([]);

  useEffect(() => {
    const ctl = iduf ? getCitiesList(iduf) : [];

    setUf(iduf);
    setUfTemp(iduf);
    setCitiesList(ctl);
    setCitiesListTemp(ctl);
    setCity(idcdd);
    setCityTemp(idcdd);
  }, [iduf, idcdd]);

  const onRestoreData = () => {
    setUf(ufTemp);
    setCity(cityTemp);
    setCitiesList(citiesListTemp);
  };

  const onChangeUf = (e) => {
    const UFid = e.target.value;

    if (parseInt(UFid) === 0) return;

    setUf(UFid);
    setCity(0);
    setCitiesList(getCitiesList(UFid));
  };

  const onChangeCity = (e) => {
    const CITYid = e.target.value;

    if (parseInt(CITYid) === 0) return;

    setCity(CITYid);

    onUpdateData(idl, uf, CITYid, getCityName(uf, CITYid, true), onRestoreData);
  };

  const onRemoveLocation = () => {
    if (!city) return;
    onRemove(idl, getCityName(uf, city, true), onRestoreData);
  };

  return (
    <SelectLocationStyled>
      <div className="selectlocation--title">Localidade {idl}</div>
      <div className="selectlocation--uf">
        <FormControl
          variant="standard"
          sx={{ width: '100%', height: 'auto' }}
        >
          <Select
            value={uf}
            onChange={onChangeUf}
            disabled={isDisabled}
            sx={{
              ...sxSelect,
              color: uf === 0 ? COLORS.app.greyTextButton : COLORS.app.greyText,
            }}
          >
            <MenuItem value="0">UF</MenuItem>
            {ufList.map((UF, idx) => {
              return (
                <MenuItem
                  key={'UF_' + idx}
                  value={UF.id}
                >
                  {UF.name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div className="selectlocation--city">
        <FormControl
          variant="standard"
          sx={{ width: '100%', height: 'auto' }}
        >
          <Select
            value={city}
            onChange={onChangeCity}
            disabled={isDisabled}
            sx={{
              ...sxSelect,
              color: city === 0 ? COLORS.app.greyTextButton : COLORS.app.greyText,
            }}
          >
            <MenuItem value="0">CIDADE</MenuItem>
            {citiesList.map((CITY, idx) => {
              return (
                <MenuItem
                  key={'city_' + idx}
                  value={CITY.id}
                >
                  {getCityName(uf, CITY.id)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>
      <div
        className={'selectlocation--bt-remove ' + (city === 0 || isDisabled ? ' disabled' : '')}
        onClick={onRemoveLocation}
      >
        <AiFillMinusCircle size={SIZES.icons} />
      </div>
    </SelectLocationStyled>
  );
};

export default SelectLocation;
