import styled from 'styled-components';
import { darken } from 'polished';

import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const ProfileStyled = styled.div`
  /* padding: 250px 0 0px; */

  .profile-block {
    display: flex;
    padding: 18px;
    color: ${COLORS.app.greyText};
    line-height: 1.3;
    align-items: flex-start;

    .profile-block--avatar {
      flex: none;
      width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .profile-block--content {
      flex-grow: 1;
      font-size: 15px;

      .profile-block--text {
        margin: 12px 0 5px 0;

        .profile-block--text-title {
          font-weight: bold;
          margin-bottom: 8px;
        }

        .profile-block--text-title {
          font-weight: bold;
          margin-bottom: 4px;
          font-size: 16px;
        }

        .profile-block--text-info {
          margin-bottom: 5px;
        }

        .credits-values {
          font-size: 18px;
          color: ${COLORS.lead.green_1};
          font-weight: bold;

          &.value-alert {
            color: ${COLORS.app.redAlert};
          }
        }

        &:first-child {
          margin-top: 5px;
        }
      }
    }

    &.profile-logout {
      color: ${COLORS.app.pink};
    }

    &.striped {
      background-color: white;
    }
  }
`;

export { ProfileStyled };
