import React from 'react';
import { ButtonBarM } from '../misc/muimm';

import { EVENTS } from '../../appConfig';
import { FooterMenuStyled } from './MainContentStyled';
const { globalEvents } = EVENTS;

const FooterMenu = ({ sections, sectionId }) => {
  return (
    <FooterMenuStyled>
      {sections.map((section) => (
        <ButtonBarM
          key={section.id + '_fbar'}
          label={<b>{section.title}</b>}
          icon={section.icon}
          onClick={() => globalEvents.navigateTo(section.id)}
          height={'100%'}
          width={'calc(100% / ' + sections.length + ')'}
          labelSize="15px"
          color="white"
          isActive={section.id === sectionId}
        />
      ))}
    </FooterMenuStyled>
  );
};

export default FooterMenu;
