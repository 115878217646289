import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leadsList: [],
  leadsAlertList: [],
  isLoadingLeads: false,
  leadOpened: null,
  leadOpenedCost: null,
};

// Actions

const actionLoadingLeads = (state, { payload }) => {
  return {
    ...state,
    isLoadingLeads: payload,
  };
};

const actionLeadsList = (state, { payload }) => {
  return {
    ...state,
    leadsList: payload,
  };
};

const actionLeadsAlertList = (state, { payload }) => {
  return {
    ...state,
    leadsAlertList: payload,
  };
};

const actionLeadOpened = (state, { payload }) => {
  return {
    ...state,
    leadOpened: payload,
  };
};

const actionLeadOpenedCost = (state, { payload }) => {
  return {
    ...state,
    leadOpenedCost: payload,
  };
};

// Reducer export

export const slice = createSlice({
  name: 'leads',
  initialState,
  reducers: {
    updateIsLoadingLeads: actionLoadingLeads,
    updateLeadsList: actionLeadsList,
    updateLeadsAlertList: actionLeadsAlertList,
    updateLeadOpened: actionLeadOpened,
    updateLeadOpenedCost: actionLeadOpenedCost,
  },
});

export const { updateIsLoadingLeads, updateLeadsList, updateLeadsAlertList, updateLeadOpened, updateLeadOpenedCost } =
  slice.actions;

export const selectIsLoadingLeads = (state) => state.leads.isLoadingLeads;
export const selectLeadsList = (state) => state.leads.leadsList;
export const selectLeadsAlertList = (state) => state.leads.leadsAlertList;
export const selectLeadOpened = (state) => state.leads.leadOpened;
export const selectLeadOpenedCost = (state) => state.leads.leadOpenedCost;

export default slice.reducer;
