import React, { useEffect, useState, useRef } from 'react';

import { Button, TextField, Modal, Fade } from '@mui/material';

import { LAYOUT } from '../../appConfig';
import { ButtonBarMStyled, ModalMStyled, ButtonFlatStyled, SwitchMStyled } from './inputsStyled';

const { COLORS } = LAYOUT;

const TextFieldM = ({ label, css, ...props }) => {
  return (
    <TextField
      id="standard-basic"
      label={label}
      variant="standard"
      margin="normal"
      fullWidth
      autoComplete="off"
      inputProps={{
        autoComplete: 'new-password',
        form: {
          autoComplete: 'off',
        },
      }}
      sx={{
        '& .MuiInputBase-root.Mui-focused:after': { borderBottomColor: COLORS.app.green },
        '& .MuiFormLabel-root.MuiInputLabel-root.Mui-focused': { color: COLORS.app.green },
        ...css,
      }}
      {...props}
    />
  );
};

const ButtonM = ({ children, textColor, type, ...props }) => {
  return (
    <Button
      variant={type || 'primary'}
      {...props}
      sx={{
        color: textColor ?? COLORS.app.green,
        padding: '6px',
      }}
    >
      {children}
    </Button>
  );
};

const ButtonBarM = ({ icon, label, color, colorBg, isActive, onClick, width, height }) => {
  return (
    <ButtonBarMStyled
      color={color}
      colorBg={colorBg}
      isActive={isActive}
      onClick={onClick}
      width={width}
      height={height}
    >
      <div className="button-bar-itens">
        <div className="button-bar-icon">{icon}</div>
        <div className="button-bar-label">{label}</div>
      </div>
    </ButtonBarMStyled>
  );
};

const ButtonFlatM = ({ color, colorBg, width, height, fontSize, margin, padding, onClick, borderRadius, children }) => {
  const btRef = useRef(null);
  const [isDown, setIsDown] = useState(false);

  return (
    <ButtonFlatStyled
      ref={btRef}
      color={color}
      colorBg={colorBg}
      width={width}
      height={height}
      fontSize={fontSize}
      onClick={onClick}
      isPressed={isDown}
      margin={margin}
      padding={padding}
      borderRadius={borderRadius}
      onTouchStart={(e) => {
        setIsDown(true);
      }}
      onTouchEnd={(e) => {
        setIsDown(false);
      }}
    >
      <span className="button-content">{children}</span>
    </ButtonFlatStyled>
  );
};

const ModalM = ({ children, isOpen, onClose, width, height, padding, ...props }) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      disableAutoFocus
      {...props}
      sx={{
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Fade in={isOpen}>
        <ModalMStyled
          width={width}
          height={height}
          padding={padding}
        >
          {children}
        </ModalMStyled>
      </Fade>
    </Modal>
  );
};

const SwitchM = ({ label, checked, onChange, marginLeft, marginRight, ...props }) => {
  return (
    <SwitchMStyled
      checked={checked}
      onChange={onChange}
      inputProps={{ 'aria-label': 'controlled' }}
      sx={{
        padding: '0px',
        width: '42px',
        height: '26px',
        marginLeft: marginLeft ?? '0px',
        marginRight: marginRight ?? '0px',
      }}
      {...props}
    />
  );
};

export { TextFieldM, ButtonM, ButtonBarM, ModalM, ButtonFlatM, SwitchM };
