import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { random } from 'lodash';

import { AiFillCheckCircle } from 'react-icons/ai';
import { MdNewReleases, MdRemoveRedEye } from 'react-icons/md';
import { IoMdEyeOff } from 'react-icons/io';
import { BiSolidDownArrow } from 'react-icons/bi';

import { loop } from '../../uteis/helpers';
import { LEADS, LAYOUT, EVENTS } from '../../appConfig';
import { LeadItemStyled } from './LeadsStyled';
import { updateLeadOpened } from '../../stores/leadsSlice';

import { ButtonFlatM } from '../../components/misc/muimm';

const { COLORS, SIZES } = LAYOUT;
const { openLeadDetails } = EVENTS.globalEvents;

const lsPrfx = 'lead-status--';

const statusInfo = {
  ICON: 'icon',
  CLSS: 'clss',
  TEXT: 'text',
  COLOR_BT: 'color_bt',
};

const infosStatusLeads = {
  [lsPrfx + LEADS.status.not_viewed]: {
    [statusInfo.ICON]: <MdNewReleases size={SIZES.icons} />,
    [statusInfo.CLSS]: '',
    [statusInfo.TEXT]: 'Ver o pedido grátis',
    [statusInfo.COLOR_BT]: COLORS.lead.green_1,
  },
  [lsPrfx + LEADS.status.pre_viewed]: {
    [statusInfo.ICON]: <MdRemoveRedEye size={SIZES.icons} />,
    [statusInfo.CLSS]: 'lead-status--viewed',
    [statusInfo.TEXT]: 'Já vi o pedido',
    [statusInfo.COLOR_BT]: COLORS.lead.green_1,
  },
  [lsPrfx + LEADS.status.already_viewed]: {
    [statusInfo.ICON]: <AiFillCheckCircle size={SIZES.icons} />,
    [statusInfo.CLSS]: 'lead-status--contacted',
    [statusInfo.TEXT]: 'Já vi o contato',
    [statusInfo.COLOR_BT]: COLORS.lead.grey_8,
  },
  [lsPrfx + LEADS.status.in_limit]: {
    [statusInfo.ICON]: <IoMdEyeOff size={SIZES.icons} />,
    [statusInfo.CLSS]: 'lead-status--limited',
    [statusInfo.TEXT]: 'LIMITE DE EMPRESAS ATINGIDO',
    [statusInfo.COLOR_BT]: COLORS.app.pink,
  },
};

const getStatusInfo = (status, info) => infosStatusLeads[lsPrfx + status][info];

const LeadItem = ({ data }) => {
  const dispatch = useDispatch();

  const { idpedido, pedido, custo, status, pedidosTotais, pedidosVistos, datae, datam, origem, destino } = data;
  const isInLimit = status === LEADS.status.in_limit;

  const clssStts = getStatusInfo(status, statusInfo.CLSS);
  const iconStts = getStatusInfo(status, statusInfo.ICON);
  const txtStts = getStatusInfo(status, statusInfo.TEXT);
  const colorBtStts = getStatusInfo(status, statusInfo.COLOR_BT);

  const onOpenLeadsDetails = () => {
    if (status !== LEADS.status.in_limit) {
      openLeadDetails(idpedido, custo, status);
      dispatch(updateLeadOpened(pedido));
    }
  };

  return (
    <LeadItemStyled>
      <div className="lead-item--header">
        <div className={'lead-item--avatar ' + clssStts}>{iconStts}</div>

        <div className="lead-item--info">
          <div className="item-info--date">{datae}</div>
          <div className="item-info--title">Pedido</div>
          <div className="item-info--number">{pedido}</div>
        </div>

        <div className="lead-item--available">
          <div className="item-available--view-limited">
            {loop(1, pedidosTotais).map((i) => {
              const pKey = random(10000);
              return (
                <div
                  key={i + '_li_' + pKey}
                  className={'view-limited--box ' + (i <= pedidosVistos ? 'is-filled ' : '') + clssStts}
                ></div>
              );
            })}
          </div>

          {!isInLimit && (
            <div className="item-available--info">
              Ainda pode ser visto por <br /> <span className="info-highlight">{pedidosTotais - pedidosVistos}</span> de{' '}
              <span className="info-highlight">{pedidosTotais}</span> empresas
            </div>
          )}
        </div>
      </div>

      <div
        className={'lead-item--move-date ' + clssStts}
        onClick={onOpenLeadsDetails}
      >
        Mudança em {datam}
      </div>

      <div
        className={'lead-item--locations ' + clssStts}
        onClick={onOpenLeadsDetails}
      >
        <div className={'item-location--place ' + clssStts}>{origem}</div>
        <div className="item-location--arrow">
          <BiSolidDownArrow size={16} />
        </div>
        <div className={'item-location--place ' + clssStts}>{destino}</div>
      </div>

      <div className={'lead-item--btfooter ' + clssStts}>
        {status === LEADS.status.in_limit ? (
          <div className="item-btfooter--limited">
            <div className="btfooter-text">{txtStts}</div>
          </div>
        ) : (
          <ButtonFlatM
            colorBg={colorBtStts}
            color={COLORS.app.white}
            width="100%"
            height="100%"
            fontSize="14px"
            margin="0"
            padding="0"
            onClick={onOpenLeadsDetails}
          >
            {txtStts}
          </ButtonFlatM>
        )}
      </div>
    </LeadItemStyled>
  );
};

export default LeadItem;
