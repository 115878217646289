import styled from 'styled-components';
import { createGlobalStyle } from 'styled-components';
import { LAYOUT } from './appConfig';

const { COLORS } = LAYOUT;

const GlobalStyle = createGlobalStyle`

  :root {
    /* --toastify-color-info: ${COLORS.app.blue}; */
    /* --toastify-color-info: ${COLORS.components.orange_2}; */
    --toastify-color-info: white;
  }

  .progress-notification {
    /* background: #9b92cf; */
    background: #f39cb3;
  }

  .body-notification{
    font-size: 14px;
  }

  .class-notification{
    background: rgb(173,0,49);
    background: linear-gradient(0deg, rgba(173,0,49,1) 0%, rgba(254,32,91,1) 65%);
    color: white;
  }

`;

const AppContainer = styled.div`
  background-color: ${COLORS.app.blue};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  /* overflow: hidden; */
`;

export { AppContainer, GlobalStyle };
