import { encode, decode } from 'js-base64';

import { PROFILE_DEFAULT_DATA } from '../stores/profileSlice';

const DB_NAME = 'MMAppDb';
const DB_VERSION = 2;
const DB_TRANSACTIONS_MODE = {
  READ: 'readonly',
  WRITE: 'readwrite',
};
const DB_STORE = {
  LOCATIONS: {
    store: 'storeLocations',
    key: 'locations',
    pk: 1,
  },
  PROFILE: {
    store: 'storeProfile',
    key: 'profile',
    pk: 1,
  },
  LEADS: {
    store: 'storeLeads',
    key: 'leads',
    pk: 1,
  },
};

const localDB = () => {
  const { LOCATIONS, PROFILE, LEADS } = DB_STORE;

  const transaction = (db, mode) => {
    const tx = db.transaction([PROFILE.store, LOCATIONS.store, LEADS.store], mode);
    const profileStore = tx.objectStore(PROFILE.store);
    const locationsStore = tx.objectStore(LOCATIONS.store);
    const leadsStore = tx.objectStore(LEADS.store);

    tx.oncomplete = () => {
      db.close();
    };

    return { profileStore, locationsStore, leadsStore };
  };

  const openDatabase = () => {
    return new Promise((resolve, reject) => {
      const request = window.indexedDB.open(DB_NAME, DB_VERSION);

      request.onerror = (event) => {
        reject('Error opening database');
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;

        // Ao executar uma atualização do banco, cria as stores somente caso não existam

        if (!db.objectStoreNames.contains(PROFILE.store)) {
          db.createObjectStore(PROFILE.store);
        }
        if (!db.objectStoreNames.contains(LOCATIONS.store)) {
          db.createObjectStore(LOCATIONS.store);
        }
        if (!db.objectStoreNames.contains(LEADS.store)) {
          db.createObjectStore(LEADS.store);
        }

        // Store default data
        const tx = event.target.transaction;
        const profileStore = tx.objectStore(PROFILE.store);
        const locationsStore = tx.objectStore(LOCATIONS.store);
        const leadsStore = tx.objectStore(LEADS.store);

        profileStore.put(PROFILE_DEFAULT_DATA.profileData, LOCATIONS.pk);
        locationsStore.put(PROFILE_DEFAULT_DATA.locations, PROFILE.pk);
        leadsStore.put(PROFILE_DEFAULT_DATA.leadsFilterType, LEADS.pk);
      };

      request.onsuccess = (event) => {
        const db = event.target.result;
        resolve(db);
      };
    });
  };

  const getStorageData = async () => {
    const db = await openDatabase();

    return new Promise((resolve, reject) => {
      const { profileStore, locationsStore, leadsStore } = transaction(db, DB_TRANSACTIONS_MODE.READ);

      const profileRequest = profileStore.get(LOCATIONS.pk); // Get by primary key
      const locationsRequest = locationsStore.get(PROFILE.pk);
      const leadsRequest = leadsStore.get(LEADS.pk);

      profileRequest.onsuccess = (event) => {
        const profileData = event.target.result;

        if (profileData) profileData.pass = decode(profileData.pass);

        locationsRequest.onsuccess = (event) => {
          const locationsData = event.target.result;

          leadsRequest.onsuccess = (event) => {
            const leadsProfileData = event.target.result;

            if (!profileData || !locationsData || !leadsProfileData) {
              reject('Data not found in stores');
              return false;
            }

            resolve({ profileData, locationsData, leadsProfileData });
          };
        };
      };
    });
  };

  const saveStorageData = async ({ profileData, locationsData, leadsProfileData }) => {
    // console.log('[localDB] {saveStorageData} profileData', profileData);
    // console.log('[localDB] {saveStorageData} locationsData', locationsData);
    // console.log('[localDB] {saveStorageData} leadsProfileData', leadsProfileData);

    const db = await openDatabase();

    return new Promise((resolve, reject) => {
      const { profileStore, locationsStore, leadsStore } = transaction(db, DB_TRANSACTIONS_MODE.WRITE);

      if (profileData) {
        const profilePutRequest = profileStore.put(
          {
            ...profileData,
            pass: profileData.pass === '' ? '' : encode(profileData.pass),
          },
          LOCATIONS.pk
        );

        profilePutRequest.onsuccess = () => {
          resolve('ProfileData saved successfully');
        };
      }

      if (locationsData) {
        const locationsPutRequest = locationsStore.put(locationsData, PROFILE.pk);
        locationsPutRequest.onsuccess = () => {
          resolve('LocationsData saved successfully');
        };
      }

      if (leadsProfileData) {
        const leadsPutRequest = leadsStore.put(leadsProfileData, LEADS.pk);
        leadsPutRequest.onsuccess = () => {
          resolve('leadsProfileData saved successfully');
        };
      }
    });
  };

  const clearData = async () => {
    saveStorageData({
      profileData: PROFILE_DEFAULT_DATA.profileData,
      locationsData: PROFILE_DEFAULT_DATA.locations,
      leadsProfileData: PROFILE_DEFAULT_DATA.leadsFilterType,
    });
  };

  return { getStorageData, saveStorageData, clearData };
};

export default localDB;
