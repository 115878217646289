import { createSlice } from '@reduxjs/toolkit';

const leadsFilterTypes = {
  ALL: 'leads_all',
  OUTER_STATES: 'leads_outer_states',
  INNER_STATES: 'leads_inner_states',
};

const PROFILE_DEFAULT_DATA = {
  profileData: { id: null, cnpj: '', pass: '' },
  locations: {
    local_1: { idl: 1, iduf: 0, idcdd: 0 },
    local_2: { idl: 2, iduf: 0, idcdd: 0 },
    local_3: { idl: 3, iduf: 0, idcdd: 0 },
    local_4: { idl: 4, iduf: 0, idcdd: 0 },
    local_5: { idl: 5, iduf: 0, idcdd: 0 },
  },
  leadsFilterType: leadsFilterTypes.ALL,
};

const { profileData, locations, leadsFilterType } = PROFILE_DEFAULT_DATA;

const initialState = {
  isLogged: false,
  purchaseBlocked: 0,
  profileData,
  firebaseDeviceToken: null,
  configData: {
    locations,
    leadsFilterType,
  },
  companyData: {
    cnpjdados: '',
    name: '',
    corporateName: '',
    owner: '',
    contact: '',
    email: '',
    site: '',
    address: '',
    complement: '',
    district: '',
    city: '',
  },
  currentBalance: 0,
};

// Actions

const actionFirebaseDeviceToken = (state, { payload }) => {
  return {
    ...state,
    firebaseDeviceToken: payload,
  };
};

const actionProfileData = (state, { payload }) => {
  const { id, cnpj, pass } = payload;

  return {
    ...state,
    profileData: {
      id,
      cnpj,
      pass,
    },
  };
};

const actionPurchaseBlocked = (state, { payload }) => {
  return {
    ...state,
    purchaseBlocked: payload,
  };
};

const actionCompanyData = (state, { payload }) => {
  const { cnpjdados, name, corporateName, email, owner, contact, site, address, complement, district, city } = payload;

  return {
    ...state,
    isLogged: true,
    companyData: {
      cnpjdados,
      name,
      corporateName,
      email,
      owner,
      contact,
      site,
      address,
      complement,
      district,
      city,
    },
  };
};

const actionConfigLocations = (state, { payload }) => {
  const { local_1, local_2, local_3, local_4, local_5 } = payload;

  return {
    ...state,
    configData: {
      locations: {
        local_1,
        local_2,
        local_3,
        local_4,
        local_5,
      },
    },
  };
};

const actionLeadsTypeFilter = (state, { payload }) => {
  return {
    ...state,
    leadsFilterType: payload,
  };
};

const actionCurrentBalance = (state, { payload }) => {
  return {
    ...state,
    currentBalance: payload,
  };
};

const actionLogOut = (state) => {
  return {
    ...state,
    isLogged: false,
    firebaseDeviceToken: null,
    profileData: {
      ...initialState.profileData,
    },
    companyData: {
      ...initialState.companyData,
    },
    currentBalance: initialState.currentBalance,
  };
};

// exports

export const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateFirebaseDeviceToken: actionFirebaseDeviceToken,
    updateProfileData: actionProfileData,
    updateCompanyData: actionCompanyData,
    updateLeadsTypeFilter: actionLeadsTypeFilter,
    updateLocations: actionConfigLocations,
    updateCurrentBalance: actionCurrentBalance,
    updatePurchaseBlocked: actionPurchaseBlocked,
    logOut: actionLogOut,
  },
});

export const {
  updateFirebaseDeviceToken,
  updateProfileData,
  updateCompanyData,
  updateLeadsTypeFilter,
  updateLocations,
  updateCurrentBalance,
  updatePurchaseBlocked,
  logOut,
} = slice.actions;

export const selectFirebaseDeviceToken = (state) => state.profile.firebaseDeviceToken;
export const selectIsLogged = (state) => state.profile.isLogged;
export const selectProfileData = (state) => state.profile.profileData;
export const selectCompanyData = (state) => state.profile.companyData;
export const selectLocations = (state) => state.profile.configData.locations;
export const selectCurrentBalance = (state) => state.profile.currentBalance;
export const selectPurchaseBlocked = (state) => state.profile.purchaseBlocked;
export const selectLeadsTypeFilter = (state) => state.profile.leadsFilterType;

export { PROFILE_DEFAULT_DATA, leadsFilterTypes };

export default slice.reducer;
