import styled from 'styled-components';

import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const DataConfigStyled = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  .dataconfig-container--header {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    line-height: 1.2;

    color: ${COLORS.app.greyText};

    .info-hightlight {
      margin-top: 12px;
      font-size: 14px;
      color: ${COLORS.lead.violet_1};
    }
  }

  .dataconfig-container--allow-notification {
    width: 100%;
    background-color: #d5cfe8;
    color: #7662b4;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    .allow-notification--txt-result {
      &.result-allowed {
        font-weight: bold;
      }
    }
  }

  .dataconfig-container--content {
    width: 100%;
    padding: 20px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    .dataconfig-content--select {
      width: 100%;
      margin-bottom: 30px;
    }
  }
`;

const SelectLocationStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  .selectlocation--title {
    flex: none;
    width: 100%;
    font-size: 16px;
    color: ${COLORS.lead.violet_1};
  }

  .selectlocation--uf {
    width: 55px;
  }

  .selectlocation--city {
    flex-grow: 1;
    padding-left: 18px;
  }

  .selectlocation--bt-remove {
    width: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: ${COLORS.app.redAlert};

    &.disabled {
      color: ${COLORS.lead.grey_2};
    }
  }
`;

const ModalLocationStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .modal-location--title,
  .modal-location--text,
  .modal-location--buttons {
    width: 100%;
  }

  .modal-location--title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .modal-location--text {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 20px;
  }

  .modal-location--buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .location-button {
      font-size: 18px;
      text-tranform: uppercase;
      color: ${COLORS.app.green};
      margin-left: 20px;
    }
  }
`;

export { DataConfigStyled, SelectLocationStyled, ModalLocationStyled };
