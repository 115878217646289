import React, { useEffect } from 'react';

// Hook personalizado para bloquear o evento 'touchmove'
function useBlockTouchMove(elementRef, blockOnTopOrBottom = false) {
  useEffect(() => {
    let startY = 0;

    const handleTouchStart = (e) => {
      if (elementRef.current && elementRef.current.contains(e.target)) {
        startY = e.touches[0].clientY;
      }
    };

    const handleTouchMove = (e) => {
      // Verifica se o elemento que acionou o evento é o mesmo que o elemento alvo
      if (elementRef.current && elementRef.current.contains(e.target) && e.cancelable) {
        if (blockOnTopOrBottom) {
          // Verifica se o elemento está no topo ou no final
          const { scrollTop, scrollHeight, clientHeight } = elementRef.current;
          const isScrollingTop = scrollTop === 0;
          const isScrollingBottom = scrollHeight - scrollTop === clientHeight;

          const deltaY = e.touches[0].clientY - startY;

          if ((isScrollingTop && deltaY > 0) || (isScrollingBottom && deltaY < 0)) {
            e.preventDefault();
          }

          return;
        }

        e.preventDefault(); // Impede a rolagem apenas dentro do elemento especificado
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    document.addEventListener('touchstart', handleTouchStart);

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchstart', handleTouchStart);
    };
  }, [elementRef]);
}

export default useBlockTouchMove;
