const loop = (init, end = null) => {
  if (end == null) {
    end = init;
    init = 0;
  }

  let arr = [];

  for (let i = init; i <= end; i++) arr.push(i);

  return arr;
};

export { loop };
