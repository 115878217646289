const checkInput = {
  simplePassword: (p) => {
    return !(p === '' || p.length < 6 || p.indexOf(' ') !== -1);
  },
  text: (txt, size = 3) => {
    return !(txt.length === '' || txt.length < size);
  },
  email: (e) => {
    return !(e === '' || e.indexOf(' ') !== -1 || e.slice(-1) === '.');
  },
  phone: (p, required = true) => {
    if (required) {
      return !(p === '' || p.indexOf('\u2000\u2000') !== -1);
    } else {
      return !(p !== '' && p.indexOf('\u2000\u2000') !== -1);
    }
  },
  zipcode: (value) => !(value.length !== 10),
  cpf: (value) => {
    const cpf = value.replace(/[^\d]+/g, '');
    if (
      cpf.length !== 11 ||
      [
        '00000000000',
        '11111111111',
        '22222222222',
        '33333333333',
        '44444444444',
        '55555555555',
        '66666666666',
        '77777777777',
        '88888888888',
        '99999999999',
      ].includes(cpf)
    ) {
      return false;
    }

    let resto,
      soma = 0;

    for (let i = 1; i <= 9; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }

    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++) {
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(cpf.substring(10, 11))) return false;

    return true;
  },
  cnpj: (value) => {
    const cnpj = value.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;

    // Remove invalid knowed CNPJs
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    // Validate DVs
    let size = cnpj.length - 2;
    let nums = cnpj.substring(0, size);
    let sum = 0;
    let pos = size - 7;

    const digits = cnpj.substring(size);

    for (let i = size; i >= 1; i--) {
      sum += nums.charAt(size - i) * pos--;
      if (pos < 2) pos = 9;
    }

    let result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(0), 10)) return false;

    size += 1;
    nums = cnpj.substring(0, size);
    sum = 0;
    pos = size - 7;

    for (let i = size; i >= 1; i--) {
      sum += nums.charAt(size - i) * pos--;

      if (pos < 2) pos = 9;
    }

    result = sum % 11 < 2 ? 0 : 11 - (sum % 11);

    if (result !== parseInt(digits.charAt(1), 10)) return false;

    return true;
  },
  url: (value) => {
    const pattern =
      '^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z\\d]([a-zA-Z\\d-]{0,61}[a-zA-Z\\d])*\\.)+' + // sub-domain + domain name
      '[a-zA-Z]{2,13})' + // extension
      '|((\\d{1,3}\\.){3}\\d{1,3})' + // OR ip (v4) address
      '|localhost)' + // OR localhost
      '(\\:\\d{1,5})?' + // port
      '(\\/[a-zA-Z\\&\\d%_.~+-:@]*)*' + // path
      '(\\?[a-zA-Z\\&\\d%_.,~+-:@=;&]*)?' + // query string
      '(\\#[-a-zA-Z&\\d_]*)?$'; // fragment locator
    const regex = new RegExp(pattern);

    return regex.test(value);
  },
};

export { checkInput };
