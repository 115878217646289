import styled from 'styled-components';

import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const LeadsContainerStyled = styled.div`
  width: 100%;
  color: ${COLORS.app.greyText};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 20px;

  .leads-container--header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    padding-top: 10px;
    background-color: white;

    .header--total-credits {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 8px;

      .header-credits--info {
        width: 100%;
        font-weigth: bold;
        display: flex;
        justify-content: center;
        align-items: center;

        .info-value {
          margin-left: 8px;
          text-align: left;
          color: ${COLORS.lead.green_1};

          &.value-alert {
            color: ${COLORS.app.redAlert};
          }
        }
      }
    }

    .header-buttons--container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;

      .header-buttons--notification-alert {
        height: 20px;
        min-width: 20px;
        padding: 4px 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 10px;
        background-color: ${COLORS.app.pink};
        color: white;
        font-size: 11px;
        font-weight: 600;
        position: absolute;
        top: -7px;
        left: -2px;

        transform-origin: center;
        transition: transform 0.6s cubic-bezier(0.68, -0.55, 0.27, 1.55);
        transform: scale(0);

        &.show--notification {
          transform: scale(1);
        }
      }
    }

    .header-tabs--container {
      width: 100%;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      .header-tabs--tab {
        // para pegar a largura do elemento, utiliza "calc" para subtrair 2px de 100% e, então, divide por 3
        width: calc((100% - 2px) / 3);
        flex: none;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        height: 38px;
        color: #949494;
        font-weight: bold;
        text-align: center;
        line-height: 1.1;
        background-color: #d0d0d0;

        // deixa arredondadas somente as bordas do topo
        border-radius: 5px 5px 0 0;

        &.is-active {
          color: #565656;
          background-color: ${COLORS.app.greyBg};
        }
      }
    }
  }

  .leads-container--content {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 75%;

    .content--is-loading {
      width: 100%;
      height: 130px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      font-size: 15px;
      color: ${COLORS.app.greyTextButton};
    }

    .content--no-leads {
      width: 100%;
      height: 130px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      font-size: 15px;
      color: ${COLORS.app.greyTextButton};
      text-align: center;
    }
  }
`;

const LeadItemStyled = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.3);
  background-color: white;
  border: 2px solid #c1bac8;

  // cria variável com o valor da opacidade
  --opacity-viewed: 0.6;

  .lead-item--header {
    background-color: white;
    padding: 6px 0;
    width: 100%;
    display: grid;
    grid-template-columns: 40px 80px auto;
    grid-template-rows: 100%;
    grid-template-areas: 'avatar info available';

    .lead-item--avatar {
      grid-area: avatar;
      display: flex;
      justify-content: center;
      align-items: flex-start;
      color: ${COLORS.lead.green_1};

      &.lead-status--viewed {
        color: ${COLORS.lead.green_3};
      }

      &.lead-status--contacted {
        color: ${COLORS.lead.grey_8};
      }

      &.lead-status--limited {
        color: ${COLORS.app.pink};
      }
    }

    .lead-item--info {
      grid-area: info;

      .item-info--date {
        font-size: 12px;
        color: ${COLORS.app.greyText};
      }

      .item-info--title {
        font-size: 14px;
        font-weight: bold;
        line-height: 1.1;
        color: ${COLORS.app.greyTextButton};
      }

      .item-info--number {
        font-size: 16px;
        font-weight: bold;
        line-height: 1;
        color: ${COLORS.lead.greyText};
      }
    }

    .lead-item--available {
      grid-area: available;
      width: 100%;
      display: flex;
      flex-direction: column;
      padding-left: 10px;
      justify-content: space-between;
      align-items: center;
      padding-right: 6px;

      .item-available--view-limited {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        .view-limited--box {
          flex-grow: 1;
          flex-basis: 0;
          margin-right: 3px;
          height: 15px;
          border-radius: 5px;
          border: 2px solid ${COLORS.lead.green_1};

          &.lead-status--viewed {
            border-color: ${COLORS.lead.green_3};
          }

          &.lead-status--contacted {
            border-color: ${COLORS.lead.grey_8};
          }

          &.lead-status--limited {
            border-color: ${COLORS.lead.grey_3};
          }

          &.is-filled {
            background-color: ${COLORS.lead.green_1};

            &.lead-status--viewed {
              background-color: ${COLORS.lead.green_3};
            }

            &.lead-status--contacted {
              background-color: ${COLORS.lead.grey_8};
            }

            &.lead-status--limited {
              background-color: ${COLORS.lead.grey_3};
            }
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .item-available--info {
        font-size: 12px;
        color: ${COLORS.app.greyText};
        line-height: 1.1;
        font-weight: 500;
        text-align: center;

        .info-highlight {
          font-weight: 700;
          color: black;
        }
      }
    }
  }

  //

  .lead-item--move-date {
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background-color: ${COLORS.app.blue};

    &.lead-status--viewed {
      opacity: var(--opacity-viewed);
    }

    &.lead-status--limited {
      background-color: ${COLORS.lead.grey_2};
    }

    &.lead-status--contacted {
      background-color: ${COLORS.lead.grey_4};
    }
  }

  //

  .lead-item--locations {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 6px 0;
    background-color: ${COLORS.lead.green_2};

    &.lead-status--viewed,
    &.lead-status--contacted {
      opacity: var(--opacity-viewed);
    }

    &.lead-status--contacted {
      background-color: ${COLORS.lead.grey_6};
    }

    &.lead-status--limited {
      background-color: ${COLORS.lead.grey_3};
    }

    .item-location--place {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-weight: bold;

      color: ${COLORS.app.blue};

      &.lead-status--limited {
        color: ${COLORS.lead.grey_2};
      }
    }

    .item-location--arrow {
      color: ${COLORS.app.white};
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  //

  .lead-item--btfooter {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    border-top: 1px solid white;
    color: white;
    font-weight: bold;
    text-align: center;

    &.lead-status--viewed {
      opacity: var(--opacity-viewed);
    }

    .item-btfooter--regular {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .item-btfooter--limited {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${COLORS.app.pink};

      .btfooter-text {
        opacity: 0.6;
        color: ${COLORS.app.white};
        font-size: 15px;
      }
    }
  }
`;

export { LeadsContainerStyled, LeadItemStyled };
