// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

// import { getAnalytics } from "firebase/analytics";
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAan4Xh3cazWQx-YQ8VoVsCa4eIrobn0BM',
  authDomain: 'mudamuda-app.firebaseapp.com',
  databaseURL: 'https://mudamuda-app.firebaseio.com',
  projectId: 'mudamuda-app',
  storageBucket: 'mudamuda-app.appspot.com',
  messagingSenderId: '995998670328',
  appId: '1:995998670328:web:e6f0aa46430d682cc7ca0d',
  measurementId: 'G-HCLF02BEJ4',
};

// const FCM_TOKEN_COLLECTION = 'fcmTokens';
const FIREBASE_APP_KEY = 'BOmj7Niqab-QyUvmIM9DiFH60NwF9oO57V73b0P8l4MxcCnCFYngX8ZYLDCqb5QX_baLEp-2OSw9RAC1-jC6mck';

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const messaging = async () => (await isSupported()) && getMessaging(app);

export const notificationStatus = {
  DEFAULT: 0,
  GRANTED: 1,
  DENIED: -1,
  NOT_SUPPORTED: -2,
};

// Retorna status da permissão de notificação
export function getNotificationStatus() {
  // retorno 1 == ativo | retorno 0 == inativo | retorno -1 == não suportado
  if ('Notification' in window) {
    if (Notification.permission === 'default') return notificationStatus.DEFAULT; // default
    if (Notification.permission === 'granted') return notificationStatus.GRANTED; // granted
    return notificationStatus.DENIED; // denied
  }
  return notificationStatus.NOT_SUPPORTED;
}

export async function getAppToken() {
  if (window.location.hostname !== 'localhost' && messaging && getNotificationStatus() === notificationStatus.GRANTED) {
    const msg = await messaging();
    const fcmToken = await getToken(msg, { vapidKey: FIREBASE_APP_KEY });
    return fcmToken;
  }
  return null;
}

const applyToMessages = async (callback, ruleValidation = true) => {
  if (window.location.hostname !== 'localhost' && messaging && ruleValidation) {
    try {
      const msg = await messaging();
      const fcmToken = await getToken(msg, { vapidKey: FIREBASE_APP_KEY });

      if (fcmToken) {
        if (callback) callback(msg);
        return 1;
      } else {
        return 0;
      }
    } catch (error) {
      console.error('{applyToMessages} Unable to access message.', error);
      return 0;
    }
  }

  return -1;
};

// Saves the messaging device token to Cloud Firestore.
export async function checkNotificationMessage(callback) {
  return await applyToMessages(async (msg) => {
    // aplica evento de notificação das mensagens
    if (getNotificationStatus() === notificationStatus.GRANTED) {
      onMessage(msg, (message) => {
        callback(message);
      });
    }
  });
}

export async function clearAppBadge() {
  if ('clearAppBadge' in navigator) {
    return await applyToMessages(async (msg) => {
      const { appId } = msg?.firebaseDependencies?.appConfig;

      try {
        navigator.clearAppBadge();
        navigator.serviceWorker.controller.postMessage({ type: 'CLEAR_APP_BADGE' });
      } catch (error) {
        console.error('Failed to clear app badge:', error);
      }
    });
  }
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
