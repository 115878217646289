const UFS = [
  { id: 2, name: 'AC', fullname: 'Acre' },
  { id: 14, name: 'AL', fullname: 'Alagoas' },
  { id: 3, name: 'AM', fullname: 'Amazonas' },
  { id: 6, name: 'AP', fullname: 'Amapá' },
  { id: 16, name: 'BA', fullname: 'Bahia' },
  { id: 10, name: 'CE', fullname: 'Ceará' },
  { id: 27, name: 'DF', fullname: 'Distrito Federal' },
  { id: 18, name: 'ES', fullname: 'Espírito Santo' },
  { id: 26, name: 'GO', fullname: 'Goiás' },
  { id: 8, name: 'MA', fullname: 'Maranhão' },
  { id: 17, name: 'MG', fullname: 'Minas Gerais' },
  { id: 24, name: 'MS', fullname: 'Mato Grosso do Sul' },
  { id: 25, name: 'MT', fullname: 'Mato Grosso' },
  { id: 5, name: 'PA', fullname: 'Pará' },
  { id: 12, name: 'PB', fullname: 'Paraíba' },
  { id: 13, name: 'PE', fullname: 'Pernambuco' },
  { id: 9, name: 'PI', fullname: 'Piauí' },
  { id: 21, name: 'PR', fullname: 'Paraná' },
  { id: 19, name: 'RJ', fullname: 'Rio de Janeiro' },
  { id: 11, name: 'RN', fullname: 'Rio Grande do Norte' },
  { id: 1, name: 'RO', fullname: 'Rondônia' },
  { id: 4, name: 'RR', fullname: 'Roraima' },
  { id: 23, name: 'RS', fullname: 'Rio Grande do Sul' },
  { id: 22, name: 'SC', fullname: 'Santa Catarina' },
  { id: 15, name: 'SE', fullname: 'Sergipe' },
  { id: 20, name: 'SP', fullname: 'São Paulo' },
  { id: 7, name: 'TO', fullname: 'Tocantins' },
];

const UFS_CDDS = [
  {
    id: 2,
    name: 'AC',
    fullname: 'Acre',
    cities: [
      { id: 67, name: 'Rio Branco' },
      { id: 53, name: 'Acrelândia' },
      { id: 54, name: 'Assis Brasil' },
      { id: 55, name: 'Brasiléia' },
      { id: 56, name: 'Bujari' },
      { id: 57, name: 'Capixaba' },
      { id: 58, name: 'Cruzeiro do Sul' },
      { id: 59, name: 'Epitaciolândia' },
      { id: 60, name: 'Feijó' },
      { id: 61, name: 'Jordão' },
      { id: 62, name: 'Mâncio Lima' },
      { id: 63, name: 'Manoel Urbano' },
      { id: 64, name: 'Marechal Thaumaturgo' },
      { id: 65, name: 'Plácido de Castro' },
      { id: 74, name: 'Porto Acre' },
      { id: 66, name: 'Porto Walter' },
      { id: 67, name: 'Rio Branco' },
      { id: 68, name: 'Rodrigues Alves' },
      { id: 69, name: 'Santa Rosa do Purus' },
      { id: 71, name: 'Sena Madureira' },
      { id: 70, name: 'Senador Guiomard' },
      { id: 72, name: 'Tarauacá' },
      { id: 73, name: 'Xapuri' },
    ],
  },
  {
    id: 14,
    name: 'AL',
    fullname: 'Alagoas',
    cities: [
      { id: 1696, name: 'Maceió' },
      { id: 1650, name: 'Água Branca' },
      { id: 1651, name: 'Anadia' },
      { id: 1652, name: 'Arapiraca' },
      { id: 1653, name: 'Atalaia' },
      { id: 1654, name: 'Barra de Santo Antônio' },
      { id: 1655, name: 'Barra de São Miguel' },
      { id: 1656, name: 'Batalha' },
      { id: 1657, name: 'Belém' },
      { id: 1658, name: 'Belo Monte' },
      { id: 1659, name: 'Boca da Mata' },
      { id: 1660, name: 'Branquinha' },
      { id: 1661, name: 'Cacimbinhas' },
      { id: 1662, name: 'Cajueiro' },
      { id: 1663, name: 'Campestre' },
      { id: 1664, name: 'Campo Alegre' },
      { id: 1665, name: 'Campo Grande' },
      { id: 1666, name: 'Canapi' },
      { id: 1667, name: 'Capela' },
      { id: 1668, name: 'Carneiros' },
      { id: 1669, name: 'Chã Preta' },
      { id: 1670, name: 'Coité do Nóia' },
      { id: 1671, name: 'Colônia Leopoldina' },
      { id: 1672, name: 'Coqueiro Seco' },
      { id: 1673, name: 'Coruripe' },
      { id: 1674, name: 'Craíbas' },
      { id: 1675, name: 'Delmiro Gouveia' },
      { id: 1676, name: 'Dois Riachos' },
      { id: 1677, name: 'Estrela de Alagoas' },
      { id: 1678, name: 'Feira Grande' },
      { id: 1679, name: 'Feliz Deserto' },
      { id: 1680, name: 'Flexeiras' },
      { id: 1681, name: 'Girau do Ponciano' },
      { id: 1682, name: 'Ibateguara' },
      { id: 1683, name: 'Igaci' },
      { id: 1684, name: 'Igreja Nova' },
      { id: 1685, name: 'Inhapi' },
      { id: 1686, name: 'Jacaré dos Homens' },
      { id: 1687, name: 'Jacuípe' },
      { id: 1688, name: 'Japaratinga' },
      { id: 1689, name: 'Jaramataia' },
      { id: 1690, name: 'Jequiá da Praia' },
      { id: 1691, name: 'Joaquim Gomes' },
      { id: 1692, name: 'Jundiá' },
      { id: 1693, name: 'Junqueiro' },
      { id: 1694, name: 'Lagoa da Canoa' },
      { id: 1695, name: 'Limoeiro de Anadia' },
      { id: 1696, name: 'Maceió' },
      { id: 1697, name: 'Major Isidoro' },
      { id: 1702, name: 'Mar Vermelho' },
      { id: 1698, name: 'Maragogi' },
      { id: 1699, name: 'Maravilha' },
      { id: 1700, name: 'Marechal Deodoro' },
      { id: 1701, name: 'Maribondo' },
      { id: 1703, name: 'Mata Grande' },
      { id: 1704, name: 'Matriz de Camaragibe' },
      { id: 1705, name: 'Messias' },
      { id: 1706, name: 'Minador do Negrão' },
      { id: 1707, name: 'Monteirópolis' },
      { id: 1708, name: 'Murici' },
      { id: 1709, name: 'Novo Lino' },
      { id: 1710, name: "Olho d'Água das Flores" },
      { id: 1711, name: "Olho d'Água do Casado" },
      { id: 1712, name: "Olho d'Água Grande" },
      { id: 1713, name: 'Olivença' },
      { id: 1714, name: 'Ouro Branco' },
      { id: 1715, name: 'Palestina' },
      { id: 1716, name: 'Palmeira dos Índios' },
      { id: 1717, name: 'Pão de Açúcar' },
      { id: 1718, name: 'Pariconha' },
      { id: 1719, name: 'Paripueira' },
      { id: 1720, name: 'Passo de Camaragibe' },
      { id: 1721, name: 'Paulo Jacinto' },
      { id: 1722, name: 'Penedo' },
      { id: 1723, name: 'Piaçabuçu' },
      { id: 1724, name: 'Pilar' },
      { id: 1725, name: 'Pindoba' },
      { id: 1726, name: 'Piranhas' },
      { id: 1727, name: 'Poço das Trincheiras' },
      { id: 1728, name: 'Porto Calvo' },
      { id: 1729, name: 'Porto de Pedras' },
      { id: 1730, name: 'Porto Real do Colégio' },
      { id: 1731, name: 'Quebrangulo' },
      { id: 1732, name: 'Rio Largo' },
      { id: 1733, name: 'Roteiro' },
      { id: 1734, name: 'Santa Luzia do Norte' },
      { id: 1735, name: 'Santana do Ipanema' },
      { id: 1736, name: 'Santana do Mundaú' },
      { id: 1737, name: 'São Brás' },
      { id: 1738, name: 'São José da Laje' },
      { id: 1739, name: 'São José da Tapera' },
      { id: 1740, name: 'São Luís do Quitunde' },
      { id: 1741, name: 'São Miguel dos Campos' },
      { id: 1742, name: 'São Miguel dos Milagres' },
      { id: 1743, name: 'São Sebastião' },
      { id: 1744, name: 'Satuba' },
      { id: 1745, name: 'Senador Rui Palmeira' },
      { id: 1746, name: "Tanque d'Arca" },
      { id: 1747, name: 'Taquarana' },
      { id: 1748, name: 'Teotônio Vilela' },
      { id: 1749, name: 'Traipu' },
      { id: 1750, name: 'União dos Palmares' },
      { id: 1751, name: 'Viçosa' },
    ],
  },
  {
    id: 3,
    name: 'AM',
    fullname: 'Amazonas',
    cities: [
      { id: 112, name: 'Manaus' },
      { id: 75, name: 'Alvarães' },
      { id: 76, name: 'Amaturá' },
      { id: 77, name: 'Anamã' },
      { id: 78, name: 'Anori' },
      { id: 79, name: 'Apuí' },
      { id: 80, name: 'Atalaia do Norte' },
      { id: 81, name: 'Autazes' },
      { id: 82, name: 'Barcelos' },
      { id: 83, name: 'Barreirinha' },
      { id: 84, name: 'Benjamin Constant' },
      { id: 85, name: 'Beruri' },
      { id: 86, name: 'Boa Vista do Ramos' },
      { id: 87, name: 'Boca do Acre' },
      { id: 88, name: 'Borba' },
      { id: 89, name: 'Caapiranga' },
      { id: 90, name: 'Canutama' },
      { id: 91, name: 'Carauari' },
      { id: 92, name: 'Careiro' },
      { id: 93, name: 'Careiro da Várzea' },
      { id: 94, name: 'Coari' },
      { id: 95, name: 'Codajás' },
      { id: 96, name: 'Eirunepé' },
      { id: 97, name: 'Envira' },
      { id: 98, name: 'Fonte Boa' },
      { id: 99, name: 'Guajará' },
      { id: 100, name: 'Humaitá' },
      { id: 101, name: 'Ipixuna' },
      { id: 102, name: 'Iranduba' },
      { id: 103, name: 'Itacoatiara' },
      { id: 104, name: 'Itamarati' },
      { id: 105, name: 'Itapiranga' },
      { id: 106, name: 'Japurá' },
      { id: 107, name: 'Juruá' },
      { id: 108, name: 'Jutaí' },
      { id: 109, name: 'Lábrea' },
      { id: 110, name: 'Manacapuru' },
      { id: 111, name: 'Manaquiri' },
      { id: 112, name: 'Manaus' },
      { id: 113, name: 'Manicoré' },
      { id: 114, name: 'Maraã' },
      { id: 115, name: 'Maués' },
      { id: 116, name: 'Nhamundá' },
      { id: 117, name: 'Nova Olinda do Norte' },
      { id: 118, name: 'Novo Airão' },
      { id: 119, name: 'Novo Aripuanã' },
      { id: 120, name: 'Parintins' },
      { id: 121, name: 'Pauini' },
      { id: 122, name: 'Presidente Figueiredo' },
      { id: 123, name: 'Rio Preto da Eva' },
      { id: 124, name: 'Santa Isabel do Rio Negro' },
      { id: 125, name: 'Santo Antônio do Içá' },
      { id: 126, name: 'São Gabriel da Cachoeira' },
      { id: 127, name: 'São Paulo de Olivença' },
      { id: 128, name: 'São Sebastião do Uatumã' },
      { id: 129, name: 'Silves' },
      { id: 130, name: 'Tabatinga' },
      { id: 131, name: 'Tapauá' },
      { id: 132, name: 'Tefé' },
      { id: 133, name: 'Tonantins' },
      { id: 134, name: 'Uarini' },
      { id: 135, name: 'Urucará' },
      { id: 136, name: 'Urucurituba' },
    ],
  },
  {
    id: 6,
    name: 'AP',
    fullname: 'Amapá',
    cities: [
      { id: 303, name: 'Macapá' },
      { id: 296, name: 'Amapá' },
      { id: 298, name: 'Calçoene' },
      { id: 299, name: 'Cutias' },
      { id: 300, name: 'Ferreira Gomes' },
      { id: 301, name: 'Itaubal' },
      { id: 302, name: 'Laranjal do Jari' },
      { id: 303, name: 'Macapá' },
      { id: 304, name: 'Mazagão' },
      { id: 305, name: 'Oiapoque' },
      { id: 297, name: 'Pedra Branca do Amapari' },
      { id: 306, name: 'Porto Grande' },
      { id: 307, name: 'Pracuúba' },
      { id: 308, name: 'Santana' },
      { id: 295, name: 'Serra do Navio' },
      { id: 309, name: 'Tartarugalzinho' },
      { id: 310, name: 'Vitória do Jari' },
    ],
  },
  {
    id: 16,
    name: 'BA',
    fullname: 'Bahia',
    cities: [
      { id: 2162, name: 'Salvador' },
      { id: 1827, name: 'Abaíra' },
      { id: 1828, name: 'Abaré' },
      { id: 1829, name: 'Acajutiba' },
      { id: 1830, name: 'Adustina' },
      { id: 1831, name: 'Água Fria' },
      { id: 1833, name: 'Aiquara' },
      { id: 1834, name: 'Alagoinhas' },
      { id: 1835, name: 'Alcobaça' },
      { id: 1836, name: 'Almadina' },
      { id: 1837, name: 'Amargosa' },
      { id: 1838, name: 'Amélia Rodrigues' },
      { id: 1839, name: 'América Dourada' },
      { id: 1840, name: 'Anagé' },
      { id: 1841, name: 'Andaraí' },
      { id: 1842, name: 'Andorinha' },
      { id: 1843, name: 'Angical' },
      { id: 1844, name: 'Anguera' },
      { id: 1845, name: 'Antas' },
      { id: 1846, name: 'Antônio Cardoso' },
      { id: 1847, name: 'Antônio Gonçalves' },
      { id: 1848, name: 'Aporá' },
      { id: 1849, name: 'Apuarema' },
      { id: 1851, name: 'Araças' },
      { id: 1850, name: 'Aracatu' },
      { id: 1852, name: 'Araci' },
      { id: 1853, name: 'Aramari' },
      { id: 1854, name: 'Arataca' },
      { id: 1855, name: 'Aratuípe' },
      { id: 1856, name: 'Aurelino Leal' },
      { id: 1857, name: 'Baianópolis' },
      { id: 1858, name: 'Baixa Grande' },
      { id: 1859, name: 'Banzaê' },
      { id: 1860, name: 'Barra' },
      { id: 1861, name: 'Barra da Estiva' },
      { id: 1862, name: 'Barra do Choça' },
      { id: 1863, name: 'Barra do Mendes' },
      { id: 1864, name: 'Barra do Rocha' },
      { id: 1865, name: 'Barreiras' },
      { id: 1866, name: 'Barro Alto' },
      { id: 1868, name: 'Barro Preto' },
      { id: 1867, name: 'Barrocas' },
      { id: 1869, name: 'Belmonte' },
      { id: 1870, name: 'Belo Campo' },
      { id: 1871, name: 'Biritinga' },
      { id: 1872, name: 'Boa Nova' },
      { id: 1873, name: 'Boa Vista do Tupim' },
      { id: 1874, name: 'Bom Jesus da Lapa' },
      { id: 1875, name: 'Bom Jesus da Serra' },
      { id: 1876, name: 'Boninal' },
      { id: 1877, name: 'Bonito' },
      { id: 1878, name: 'Boquira' },
      { id: 1879, name: 'Botuporã' },
      { id: 1880, name: 'Brejões' },
      { id: 1881, name: 'Brejolândia' },
      { id: 1882, name: 'Brotas de Macaúbas' },
      { id: 1883, name: 'Brumado' },
      { id: 1884, name: 'Buerarema' },
      { id: 1885, name: 'Buritirama' },
      { id: 1886, name: 'Caatiba' },
      { id: 1887, name: 'Cabaceiras do Paraguaçu' },
      { id: 1888, name: 'Cachoeira' },
      { id: 1889, name: 'Caculé' },
      { id: 1890, name: 'Caém' },
      { id: 1891, name: 'Caetanos' },
      { id: 1892, name: 'Caetité' },
      { id: 1893, name: 'Cafarnaum' },
      { id: 1894, name: 'Cairu' },
      { id: 1895, name: 'Caldeirão Grande' },
      { id: 1896, name: 'Camacan' },
      { id: 1897, name: 'Camaçari' },
      { id: 1898, name: 'Camamu' },
      { id: 1899, name: 'Campo Alegre de Lourdes' },
      { id: 1900, name: 'Campo Formoso' },
      { id: 1901, name: 'Canápolis' },
      { id: 1902, name: 'Canarana' },
      { id: 1903, name: 'Canavieiras' },
      { id: 1904, name: 'Candeal' },
      { id: 1905, name: 'Candeias' },
      { id: 1906, name: 'Candiba' },
      { id: 1907, name: 'Cândido Sales' },
      { id: 1908, name: 'Cansanção' },
      { id: 1909, name: 'Canudos' },
      { id: 1910, name: 'Capela do Alto Alegre' },
      { id: 1911, name: 'Capim Grosso' },
      { id: 1912, name: 'Caraíbas' },
      { id: 1913, name: 'Caravelas' },
      { id: 1914, name: 'Cardeal da Silva' },
      { id: 1915, name: 'Carinhanha' },
      { id: 1916, name: 'Casa Nova' },
      { id: 1917, name: 'Castro Alves' },
      { id: 1918, name: 'Catolândia' },
      { id: 1919, name: 'Catu' },
      { id: 1920, name: 'Caturama' },
      { id: 1921, name: 'Central' },
      { id: 1922, name: 'Chorrochó' },
      { id: 1923, name: 'Cícero Dantas' },
      { id: 1924, name: 'Cipó' },
      { id: 1925, name: 'Coaraci' },
      { id: 1926, name: 'Cocos' },
      { id: 1927, name: 'Conceição da Feira' },
      { id: 1928, name: 'Conceição do Almeida' },
      { id: 1929, name: 'Conceição do Coité' },
      { id: 1930, name: 'Conceição do Jacuípe' },
      { id: 1931, name: 'Conde' },
      { id: 1932, name: 'Condeúba' },
      { id: 1933, name: 'Contendas do Sincorá' },
      { id: 1934, name: 'Coração de Maria' },
      { id: 1935, name: 'Cordeiros' },
      { id: 1936, name: 'Coribe' },
      { id: 1937, name: 'Coronel João Sá' },
      { id: 1938, name: 'Correntina' },
      { id: 1939, name: 'Cotegipe' },
      { id: 1940, name: 'Cravolândia' },
      { id: 1941, name: 'Crisópolis' },
      { id: 1942, name: 'Cristópolis' },
      { id: 1943, name: 'Cruz das Almas' },
      { id: 1944, name: 'Curaçá' },
      { id: 1945, name: 'Dário Meira' },
      { id: 1946, name: "Dias d'Ávila" },
      { id: 1947, name: 'Dom Basílio' },
      { id: 1948, name: 'Dom Macedo Costa' },
      { id: 1949, name: 'Elísio Medrado' },
      { id: 1950, name: 'Encruzilhada' },
      { id: 1951, name: 'Entre Rios' },
      { id: 1832, name: 'Érico Cardoso' },
      { id: 1952, name: 'Esplanada' },
      { id: 1953, name: 'Euclides da Cunha' },
      { id: 1954, name: 'Eunápolis' },
      { id: 1955, name: 'Fátima' },
      { id: 1956, name: 'Feira da Mata' },
      { id: 1957, name: 'Feira de Santana' },
      { id: 1958, name: 'Filadélfia' },
      { id: 1959, name: 'Firmino Alves' },
      { id: 1960, name: 'Floresta Azul' },
      { id: 1961, name: 'Formosa do Rio Preto' },
      { id: 1962, name: 'Gandu' },
      { id: 1963, name: 'Gavião' },
      { id: 1964, name: 'Gentio do Ouro' },
      { id: 1965, name: 'Glória' },
      { id: 1966, name: 'Gongogi' },
      { id: 1967, name: 'Governador Mangabeira' },
      { id: 1968, name: 'Guajeru' },
      { id: 1969, name: 'Guanambi' },
      { id: 1970, name: 'Guaratinga' },
      { id: 1971, name: 'Heliópolis' },
      { id: 1972, name: 'Iaçu' },
      { id: 1973, name: 'Ibiassucê' },
      { id: 1974, name: 'Ibicaraí' },
      { id: 1975, name: 'Ibicoara' },
      { id: 1976, name: 'Ibicuí' },
      { id: 1977, name: 'Ibipeba' },
      { id: 1978, name: 'Ibipitanga' },
      { id: 1979, name: 'Ibiquera' },
      { id: 1980, name: 'Ibirapitanga' },
      { id: 1981, name: 'Ibirapuã' },
      { id: 1982, name: 'Ibirataia' },
      { id: 1983, name: 'Ibitiara' },
      { id: 1984, name: 'Ibititá' },
      { id: 1985, name: 'Ibotirama' },
      { id: 1986, name: 'Ichu' },
      { id: 1987, name: 'Igaporã' },
      { id: 1988, name: 'Igrapiúna' },
      { id: 1989, name: 'Iguaí' },
      { id: 1990, name: 'Ilhéus' },
      { id: 1991, name: 'Inhambupe' },
      { id: 1992, name: 'Ipecaetá' },
      { id: 1993, name: 'Ipiaú' },
      { id: 1994, name: 'Ipirá' },
      { id: 1995, name: 'Ipupiara' },
      { id: 1996, name: 'Irajuba' },
      { id: 1997, name: 'Iramaia' },
      { id: 1998, name: 'Iraquara' },
      { id: 1999, name: 'Irará' },
      { id: 2000, name: 'Irecê' },
      { id: 2001, name: 'Itabela' },
      { id: 2002, name: 'Itaberaba' },
      { id: 2003, name: 'Itabuna' },
      { id: 2004, name: 'Itacaré' },
      { id: 2005, name: 'Itaeté' },
      { id: 2006, name: 'Itagi' },
      { id: 2007, name: 'Itagibá' },
      { id: 2008, name: 'Itagimirim' },
      { id: 2009, name: 'Itaguaçu da Bahia' },
      { id: 2010, name: 'Itaju do Colônia' },
      { id: 2011, name: 'Itajuípe' },
      { id: 2012, name: 'Itamaraju' },
      { id: 2013, name: 'Itamari' },
      { id: 2014, name: 'Itambé' },
      { id: 2015, name: 'Itanagra' },
      { id: 2016, name: 'Itanhém' },
      { id: 2017, name: 'Itaparica' },
      { id: 2018, name: 'Itapé' },
      { id: 2019, name: 'Itapebi' },
      { id: 2020, name: 'Itapetinga' },
      { id: 2021, name: 'Itapicuru' },
      { id: 2022, name: 'Itapitanga' },
      { id: 2023, name: 'Itaquara' },
      { id: 2024, name: 'Itarantim' },
      { id: 2025, name: 'Itatim' },
      { id: 2026, name: 'Itiruçu' },
      { id: 2027, name: 'Itiúba' },
      { id: 2028, name: 'Itororó' },
      { id: 2029, name: 'Ituaçu' },
      { id: 2030, name: 'Ituberá' },
      { id: 2031, name: 'Iuiú' },
      { id: 2032, name: 'Jaborandi' },
      { id: 2033, name: 'Jacaraci' },
      { id: 2034, name: 'Jacobina' },
      { id: 2035, name: 'Jaguaquara' },
      { id: 2036, name: 'Jaguarari' },
      { id: 2037, name: 'Jaguaripe' },
      { id: 2038, name: 'Jandaíra' },
      { id: 2039, name: 'Jequié' },
      { id: 2040, name: 'Jeremoabo' },
      { id: 2041, name: 'Jiquiriçá' },
      { id: 2042, name: 'Jitaúna' },
      { id: 2043, name: 'João Dourado' },
      { id: 2044, name: 'Juazeiro' },
      { id: 2045, name: 'Jucuruçu' },
      { id: 2046, name: 'Jussara' },
      { id: 2047, name: 'Jussari' },
      { id: 2048, name: 'Jussiape' },
      { id: 2049, name: 'Lafaiete Coutinho' },
      { id: 2050, name: 'Lagoa Real' },
      { id: 2051, name: 'Laje' },
      { id: 2052, name: 'Lajedão' },
      { id: 2053, name: 'Lajedinho' },
      { id: 2054, name: 'Lajedo do Tabocal' },
      { id: 2055, name: 'Lamarão' },
      { id: 2056, name: 'Lapão' },
      { id: 2057, name: 'Lauro de Freitas' },
      { id: 2058, name: 'Lençóis' },
      { id: 2059, name: 'Licínio de Almeida' },
      { id: 2060, name: 'Livramento de Nossa Senhora' },
      { id: 2061, name: 'Luís Eduardo Magalhães' },
      { id: 2062, name: 'Macajuba' },
      { id: 2063, name: 'Macarani' },
      { id: 2064, name: 'Macaúbas' },
      { id: 2065, name: 'Macururé' },
      { id: 2066, name: 'Madre de Deus' },
      { id: 2067, name: 'Maetinga' },
      { id: 2068, name: 'Maiquinique' },
      { id: 2069, name: 'Mairi' },
      { id: 2070, name: 'Malhada' },
      { id: 2071, name: 'Malhada de Pedras' },
      { id: 2072, name: 'Manoel Vitorino' },
      { id: 2073, name: 'Mansidão' },
      { id: 2074, name: 'Maracás' },
      { id: 2075, name: 'Maragogipe' },
      { id: 2076, name: 'Maraú' },
      { id: 2077, name: 'Marcionílio Souza' },
      { id: 2078, name: 'Mascote' },
      { id: 2079, name: 'Mata de São João' },
      { id: 2080, name: 'Matina' },
      { id: 2081, name: 'Medeiros Neto' },
      { id: 2082, name: 'Miguel Calmon' },
      { id: 2083, name: 'Milagres' },
      { id: 2084, name: 'Mirangaba' },
      { id: 2085, name: 'Mirante' },
      { id: 2086, name: 'Monte Santo' },
      { id: 2087, name: 'Morpará' },
      { id: 2088, name: 'Morro do Chapéu' },
      { id: 2089, name: 'Mortugaba' },
      { id: 2090, name: 'Mucugê' },
      { id: 2091, name: 'Mucuri' },
      { id: 2092, name: 'Mulungu do Morro' },
      { id: 2093, name: 'Mundo Novo' },
      { id: 2094, name: 'Muniz Ferreira' },
      { id: 2095, name: 'Muquém de São Francisco' },
      { id: 2096, name: 'Muritiba' },
      { id: 2097, name: 'Mutuípe' },
      { id: 2098, name: 'Nazaré' },
      { id: 2099, name: 'Nilo Peçanha' },
      { id: 2100, name: 'Nordestina' },
      { id: 2101, name: 'Nova Canaã' },
      { id: 2102, name: 'Nova Fátima' },
      { id: 2103, name: 'Nova Ibiá' },
      { id: 2104, name: 'Nova Itarana' },
      { id: 2105, name: 'Nova Redenção' },
      { id: 2106, name: 'Nova Soure' },
      { id: 2107, name: 'Nova Viçosa' },
      { id: 2108, name: 'Novo Horizonte' },
      { id: 2109, name: 'Novo Triunfo' },
      { id: 2110, name: 'Olindina' },
      { id: 2111, name: 'Oliveira dos Brejinhos' },
      { id: 2112, name: 'Ouriçangas' },
      { id: 2113, name: 'Ourolândia' },
      { id: 2114, name: 'Palmas de Monte Alto' },
      { id: 2115, name: 'Palmeiras' },
      { id: 2116, name: 'Paramirim' },
      { id: 2117, name: 'Paratinga' },
      { id: 2118, name: 'Paripiranga' },
      { id: 2119, name: 'Pau Brasil' },
      { id: 2120, name: 'Paulo Afonso' },
      { id: 2121, name: 'Pé de Serra' },
      { id: 2122, name: 'Pedrão' },
      { id: 2123, name: 'Pedro Alexandre' },
      { id: 2124, name: 'Piatã' },
      { id: 2125, name: 'Pilão Arcado' },
      { id: 2126, name: 'Pindaí' },
      { id: 2127, name: 'Pindobaçu' },
      { id: 2128, name: 'Pintadas' },
      { id: 2129, name: 'Piraí do Norte' },
      { id: 2130, name: 'Piripá' },
      { id: 2131, name: 'Piritiba' },
      { id: 2132, name: 'Planaltino' },
      { id: 2133, name: 'Planalto' },
      { id: 2134, name: 'Poções' },
      { id: 2135, name: 'Pojuca' },
      { id: 2136, name: 'Ponto Novo' },
      { id: 2137, name: 'Porto Seguro' },
      { id: 2138, name: 'Potiraguá' },
      { id: 2139, name: 'Prado' },
      { id: 2140, name: 'Presidente Dutra' },
      { id: 2141, name: 'Presidente Jânio Quadros' },
      { id: 2142, name: 'Presidente Tancredo Neves' },
      { id: 2143, name: 'Queimadas' },
      { id: 2144, name: 'Quijingue' },
      { id: 2145, name: 'Quixabeira' },
      { id: 2146, name: 'Rafael Jambeiro' },
      { id: 2147, name: 'Remanso' },
      { id: 2148, name: 'Retirolândia' },
      { id: 2149, name: 'Riachão das Neves' },
      { id: 2150, name: 'Riachão do Jacuípe' },
      { id: 2151, name: 'Riacho de Santana' },
      { id: 2152, name: 'Ribeira do Amparo' },
      { id: 2153, name: 'Ribeira do Pombal' },
      { id: 2154, name: 'Ribeirão do Largo' },
      { id: 2155, name: 'Rio de Contas' },
      { id: 2156, name: 'Rio do Antônio' },
      { id: 2157, name: 'Rio do Pires' },
      { id: 2158, name: 'Rio Real' },
      { id: 2159, name: 'Rodelas' },
      { id: 2160, name: 'Ruy Barbosa' },
      { id: 2161, name: 'Salinas da Margarida' },
      { id: 2162, name: 'Salvador' },
      { id: 2163, name: 'Santa Bárbara' },
      { id: 2164, name: 'Santa Brígida' },
      { id: 2165, name: 'Santa Cruz Cabrália' },
      { id: 2166, name: 'Santa Cruz da Vitória' },
      { id: 2167, name: 'Santa Inês' },
      { id: 2169, name: 'Santa Luzia' },
      { id: 2170, name: 'Santa Maria da Vitória' },
      { id: 2173, name: 'Santa Rita de Cássia' },
      { id: 2174, name: 'Santa Teresinha' },
      { id: 2168, name: 'Santaluz' },
      { id: 2171, name: 'Santana' },
      { id: 2172, name: 'Santanópolis' },
      { id: 2175, name: 'Santo Amaro' },
      { id: 2176, name: 'Santo Antônio de Jesus' },
      { id: 2177, name: 'Santo Estêvão' },
      { id: 2178, name: 'São Desidério' },
      { id: 2179, name: 'São Domingos' },
      { id: 2182, name: 'São Felipe' },
      { id: 2180, name: 'São Félix' },
      { id: 2181, name: 'São Félix do Coribe' },
      { id: 2183, name: 'São Francisco do Conde' },
      { id: 2184, name: 'São Gabriel' },
      { id: 2185, name: 'São Gonçalo dos Campos' },
      { id: 2186, name: 'São José da Vitória' },
      { id: 2187, name: 'São José do Jacuípe' },
      { id: 2188, name: 'São Miguel das Matas' },
      { id: 2189, name: 'São Sebastião do Passé' },
      { id: 2190, name: 'Sapeaçu' },
      { id: 2191, name: 'Sátiro Dias' },
      { id: 2192, name: 'Saubara' },
      { id: 2193, name: 'Saúde' },
      { id: 2194, name: 'Seabra' },
      { id: 2195, name: 'Sebastião Laranjeiras' },
      { id: 2196, name: 'Senhor do Bonfim' },
      { id: 2198, name: 'Sento Sé' },
      { id: 2197, name: 'Serra do Ramalho' },
      { id: 2199, name: 'Serra Dourada' },
      { id: 2200, name: 'Serra Preta' },
      { id: 2201, name: 'Serrinha' },
      { id: 2202, name: 'Serrolândia' },
      { id: 2203, name: 'Simões Filho' },
      { id: 2204, name: 'Sítio do Mato' },
      { id: 2205, name: 'Sítio do Quinto' },
      { id: 2206, name: 'Sobradinho' },
      { id: 2207, name: 'Souto Soares' },
      { id: 2208, name: 'Tabocas do Brejo Velho' },
      { id: 2209, name: 'Tanhaçu' },
      { id: 2210, name: 'Tanque Novo' },
      { id: 2211, name: 'Tanquinho' },
      { id: 2212, name: 'Taperoá' },
      { id: 2213, name: 'Tapiramutá' },
      { id: 2214, name: 'Teixeira de Freitas' },
      { id: 2215, name: 'Teodoro Sampaio' },
      { id: 2216, name: 'Teofilândia' },
      { id: 2217, name: 'Teolândia' },
      { id: 2218, name: 'Terra Nova' },
      { id: 2219, name: 'Tremedal' },
      { id: 2220, name: 'Tucano' },
      { id: 2221, name: 'Uauá' },
      { id: 2222, name: 'Ubaíra' },
      { id: 2223, name: 'Ubaitaba' },
      { id: 2224, name: 'Ubatã' },
      { id: 2225, name: 'Uibaí' },
      { id: 2226, name: 'Umburanas' },
      { id: 2227, name: 'Una' },
      { id: 2228, name: 'Urandi' },
      { id: 2229, name: 'Uruçuca' },
      { id: 2230, name: 'Utinga' },
      { id: 2231, name: 'Valença' },
      { id: 2232, name: 'Valente' },
      { id: 2233, name: 'Várzea da Roça' },
      { id: 2234, name: 'Várzea do Poço' },
      { id: 2235, name: 'Várzea Nova' },
      { id: 2236, name: 'Varzedo' },
      { id: 2237, name: 'Vera Cruz' },
      { id: 2238, name: 'Vereda' },
      { id: 2239, name: 'Vitória da Conquista' },
      { id: 2240, name: 'Wagner' },
      { id: 2241, name: 'Wanderley' },
      { id: 2242, name: 'Wenceslau Guimarães' },
      { id: 2243, name: 'Xique-Xique' },
    ],
  },
  {
    id: 10,
    name: 'CE',
    fullname: 'Ceará',
    cities: [
      { id: 949, name: 'Fortaleza' },
      { id: 891, name: 'Abaiara' },
      { id: 892, name: 'Acarape' },
      { id: 893, name: 'Acaraú' },
      { id: 894, name: 'Acopiara' },
      { id: 895, name: 'Aiuaba' },
      { id: 896, name: 'Alcântaras' },
      { id: 897, name: 'Altaneira' },
      { id: 898, name: 'Alto Santo' },
      { id: 899, name: 'Amontada' },
      { id: 900, name: 'Antonina do Norte' },
      { id: 901, name: 'Apuiarés' },
      { id: 902, name: 'Aquiraz' },
      { id: 903, name: 'Aracati' },
      { id: 904, name: 'Aracoiaba' },
      { id: 905, name: 'Ararendá' },
      { id: 906, name: 'Araripe' },
      { id: 907, name: 'Aratuba' },
      { id: 908, name: 'Arneiroz' },
      { id: 909, name: 'Assaré' },
      { id: 910, name: 'Aurora' },
      { id: 911, name: 'Baixio' },
      { id: 912, name: 'Banabuiú' },
      { id: 913, name: 'Barbalha' },
      { id: 914, name: 'Barreira' },
      { id: 915, name: 'Barro' },
      { id: 916, name: 'Barroquinha' },
      { id: 917, name: 'Baturité' },
      { id: 918, name: 'Beberibe' },
      { id: 919, name: 'Bela Cruz' },
      { id: 920, name: 'Boa Viagem' },
      { id: 921, name: 'Brejo Santo' },
      { id: 922, name: 'Camocim' },
      { id: 923, name: 'Campos Sales' },
      { id: 924, name: 'Canindé' },
      { id: 925, name: 'Capistrano' },
      { id: 926, name: 'Caridade' },
      { id: 927, name: 'Cariré' },
      { id: 928, name: 'Caririaçu' },
      { id: 929, name: 'Cariús' },
      { id: 930, name: 'Carnaubal' },
      { id: 931, name: 'Cascavel' },
      { id: 932, name: 'Catarina' },
      { id: 933, name: 'Catunda' },
      { id: 934, name: 'Caucaia' },
      { id: 935, name: 'Cedro' },
      { id: 936, name: 'Chaval' },
      { id: 937, name: 'Choró' },
      { id: 938, name: 'Chorozinho' },
      { id: 939, name: 'Coreaú' },
      { id: 940, name: 'Crateús' },
      { id: 941, name: 'Crato' },
      { id: 942, name: 'Croatá' },
      { id: 943, name: 'Cruz' },
      { id: 944, name: 'Deputado Irapuan Pinheiro' },
      { id: 945, name: 'Ererê' },
      { id: 946, name: 'Eusébio' },
      { id: 947, name: 'Farias Brito' },
      { id: 948, name: 'Forquilha' },
      { id: 949, name: 'Fortaleza' },
      { id: 950, name: 'Fortim' },
      { id: 951, name: 'Frecheirinha' },
      { id: 952, name: 'General Sampaio' },
      { id: 953, name: 'Graça' },
      { id: 954, name: 'Granja' },
      { id: 955, name: 'Granjeiro' },
      { id: 956, name: 'Groaíras' },
      { id: 957, name: 'Guaiúba' },
      { id: 958, name: 'Guaraciaba do Norte' },
      { id: 959, name: 'Guaramiranga' },
      { id: 960, name: 'Hidrolândia' },
      { id: 961, name: 'Horizonte' },
      { id: 962, name: 'Ibaretama' },
      { id: 963, name: 'Ibiapina' },
      { id: 964, name: 'Ibicuitinga' },
      { id: 965, name: 'Icapuí' },
      { id: 966, name: 'Icó' },
      { id: 967, name: 'Iguatu' },
      { id: 968, name: 'Independência' },
      { id: 969, name: 'Ipaporanga' },
      { id: 970, name: 'Ipaumirim' },
      { id: 971, name: 'Ipu' },
      { id: 972, name: 'Ipueiras' },
      { id: 973, name: 'Iracema' },
      { id: 974, name: 'Irauçuba' },
      { id: 975, name: 'Itaiçaba' },
      { id: 976, name: 'Itaitinga' },
      { id: 977, name: 'Itapagé' },
      { id: 978, name: 'Itapipoca' },
      { id: 979, name: 'Itapiúna' },
      { id: 980, name: 'Itarema' },
      { id: 981, name: 'Itatira' },
      { id: 982, name: 'Jaguaretama' },
      { id: 983, name: 'Jaguaribara' },
      { id: 984, name: 'Jaguaribe' },
      { id: 985, name: 'Jaguaruana' },
      { id: 986, name: 'Jardim' },
      { id: 987, name: 'Jati' },
      { id: 988, name: 'Jijoca de Jericoacoara' },
      { id: 989, name: 'Juazeiro do Norte' },
      { id: 990, name: 'Jucás' },
      { id: 991, name: 'Lavras da Mangabeira' },
      { id: 992, name: 'Limoeiro do Norte' },
      { id: 993, name: 'Madalena' },
      { id: 994, name: 'Maracanaú' },
      { id: 995, name: 'Maranguape' },
      { id: 996, name: 'Marco' },
      { id: 997, name: 'Martinópole' },
      { id: 998, name: 'Massapê' },
      { id: 999, name: 'Mauriti' },
      { id: 1000, name: 'Meruoca' },
      { id: 1001, name: 'Milagres' },
      { id: 1002, name: 'Milhã' },
      { id: 1003, name: 'Miraíma' },
      { id: 1004, name: 'Missão Velha' },
      { id: 1005, name: 'Mombaça' },
      { id: 1006, name: 'Monsenhor Tabosa' },
      { id: 1007, name: 'Morada Nova' },
      { id: 1008, name: 'Moraújo' },
      { id: 1009, name: 'Morrinhos' },
      { id: 1010, name: 'Mucambo' },
      { id: 1011, name: 'Mulungu' },
      { id: 1012, name: 'Nova Olinda' },
      { id: 1013, name: 'Nova Russas' },
      { id: 1014, name: 'Novo Oriente' },
      { id: 1015, name: 'Ocara' },
      { id: 1016, name: 'Orós' },
      { id: 1017, name: 'Pacajus' },
      { id: 1018, name: 'Pacatuba' },
      { id: 1019, name: 'Pacoti' },
      { id: 1020, name: 'Pacujá' },
      { id: 1021, name: 'Palhano' },
      { id: 1022, name: 'Palmácia' },
      { id: 1023, name: 'Paracuru' },
      { id: 1024, name: 'Paraipaba' },
      { id: 1025, name: 'Parambu' },
      { id: 1026, name: 'Paramoti' },
      { id: 1027, name: 'Pedra Branca' },
      { id: 1028, name: 'Penaforte' },
      { id: 1029, name: 'Pentecoste' },
      { id: 1030, name: 'Pereiro' },
      { id: 1031, name: 'Pindoretama' },
      { id: 1032, name: 'Piquet Carneiro' },
      { id: 1033, name: 'Pires Ferreira' },
      { id: 1034, name: 'Poranga' },
      { id: 1035, name: 'Porteiras' },
      { id: 1036, name: 'Potengi' },
      { id: 1037, name: 'Potiretama' },
      { id: 1038, name: 'Quiterianópolis' },
      { id: 1039, name: 'Quixadá' },
      { id: 1040, name: 'Quixelô' },
      { id: 1041, name: 'Quixeramobim' },
      { id: 1042, name: 'Quixeré' },
      { id: 1043, name: 'Redenção' },
      { id: 1044, name: 'Reriutaba' },
      { id: 1045, name: 'Russas' },
      { id: 1046, name: 'Saboeiro' },
      { id: 1047, name: 'Salitre' },
      { id: 1050, name: 'Santa Quitéria' },
      { id: 1048, name: 'Santana do Acaraú' },
      { id: 1049, name: 'Santana do Cariri' },
      { id: 1051, name: 'São Benedito' },
      { id: 1052, name: 'São Gonçalo do Amarante' },
      { id: 1053, name: 'São João do Jaguaribe' },
      { id: 1054, name: 'São Luís do Curu' },
      { id: 1055, name: 'Senador Pompeu' },
      { id: 1056, name: 'Senador Sá' },
      { id: 1057, name: 'Sobral' },
      { id: 1058, name: 'Solonópole' },
      { id: 1059, name: 'Tabuleiro do Norte' },
      { id: 1060, name: 'Tamboril' },
      { id: 1061, name: 'Tarrafas' },
      { id: 1062, name: 'Tauá' },
      { id: 1063, name: 'Tejuçuoca' },
      { id: 1064, name: 'Tianguá' },
      { id: 1065, name: 'Trairi' },
      { id: 1066, name: 'Tururu' },
      { id: 1067, name: 'Ubajara' },
      { id: 1068, name: 'Umari' },
      { id: 1069, name: 'Umirim' },
      { id: 1070, name: 'Uruburetama' },
      { id: 1071, name: 'Uruoca' },
      { id: 1072, name: 'Varjota' },
      { id: 1073, name: 'Várzea Alegre' },
      { id: 1074, name: 'Viçosa do Ceará' },
    ],
  },
  { id: 27, name: 'DF', fullname: 'Distrito Federal', cities: [{ id: 5565, name: 'Brasília' }] },
  {
    id: 18,
    name: 'ES',
    fullname: 'Espírito Santo',
    cities: [
      { id: 3174, name: 'Vitória' },
      { id: 3097, name: 'Afonso Cláudio' },
      { id: 3099, name: 'Água Doce do Norte' },
      { id: 3098, name: 'Águia Branca' },
      { id: 3100, name: 'Alegre' },
      { id: 3101, name: 'Alfredo Chaves' },
      { id: 3102, name: 'Alto Rio Novo' },
      { id: 3103, name: 'Anchieta' },
      { id: 3104, name: 'Apiacá' },
      { id: 3105, name: 'Aracruz' },
      { id: 3106, name: 'Atilio Vivacqua' },
      { id: 3107, name: 'Baixo Guandu' },
      { id: 3108, name: 'Barra de São Francisco' },
      { id: 3109, name: 'Boa Esperança' },
      { id: 3110, name: 'Bom Jesus do Norte' },
      { id: 3111, name: 'Brejetuba' },
      { id: 3112, name: 'Cachoeiro de Itapemirim' },
      { id: 3113, name: 'Cariacica' },
      { id: 3114, name: 'Castelo' },
      { id: 3115, name: 'Colatina' },
      { id: 3116, name: 'Conceição da Barra' },
      { id: 3117, name: 'Conceição do Castelo' },
      { id: 3118, name: 'Divino de São Lourenço' },
      { id: 3119, name: 'Domingos Martins' },
      { id: 3120, name: 'Dores do Rio Preto' },
      { id: 3121, name: 'Ecoporanga' },
      { id: 3122, name: 'Fundão' },
      { id: 3123, name: 'Governador Lindenberg' },
      { id: 3124, name: 'Guaçuí' },
      { id: 3125, name: 'Guarapari' },
      { id: 3126, name: 'Ibatiba' },
      { id: 3127, name: 'Ibiraçu' },
      { id: 3128, name: 'Ibitirama' },
      { id: 3129, name: 'Iconha' },
      { id: 3130, name: 'Irupi' },
      { id: 3131, name: 'Itaguaçu' },
      { id: 3132, name: 'Itapemirim' },
      { id: 3133, name: 'Itarana' },
      { id: 3134, name: 'Iúna' },
      { id: 3135, name: 'Jaguaré' },
      { id: 3136, name: 'Jerônimo Monteiro' },
      { id: 3137, name: 'João Neiva' },
      { id: 3138, name: 'Laranja da Terra' },
      { id: 3139, name: 'Linhares' },
      { id: 3140, name: 'Mantenópolis' },
      { id: 3141, name: 'Marataízes' },
      { id: 3142, name: 'Marechal Floriano' },
      { id: 3143, name: 'Marilândia' },
      { id: 3144, name: 'Mimoso do Sul' },
      { id: 3145, name: 'Montanha' },
      { id: 3146, name: 'Mucurici' },
      { id: 3147, name: 'Muniz Freire' },
      { id: 3148, name: 'Muqui' },
      { id: 3149, name: 'Nova Venécia' },
      { id: 3150, name: 'Pancas' },
      { id: 3151, name: 'Pedro Canário' },
      { id: 3152, name: 'Pinheiros' },
      { id: 3153, name: 'Piúma' },
      { id: 3154, name: 'Ponto Belo' },
      { id: 3155, name: 'Presidente Kennedy' },
      { id: 3156, name: 'Rio Bananal' },
      { id: 3157, name: 'Rio Novo do Sul' },
      { id: 3158, name: 'Santa Leopoldina' },
      { id: 3159, name: 'Santa Maria de Jetibá' },
      { id: 3160, name: 'Santa Teresa' },
      { id: 3161, name: 'São Domingos do Norte' },
      { id: 3162, name: 'São Gabriel da Palha' },
      { id: 3163, name: 'São José do Calçado' },
      { id: 3164, name: 'São Mateus' },
      { id: 3165, name: 'São Roque do Canaã' },
      { id: 3166, name: 'Serra' },
      { id: 3167, name: 'Sooretama' },
      { id: 3168, name: 'Vargem Alta' },
      { id: 3169, name: 'Venda Nova do Imigrante' },
      { id: 3170, name: 'Viana' },
      { id: 3171, name: 'Vila Pavão' },
      { id: 3172, name: 'Vila Valério' },
      { id: 3173, name: 'Vila Velha' },
      { id: 3174, name: 'Vitória' },
    ],
  },
  {
    id: 26,
    name: 'GO',
    fullname: 'Goiás',
    cities: [
      { id: 5413, name: 'Goiânia' },
      { id: 5319, name: 'Abadia de Goiás' },
      { id: 5320, name: 'Abadiânia' },
      { id: 5321, name: 'Acreúna' },
      { id: 5322, name: 'Adelândia' },
      { id: 5323, name: 'Água Fria de Goiás' },
      { id: 5324, name: 'Água Limpa' },
      { id: 5325, name: 'Águas Lindas de Goiás' },
      { id: 5326, name: 'Alexânia' },
      { id: 5327, name: 'Aloândia' },
      { id: 5328, name: 'Alto Horizonte' },
      { id: 5329, name: 'Alto Paraíso de Goiás' },
      { id: 5330, name: 'Alvorada do Norte' },
      { id: 5331, name: 'Amaralina' },
      { id: 5332, name: 'Americano do Brasil' },
      { id: 5333, name: 'Amorinópolis' },
      { id: 5334, name: 'Anápolis' },
      { id: 5335, name: 'Anhanguera' },
      { id: 5336, name: 'Anicuns' },
      { id: 5337, name: 'Aparecida de Goiânia' },
      { id: 5338, name: 'Aparecida do Rio Doce' },
      { id: 5339, name: 'Aporé' },
      { id: 5340, name: 'Araçu' },
      { id: 5341, name: 'Aragarças' },
      { id: 5342, name: 'Aragoiânia' },
      { id: 5343, name: 'Araguapaz' },
      { id: 5344, name: 'Arenópolis' },
      { id: 5345, name: 'Aruanã' },
      { id: 5346, name: 'Aurilândia' },
      { id: 5347, name: 'Avelinópolis' },
      { id: 5348, name: 'Baliza' },
      { id: 5349, name: 'Barro Alto' },
      { id: 5350, name: 'Bela Vista de Goiás' },
      { id: 5351, name: 'Bom Jardim de Goiás' },
      { id: 5352, name: 'Bom Jesus de Goiás' },
      { id: 5353, name: 'Bonfinópolis' },
      { id: 5354, name: 'Bonópolis' },
      { id: 5355, name: 'Brazabrantes' },
      { id: 5356, name: 'Britânia' },
      { id: 5357, name: 'Buriti Alegre' },
      { id: 5358, name: 'Buriti de Goiás' },
      { id: 5359, name: 'Buritinópolis' },
      { id: 5360, name: 'Cabeceiras' },
      { id: 5361, name: 'Cachoeira Alta' },
      { id: 5362, name: 'Cachoeira de Goiás' },
      { id: 5363, name: 'Cachoeira Dourada' },
      { id: 5364, name: 'Caçu' },
      { id: 5365, name: 'Caiapônia' },
      { id: 5366, name: 'Caldas Novas' },
      { id: 5367, name: 'Caldazinha' },
      { id: 5368, name: 'Campestre de Goiás' },
      { id: 5369, name: 'Campinaçu' },
      { id: 5370, name: 'Campinorte' },
      { id: 5371, name: 'Campo Alegre de Goiás' },
      { id: 5372, name: 'Campo Limpo de Goiás' },
      { id: 5373, name: 'Campos Belos' },
      { id: 5374, name: 'Campos Verdes' },
      { id: 5375, name: 'Carmo do Rio Verde' },
      { id: 5376, name: 'Castelândia' },
      { id: 5377, name: 'Catalão' },
      { id: 5378, name: 'Caturaí' },
      { id: 5379, name: 'Cavalcante' },
      { id: 5380, name: 'Ceres' },
      { id: 5381, name: 'Cezarina' },
      { id: 5382, name: 'Chapadão do Céu' },
      { id: 5383, name: 'Cidade Ocidental' },
      { id: 5384, name: 'Cocalzinho de Goiás' },
      { id: 5385, name: 'Colinas do Sul' },
      { id: 5386, name: 'Córrego do Ouro' },
      { id: 5387, name: 'Corumbá de Goiás' },
      { id: 5388, name: 'Corumbaíba' },
      { id: 5389, name: 'Cristalina' },
      { id: 5390, name: 'Cristianópolis' },
      { id: 5391, name: 'Crixás' },
      { id: 5392, name: 'Cromínia' },
      { id: 5393, name: 'Cumari' },
      { id: 5394, name: 'Damianópolis' },
      { id: 5395, name: 'Damolândia' },
      { id: 5396, name: 'Davinópolis' },
      { id: 5397, name: 'Diorama' },
      { id: 5409, name: 'Divinópolis de Goiás' },
      { id: 5398, name: 'Doverlândia' },
      { id: 5399, name: 'Edealina' },
      { id: 5400, name: 'Edéia' },
      { id: 5401, name: 'Estrela do Norte' },
      { id: 5402, name: 'Faina' },
      { id: 5403, name: 'Fazenda Nova' },
      { id: 5404, name: 'Firminópolis' },
      { id: 5405, name: 'Flores de Goiás' },
      { id: 5406, name: 'Formosa' },
      { id: 5407, name: 'Formoso' },
      { id: 5408, name: 'Gameleira de Goiás' },
      { id: 5410, name: 'Goianápolis' },
      { id: 5411, name: 'Goiandira' },
      { id: 5412, name: 'Goianésia' },
      { id: 5413, name: 'Goiânia' },
      { id: 5414, name: 'Goianira' },
      { id: 5415, name: 'Goiás' },
      { id: 5416, name: 'Goiatuba' },
      { id: 5417, name: 'Gouvelândia' },
      { id: 5418, name: 'Guapó' },
      { id: 5419, name: 'Guaraíta' },
      { id: 5420, name: 'Guarani de Goiás' },
      { id: 5421, name: 'Guarinos' },
      { id: 5422, name: 'Heitoraí' },
      { id: 5423, name: 'Hidrolândia' },
      { id: 5424, name: 'Hidrolina' },
      { id: 5425, name: 'Iaciara' },
      { id: 5426, name: 'Inaciolândia' },
      { id: 5427, name: 'Indiara' },
      { id: 5428, name: 'Inhumas' },
      { id: 5429, name: 'Ipameri' },
      { id: 5430, name: 'Ipiranga de Goiás' },
      { id: 5431, name: 'Iporá' },
      { id: 5432, name: 'Israelândia' },
      { id: 5433, name: 'Itaberaí' },
      { id: 5434, name: 'Itaguari' },
      { id: 5435, name: 'Itaguaru' },
      { id: 5436, name: 'Itajá' },
      { id: 5437, name: 'Itapaci' },
      { id: 5438, name: 'Itapirapuã' },
      { id: 5439, name: 'Itapuranga' },
      { id: 5440, name: 'Itarumã' },
      { id: 5441, name: 'Itauçu' },
      { id: 5442, name: 'Itumbiara' },
      { id: 5443, name: 'Ivolândia' },
      { id: 5444, name: 'Jandaia' },
      { id: 5445, name: 'Jaraguá' },
      { id: 5446, name: 'Jataí' },
      { id: 5447, name: 'Jaupaci' },
      { id: 5448, name: 'Jesúpolis' },
      { id: 5449, name: 'Joviânia' },
      { id: 5450, name: 'Jussara' },
      { id: 5451, name: 'Lagoa Santa' },
      { id: 5452, name: 'Leopoldo de Bulhões' },
      { id: 5453, name: 'Luziânia' },
      { id: 5454, name: 'Mairipotaba' },
      { id: 5455, name: 'Mambaí' },
      { id: 5456, name: 'Mara Rosa' },
      { id: 5457, name: 'Marzagão' },
      { id: 5458, name: 'Matrinchã' },
      { id: 5459, name: 'Maurilândia' },
      { id: 5460, name: 'Mimoso de Goiás' },
      { id: 5461, name: 'Minaçu' },
      { id: 5462, name: 'Mineiros' },
      { id: 5463, name: 'Moiporá' },
      { id: 5464, name: 'Monte Alegre de Goiás' },
      { id: 5465, name: 'Montes Claros de Goiás' },
      { id: 5466, name: 'Montividiu' },
      { id: 5467, name: 'Montividiu do Norte' },
      { id: 5468, name: 'Morrinhos' },
      { id: 5469, name: 'Morro Agudo de Goiás' },
      { id: 5470, name: 'Mossâmedes' },
      { id: 5471, name: 'Mozarlândia' },
      { id: 5472, name: 'Mundo Novo' },
      { id: 5473, name: 'Mutunópolis' },
      { id: 5474, name: 'Nazário' },
      { id: 5475, name: 'Nerópolis' },
      { id: 5476, name: 'Niquelândia' },
      { id: 5477, name: 'Nova América' },
      { id: 5478, name: 'Nova Aurora' },
      { id: 5479, name: 'Nova Crixás' },
      { id: 5480, name: 'Nova Glória' },
      { id: 5481, name: 'Nova Iguaçu de Goiás' },
      { id: 5482, name: 'Nova Roma' },
      { id: 5483, name: 'Nova Veneza' },
      { id: 5484, name: 'Novo Brasil' },
      { id: 5485, name: 'Novo Gama' },
      { id: 5486, name: 'Novo Planalto' },
      { id: 5487, name: 'Orizona' },
      { id: 5488, name: 'Ouro Verde de Goiás' },
      { id: 5489, name: 'Ouvidor' },
      { id: 5490, name: 'Padre Bernardo' },
      { id: 5491, name: 'Palestina de Goiás' },
      { id: 5492, name: 'Palmeiras de Goiás' },
      { id: 5493, name: 'Palmelo' },
      { id: 5494, name: 'Palminópolis' },
      { id: 5495, name: 'Panamá' },
      { id: 5496, name: 'Paranaiguara' },
      { id: 5497, name: 'Paraúna' },
      { id: 5498, name: 'Perolândia' },
      { id: 5499, name: 'Petrolina de Goiás' },
      { id: 5500, name: 'Pilar de Goiás' },
      { id: 5501, name: 'Piracanjuba' },
      { id: 5502, name: 'Piranhas' },
      { id: 5503, name: 'Pirenópolis' },
      { id: 5504, name: 'Pires do Rio' },
      { id: 5505, name: 'Planaltina' },
      { id: 5506, name: 'Pontalina' },
      { id: 5507, name: 'Porangatu' },
      { id: 5508, name: 'Porteirão' },
      { id: 5509, name: 'Portelândia' },
      { id: 5510, name: 'Posse' },
      { id: 5511, name: 'Professor Jamil' },
      { id: 5512, name: 'Quirinópolis' },
      { id: 5513, name: 'Rialma' },
      { id: 5514, name: 'Rianápolis' },
      { id: 5515, name: 'Rio Quente' },
      { id: 5516, name: 'Rio Verde' },
      { id: 5517, name: 'Rubiataba' },
      { id: 5518, name: 'Sanclerlândia' },
      { id: 5519, name: 'Santa Bárbara de Goiás' },
      { id: 5520, name: 'Santa Cruz de Goiás' },
      { id: 5521, name: 'Santa Fé de Goiás' },
      { id: 5522, name: 'Santa Helena de Goiás' },
      { id: 5523, name: 'Santa Isabel' },
      { id: 5524, name: 'Santa Rita do Araguaia' },
      { id: 5525, name: 'Santa Rita do Novo Destino' },
      { id: 5526, name: 'Santa Rosa de Goiás' },
      { id: 5527, name: 'Santa Tereza de Goiás' },
      { id: 5528, name: 'Santa Terezinha de Goiás' },
      { id: 5529, name: 'Santo Antônio da Barra' },
      { id: 5530, name: 'Santo Antônio de Goiás' },
      { id: 5531, name: 'Santo Antônio do Descoberto' },
      { id: 5532, name: 'São Domingos' },
      { id: 5533, name: 'São Francisco de Goiás' },
      { id: 5534, name: "São João d'Aliança" },
      { id: 5535, name: 'São João da Paraúna' },
      { id: 5536, name: 'São Luís de Montes Belos' },
      { id: 5537, name: 'São Luíz do Norte' },
      { id: 5538, name: 'São Miguel do Araguaia' },
      { id: 5539, name: 'São Miguel do Passa Quatro' },
      { id: 5540, name: 'São Patrício' },
      { id: 5541, name: 'São Simão' },
      { id: 5542, name: 'Senador Canedo' },
      { id: 5543, name: 'Serranópolis' },
      { id: 5544, name: 'Silvânia' },
      { id: 5545, name: 'Simolândia' },
      { id: 5546, name: "Sítio d'Abadia" },
      { id: 5547, name: 'Taquaral de Goiás' },
      { id: 5548, name: 'Teresina de Goiás' },
      { id: 5549, name: 'Terezópolis de Goiás' },
      { id: 5550, name: 'Três Ranchos' },
      { id: 5551, name: 'Trindade' },
      { id: 5552, name: 'Trombas' },
      { id: 5553, name: 'Turvânia' },
      { id: 5554, name: 'Turvelândia' },
      { id: 5555, name: 'Uirapuru' },
      { id: 5556, name: 'Uruaçu' },
      { id: 5557, name: 'Uruana' },
      { id: 5558, name: 'Urutaí' },
      { id: 5559, name: 'Valparaíso de Goiás' },
      { id: 5560, name: 'Varjão' },
      { id: 5561, name: 'Vianópolis' },
      { id: 5562, name: 'Vicentinópolis' },
      { id: 5563, name: 'Vila Boa' },
      { id: 5564, name: 'Vila Propício' },
    ],
  },
  {
    id: 8,
    name: 'MA',
    fullname: 'Maranhão',
    cities: [
      { id: 150, name: 'São Luiz' },
      { id: 450, name: 'Açailândia' },
      { id: 451, name: 'Afonso Cunha' },
      { id: 452, name: 'Água Doce do Maranhão' },
      { id: 453, name: 'Alcântara' },
      { id: 454, name: 'Aldeias Altas' },
      { id: 455, name: 'Altamira do Maranhão' },
      { id: 456, name: 'Alto Alegre do Maranhão' },
      { id: 457, name: 'Alto Alegre do Pindaré' },
      { id: 458, name: 'Alto Parnaíba' },
      { id: 459, name: 'Amapá do Maranhão' },
      { id: 460, name: 'Amarante do Maranhão' },
      { id: 461, name: 'Anajatuba' },
      { id: 462, name: 'Anapurus' },
      { id: 463, name: 'Apicum-Açu' },
      { id: 464, name: 'Araguanã' },
      { id: 465, name: 'Araioses' },
      { id: 466, name: 'Arame' },
      { id: 467, name: 'Arari' },
      { id: 468, name: 'Axixá' },
      { id: 469, name: 'Bacabal' },
      { id: 470, name: 'Bacabeira' },
      { id: 471, name: 'Bacuri' },
      { id: 472, name: 'Bacurituba' },
      { id: 473, name: 'Balsas' },
      { id: 474, name: 'Barão de Grajaú' },
      { id: 475, name: 'Barra do Corda' },
      { id: 476, name: 'Barreirinhas' },
      { id: 478, name: 'Bela Vista do Maranhão' },
      { id: 477, name: 'Belágua' },
      { id: 479, name: 'Benedito Leite' },
      { id: 480, name: 'Bequimão' },
      { id: 481, name: 'Bernardo do Mearim' },
      { id: 482, name: 'Boa Vista do Gurupi' },
      { id: 483, name: 'Bom Jardim' },
      { id: 484, name: 'Bom Jesus das Selvas' },
      { id: 485, name: 'Bom Lugar' },
      { id: 486, name: 'Brejo' },
      { id: 487, name: 'Brejo de Areia' },
      { id: 488, name: 'Buriti' },
      { id: 489, name: 'Buriti Bravo' },
      { id: 490, name: 'Buriticupu' },
      { id: 491, name: 'Buritirana' },
      { id: 492, name: 'Cachoeira Grande' },
      { id: 493, name: 'Cajapió' },
      { id: 494, name: 'Cajari' },
      { id: 495, name: 'Campestre do Maranhão' },
      { id: 496, name: 'Cândido Mendes' },
      { id: 497, name: 'Cantanhede' },
      { id: 498, name: 'Capinzal do Norte' },
      { id: 499, name: 'Carolina' },
      { id: 500, name: 'Carutapera' },
      { id: 501, name: 'Caxias' },
      { id: 502, name: 'Cedral' },
      { id: 503, name: 'Central do Maranhão' },
      { id: 504, name: 'Centro do Guilherme' },
      { id: 505, name: 'Centro Novo do Maranhão' },
      { id: 506, name: 'Chapadinha' },
      { id: 507, name: 'Cidelândia' },
      { id: 508, name: 'Codó' },
      { id: 509, name: 'Coelho Neto' },
      { id: 510, name: 'Colinas' },
      { id: 511, name: 'Conceição do Lago-Açu' },
      { id: 512, name: 'Coroatá' },
      { id: 513, name: 'Cururupu' },
      { id: 514, name: 'Davinópolis' },
      { id: 515, name: 'Dom Pedro' },
      { id: 516, name: 'Duque Bacelar' },
      { id: 517, name: 'Esperantinópolis' },
      { id: 518, name: 'Estreito' },
      { id: 519, name: 'Feira Nova do Maranhão' },
      { id: 520, name: 'Fernando Falcão' },
      { id: 521, name: 'Formosa da Serra Negra' },
      { id: 522, name: 'Fortaleza dos Nogueiras' },
      { id: 523, name: 'Fortuna' },
      { id: 524, name: 'Godofredo Viana' },
      { id: 525, name: 'Gonçalves Dias' },
      { id: 526, name: 'Governador Archer' },
      { id: 527, name: 'Governador Edison Lobão' },
      { id: 528, name: 'Governador Eugênio Barros' },
      { id: 529, name: 'Governador Luiz Rocha' },
      { id: 530, name: 'Governador Newton Bello' },
      { id: 531, name: 'Governador Nunes Freire' },
      { id: 532, name: 'Graça Aranha' },
      { id: 533, name: 'Grajaú' },
      { id: 534, name: 'Guimarães' },
      { id: 535, name: 'Humberto de Campos' },
      { id: 536, name: 'Icatu' },
      { id: 537, name: 'Igarapé do Meio' },
      { id: 538, name: 'Igarapé Grande' },
      { id: 539, name: 'Imperatriz' },
      { id: 540, name: 'Itaipava do Grajaú' },
      { id: 541, name: 'Itapecuru Mirim' },
      { id: 542, name: 'Itinga do Maranhão' },
      { id: 543, name: 'Jatobá' },
      { id: 544, name: 'Jenipapo dos Vieiras' },
      { id: 545, name: 'João Lisboa' },
      { id: 546, name: 'Joselândia' },
      { id: 547, name: 'Junco do Maranhão' },
      { id: 548, name: 'Lago da Pedra' },
      { id: 549, name: 'Lago do Junco' },
      { id: 552, name: 'Lago dos Rodrigues' },
      { id: 550, name: 'Lago Verde' },
      { id: 551, name: 'Lagoa do Mato' },
      { id: 553, name: 'Lagoa Grande do Maranhão' },
      { id: 554, name: 'Lajeado Novo' },
      { id: 555, name: 'Lima Campos' },
      { id: 556, name: 'Loreto' },
      { id: 557, name: 'Luís Domingues' },
      { id: 558, name: 'Magalhães de Almeida' },
      { id: 559, name: 'Maracaçumé' },
      { id: 560, name: 'Marajá do Sena' },
      { id: 561, name: 'Maranhãozinho' },
      { id: 562, name: 'Mata Roma' },
      { id: 563, name: 'Matinha' },
      { id: 564, name: 'Matões' },
      { id: 565, name: 'Matões do Norte' },
      { id: 566, name: 'Milagres do Maranhão' },
      { id: 567, name: 'Mirador' },
      { id: 568, name: 'Miranda do Norte' },
      { id: 569, name: 'Mirinzal' },
      { id: 570, name: 'Monção' },
      { id: 571, name: 'Montes Altos' },
      { id: 572, name: 'Morros' },
      { id: 573, name: 'Nina Rodrigues' },
      { id: 574, name: 'Nova Colinas' },
      { id: 575, name: 'Nova Iorque' },
      { id: 576, name: 'Nova Olinda do Maranhão' },
      { id: 577, name: "Olho d'Água das Cunhãs" },
      { id: 578, name: 'Olinda Nova do Maranhão' },
      { id: 579, name: 'Paço do Lumiar' },
      { id: 580, name: 'Palmeirândia' },
      { id: 581, name: 'Paraibano' },
      { id: 582, name: 'Parnarama' },
      { id: 583, name: 'Passagem Franca' },
      { id: 584, name: 'Pastos Bons' },
      { id: 585, name: 'Paulino Neves' },
      { id: 586, name: 'Paulo Ramos' },
      { id: 587, name: 'Pedreiras' },
      { id: 588, name: 'Pedro do Rosário' },
      { id: 589, name: 'Penalva' },
      { id: 590, name: 'Peri Mirim' },
      { id: 591, name: 'Peritoró' },
      { id: 592, name: 'Pindaré-Mirim' },
      { id: 593, name: 'Pinheiro' },
      { id: 594, name: 'Pio XII' },
      { id: 595, name: 'Pirapemas' },
      { id: 596, name: 'Poção de Pedras' },
      { id: 597, name: 'Porto Franco' },
      { id: 598, name: 'Porto Rico do Maranhão' },
      { id: 599, name: 'Presidente Dutra' },
      { id: 600, name: 'Presidente Juscelino' },
      { id: 601, name: 'Presidente Médici' },
      { id: 602, name: 'Presidente Sarney' },
      { id: 603, name: 'Presidente Vargas' },
      { id: 604, name: 'Primeira Cruz' },
      { id: 605, name: 'Raposa' },
      { id: 606, name: 'Riachão' },
      { id: 607, name: 'Ribamar Fiquene' },
      { id: 608, name: 'Rosário' },
      { id: 609, name: 'Sambaíba' },
      { id: 610, name: 'Santa Filomena do Maranhão' },
      { id: 611, name: 'Santa Helena' },
      { id: 612, name: 'Santa Inês' },
      { id: 613, name: 'Santa Luzia' },
      { id: 614, name: 'Santa Luzia do Paruá' },
      { id: 615, name: 'Santa Quitéria do Maranhão' },
      { id: 616, name: 'Santa Rita' },
      { id: 617, name: 'Santana do Maranhão' },
      { id: 618, name: 'Santo Amaro do Maranhão' },
      { id: 619, name: 'Santo Antônio dos Lopes' },
      { id: 620, name: 'São Benedito do Rio Preto' },
      { id: 621, name: 'São Bento' },
      { id: 622, name: 'São Bernardo' },
      { id: 623, name: 'São Domingos do Azeitão' },
      { id: 624, name: 'São Domingos do Maranhão' },
      { id: 625, name: 'São Félix de Balsas' },
      { id: 626, name: 'São Francisco do Brejão' },
      { id: 627, name: 'São Francisco do Maranhão' },
      { id: 628, name: 'São João Batista' },
      { id: 629, name: 'São João do Carú' },
      { id: 630, name: 'São João do Paraíso' },
      { id: 631, name: 'São João do Soter' },
      { id: 632, name: 'São João dos Patos' },
      { id: 633, name: 'São José de Ribamar' },
      { id: 634, name: 'São José dos Basílios' },
      { id: 635, name: 'São Luís' },
      { id: 636, name: 'São Luís Gonzaga do Maranhão' },
      { id: 637, name: 'São Mateus do Maranhão' },
      { id: 638, name: 'São Pedro da Água Branca' },
      { id: 639, name: 'São Pedro dos Crentes' },
      { id: 640, name: 'São Raimundo das Mangabeiras' },
      { id: 641, name: 'São Raimundo do Doca Bezerra' },
      { id: 642, name: 'São Roberto' },
      { id: 643, name: 'São Vicente Ferrer' },
      { id: 644, name: 'Satubinha' },
      { id: 645, name: 'Senador Alexandre Costa' },
      { id: 646, name: 'Senador La Rocque' },
      { id: 647, name: 'Serrano do Maranhão' },
      { id: 648, name: 'Sítio Novo' },
      { id: 649, name: 'Sucupira do Norte' },
      { id: 650, name: 'Sucupira do Riachão' },
      { id: 651, name: 'Tasso Fragoso' },
      { id: 652, name: 'Timbiras' },
      { id: 653, name: 'Timon' },
      { id: 654, name: 'Trizidela do Vale' },
      { id: 655, name: 'Tufilândia' },
      { id: 656, name: 'Tuntum' },
      { id: 657, name: 'Turiaçu' },
      { id: 658, name: 'Turilândia' },
      { id: 659, name: 'Tutóia' },
      { id: 660, name: 'Urbano Santos' },
      { id: 661, name: 'Vargem Grande' },
      { id: 662, name: 'Viana' },
      { id: 663, name: 'Vila Nova dos Martírios' },
      { id: 664, name: 'Vitória do Mearim' },
      { id: 665, name: 'Vitorino Freire' },
      { id: 666, name: 'Zé Doca' },
    ],
  },
  {
    id: 17,
    name: 'MG',
    fullname: 'Minas Gerais',
    cities: [
      { id: 2309, name: 'Belo Horizonte' },
      { id: 2244, name: 'Abadia dos Dourados' },
      { id: 2245, name: 'Abaeté' },
      { id: 2246, name: 'Abre Campo' },
      { id: 2247, name: 'Acaiaca' },
      { id: 2248, name: 'Açucena' },
      { id: 2249, name: 'Água Boa' },
      { id: 2250, name: 'Água Comprida' },
      { id: 2251, name: 'Aguanil' },
      { id: 2252, name: 'Águas Formosas' },
      { id: 2253, name: 'Águas Vermelhas' },
      { id: 2254, name: 'Aimorés' },
      { id: 2255, name: 'Aiuruoca' },
      { id: 2256, name: 'Alagoa' },
      { id: 2257, name: 'Albertina' },
      { id: 2258, name: 'Além Paraíba' },
      { id: 2259, name: 'Alfenas' },
      { id: 2260, name: 'Alfredo Vasconcelos' },
      { id: 2261, name: 'Almenara' },
      { id: 2262, name: 'Alpercata' },
      { id: 2263, name: 'Alpinópolis' },
      { id: 2264, name: 'Alterosa' },
      { id: 2265, name: 'Alto Caparaó' },
      { id: 2867, name: 'Alto Jequitibá' },
      { id: 2266, name: 'Alto Rio Doce' },
      { id: 2267, name: 'Alvarenga' },
      { id: 2268, name: 'Alvinópolis' },
      { id: 2269, name: 'Alvorada de Minas' },
      { id: 2270, name: 'Amparo do Serra' },
      { id: 2271, name: 'Andradas' },
      { id: 2273, name: 'Andrelândia' },
      { id: 2274, name: 'Angelândia' },
      { id: 2275, name: 'Antônio Carlos' },
      { id: 2276, name: 'Antônio Dias' },
      { id: 2277, name: 'Antônio Prado de Minas' },
      { id: 2278, name: 'Araçaí' },
      { id: 2279, name: 'Aracitaba' },
      { id: 2280, name: 'Araçuaí' },
      { id: 2281, name: 'Araguari' },
      { id: 2282, name: 'Arantina' },
      { id: 2283, name: 'Araponga' },
      { id: 2284, name: 'Araporã' },
      { id: 2285, name: 'Arapuá' },
      { id: 2286, name: 'Araújos' },
      { id: 2287, name: 'Araxá' },
      { id: 2288, name: 'Arceburgo' },
      { id: 2289, name: 'Arcos' },
      { id: 2290, name: 'Areado' },
      { id: 2291, name: 'Argirita' },
      { id: 2292, name: 'Aricanduva' },
      { id: 2293, name: 'Arinos' },
      { id: 2294, name: 'Astolfo Dutra' },
      { id: 2295, name: 'Ataléia' },
      { id: 2296, name: 'Augusto de Lima' },
      { id: 2297, name: 'Baependi' },
      { id: 2298, name: 'Baldim' },
      { id: 2299, name: 'Bambuí' },
      { id: 2300, name: 'Bandeira' },
      { id: 2301, name: 'Bandeira do Sul' },
      { id: 2302, name: 'Barão de Cocais' },
      { id: 2303, name: 'Barão de Monte Alto' },
      { id: 2304, name: 'Barbacena' },
      { id: 2305, name: 'Barra Longa' },
      { id: 2306, name: 'Barroso' },
      { id: 2307, name: 'Bela Vista de Minas' },
      { id: 2308, name: 'Belmiro Braga' },
      { id: 2309, name: 'Belo Horizonte' },
      { id: 2310, name: 'Belo Oriente' },
      { id: 2311, name: 'Belo Vale' },
      { id: 2312, name: 'Berilo' },
      { id: 2314, name: 'Berizal' },
      { id: 2313, name: 'Bertópolis' },
      { id: 2315, name: 'Betim' },
      { id: 2316, name: 'Bias Fortes' },
      { id: 2317, name: 'Bicas' },
      { id: 2318, name: 'Biquinhas' },
      { id: 2319, name: 'Boa Esperança' },
      { id: 2320, name: 'Bocaina de Minas' },
      { id: 2321, name: 'Bocaiúva' },
      { id: 2322, name: 'Bom Despacho' },
      { id: 2323, name: 'Bom Jardim de Minas' },
      { id: 2324, name: 'Bom Jesus da Penha' },
      { id: 2325, name: 'Bom Jesus do Amparo' },
      { id: 2326, name: 'Bom Jesus do Galho' },
      { id: 2327, name: 'Bom Repouso' },
      { id: 2328, name: 'Bom Sucesso' },
      { id: 2329, name: 'Bonfim' },
      { id: 2330, name: 'Bonfinópolis de Minas' },
      { id: 2331, name: 'Bonito de Minas' },
      { id: 2332, name: 'Borda da Mata' },
      { id: 2333, name: 'Botelhos' },
      { id: 2334, name: 'Botumirim' },
      { id: 2337, name: 'Brás Pires' },
      { id: 2335, name: 'Brasilândia de Minas' },
      { id: 2336, name: 'Brasília de Minas' },
      { id: 2339, name: 'Brasópolis' },
      { id: 2338, name: 'Braúnas' },
      { id: 2340, name: 'Brumadinho' },
      { id: 2341, name: 'Bueno Brandão' },
      { id: 2342, name: 'Buenópolis' },
      { id: 2343, name: 'Bugre' },
      { id: 2344, name: 'Buritis' },
      { id: 2345, name: 'Buritizeiro' },
      { id: 2346, name: 'Cabeceira Grande' },
      { id: 2347, name: 'Cabo Verde' },
      { id: 2348, name: 'Cachoeira da Prata' },
      { id: 2349, name: 'Cachoeira de Minas' },
      { id: 2272, name: 'Cachoeira de Pajeú' },
      { id: 2350, name: 'Cachoeira Dourada' },
      { id: 2351, name: 'Caetanópolis' },
      { id: 2352, name: 'Caeté' },
      { id: 2353, name: 'Caiana' },
      { id: 2354, name: 'Cajuri' },
      { id: 2355, name: 'Caldas' },
      { id: 2356, name: 'Camacho' },
      { id: 2357, name: 'Camanducaia' },
      { id: 2358, name: 'Cambuí' },
      { id: 2359, name: 'Cambuquira' },
      { id: 2360, name: 'Campanário' },
      { id: 2361, name: 'Campanha' },
      { id: 2362, name: 'Campestre' },
      { id: 2363, name: 'Campina Verde' },
      { id: 2364, name: 'Campo Azul' },
      { id: 2365, name: 'Campo Belo' },
      { id: 2366, name: 'Campo do Meio' },
      { id: 2367, name: 'Campo Florido' },
      { id: 2368, name: 'Campos Altos' },
      { id: 2369, name: 'Campos Gerais' },
      { id: 2372, name: 'Cana Verde' },
      { id: 2370, name: 'Canaã' },
      { id: 2371, name: 'Canápolis' },
      { id: 2373, name: 'Candeias' },
      { id: 2374, name: 'Cantagalo' },
      { id: 2375, name: 'Caparaó' },
      { id: 2376, name: 'Capela Nova' },
      { id: 2377, name: 'Capelinha' },
      { id: 2378, name: 'Capetinga' },
      { id: 2379, name: 'Capim Branco' },
      { id: 2380, name: 'Capinópolis' },
      { id: 2381, name: 'Capitão Andrade' },
      { id: 2382, name: 'Capitão Enéas' },
      { id: 2383, name: 'Capitólio' },
      { id: 2384, name: 'Caputira' },
      { id: 2385, name: 'Caraí' },
      { id: 2386, name: 'Caranaíba' },
      { id: 2387, name: 'Carandaí' },
      { id: 2388, name: 'Carangola' },
      { id: 2389, name: 'Caratinga' },
      { id: 2390, name: 'Carbonita' },
      { id: 2391, name: 'Careaçu' },
      { id: 2392, name: 'Carlos Chagas' },
      { id: 2393, name: 'Carmésia' },
      { id: 2394, name: 'Carmo da Cachoeira' },
      { id: 2395, name: 'Carmo da Mata' },
      { id: 2396, name: 'Carmo de Minas' },
      { id: 2397, name: 'Carmo do Cajuru' },
      { id: 2398, name: 'Carmo do Paranaíba' },
      { id: 2399, name: 'Carmo do Rio Claro' },
      { id: 2400, name: 'Carmópolis de Minas' },
      { id: 2401, name: 'Carneirinho' },
      { id: 2402, name: 'Carrancas' },
      { id: 2403, name: 'Carvalhópolis' },
      { id: 2404, name: 'Carvalhos' },
      { id: 2405, name: 'Casa Grande' },
      { id: 2406, name: 'Cascalho Rico' },
      { id: 2407, name: 'Cássia' },
      { id: 2409, name: 'Cataguases' },
      { id: 2410, name: 'Catas Altas' },
      { id: 2411, name: 'Catas Altas da Noruega' },
      { id: 2412, name: 'Catuji' },
      { id: 2413, name: 'Catuti' },
      { id: 2414, name: 'Caxambu' },
      { id: 2415, name: 'Cedro do Abaeté' },
      { id: 2416, name: 'Central de Minas' },
      { id: 2417, name: 'Centralina' },
      { id: 2418, name: 'Chácara' },
      { id: 2419, name: 'Chalé' },
      { id: 2420, name: 'Chapada do Norte' },
      { id: 2421, name: 'Chapada Gaúcha' },
      { id: 2422, name: 'Chiador' },
      { id: 2423, name: 'Cipotânea' },
      { id: 2424, name: 'Claraval' },
      { id: 2425, name: 'Claro dos Poções' },
      { id: 2426, name: 'Cláudio' },
      { id: 2427, name: 'Coimbra' },
      { id: 2428, name: 'Coluna' },
      { id: 2429, name: 'Comendador Gomes' },
      { id: 2430, name: 'Comercinho' },
      { id: 2431, name: 'Conceição da Aparecida' },
      { id: 2408, name: 'Conceição da Barra de Minas' },
      { id: 2433, name: 'Conceição das Alagoas' },
      { id: 2432, name: 'Conceição das Pedras' },
      { id: 2434, name: 'Conceição de Ipanema' },
      { id: 2435, name: 'Conceição do Mato Dentro' },
      { id: 2436, name: 'Conceição do Pará' },
      { id: 2437, name: 'Conceição do Rio Verde' },
      { id: 2438, name: 'Conceição dos Ouros' },
      { id: 2439, name: 'Cônego Marinho' },
      { id: 2440, name: 'Confins' },
      { id: 2441, name: 'Congonhal' },
      { id: 2442, name: 'Congonhas' },
      { id: 2443, name: 'Congonhas do Norte' },
      { id: 2444, name: 'Conquista' },
      { id: 2445, name: 'Conselheiro Lafaiete' },
      { id: 2446, name: 'Conselheiro Pena' },
      { id: 2447, name: 'Consolação' },
      { id: 2448, name: 'Contagem' },
      { id: 2449, name: 'Coqueiral' },
      { id: 2450, name: 'Coração de Jesus' },
      { id: 2451, name: 'Cordisburgo' },
      { id: 2452, name: 'Cordislândia' },
      { id: 2453, name: 'Corinto' },
      { id: 2454, name: 'Coroaci' },
      { id: 2455, name: 'Coromandel' },
      { id: 2456, name: 'Coronel Fabriciano' },
      { id: 2457, name: 'Coronel Murta' },
      { id: 2458, name: 'Coronel Pacheco' },
      { id: 2459, name: 'Coronel Xavier Chaves' },
      { id: 2460, name: 'Córrego Danta' },
      { id: 2461, name: 'Córrego do Bom Jesus' },
      { id: 2462, name: 'Córrego Fundo' },
      { id: 2463, name: 'Córrego Novo' },
      { id: 2464, name: 'Couto de Magalhães de Minas' },
      { id: 2465, name: 'Crisólita' },
      { id: 2466, name: 'Cristais' },
      { id: 2467, name: 'Cristália' },
      { id: 2468, name: 'Cristiano Otoni' },
      { id: 2469, name: 'Cristina' },
      { id: 2470, name: 'Crucilândia' },
      { id: 2471, name: 'Cruzeiro da Fortaleza' },
      { id: 2472, name: 'Cruzília' },
      { id: 2473, name: 'Cuparaque' },
      { id: 2474, name: 'Curral de Dentro' },
      { id: 2475, name: 'Curvelo' },
      { id: 2476, name: 'Datas' },
      { id: 2477, name: 'Delfim Moreira' },
      { id: 2478, name: 'Delfinópolis' },
      { id: 2479, name: 'Delta' },
      { id: 2480, name: 'Descoberto' },
      { id: 2481, name: 'Desterro de Entre Rios' },
      { id: 2482, name: 'Desterro do Melo' },
      { id: 2483, name: 'Diamantina' },
      { id: 2484, name: 'Diogo de Vasconcelos' },
      { id: 2485, name: 'Dionísio' },
      { id: 2486, name: 'Divinésia' },
      { id: 2487, name: 'Divino' },
      { id: 2488, name: 'Divino das Laranjeiras' },
      { id: 2489, name: 'Divinolândia de Minas' },
      { id: 2490, name: 'Divinópolis' },
      { id: 2491, name: 'Divisa Alegre' },
      { id: 2492, name: 'Divisa Nova' },
      { id: 2493, name: 'Divisópolis' },
      { id: 2494, name: 'Dom Bosco' },
      { id: 2495, name: 'Dom Cavati' },
      { id: 2496, name: 'Dom Joaquim' },
      { id: 2497, name: 'Dom Silvério' },
      { id: 2498, name: 'Dom Viçoso' },
      { id: 2499, name: 'Dona Eusébia' },
      { id: 2500, name: 'Dores de Campos' },
      { id: 2501, name: 'Dores de Guanhães' },
      { id: 2502, name: 'Dores do Indaiá' },
      { id: 2503, name: 'Dores do Turvo' },
      { id: 2504, name: 'Doresópolis' },
      { id: 2505, name: 'Douradoquara' },
      { id: 2506, name: 'Durandé' },
      { id: 2507, name: 'Elói Mendes' },
      { id: 2508, name: 'Engenheiro Caldas' },
      { id: 2509, name: 'Engenheiro Navarro' },
      { id: 2510, name: 'Entre Folhas' },
      { id: 2511, name: 'Entre Rios de Minas' },
      { id: 2512, name: 'Ervália' },
      { id: 2513, name: 'Esmeraldas' },
      { id: 2514, name: 'Espera Feliz' },
      { id: 2515, name: 'Espinosa' },
      { id: 2516, name: 'Espírito Santo do Dourado' },
      { id: 2517, name: 'Estiva' },
      { id: 2518, name: 'Estrela Dalva' },
      { id: 2519, name: 'Estrela do Indaiá' },
      { id: 2520, name: 'Estrela do Sul' },
      { id: 2521, name: 'Eugenópolis' },
      { id: 2522, name: 'Ewbank da Câmara' },
      { id: 2523, name: 'Extrema' },
      { id: 2524, name: 'Fama' },
      { id: 2525, name: 'Faria Lemos' },
      { id: 2526, name: 'Felício dos Santos' },
      { id: 2528, name: 'Felisburgo' },
      { id: 2529, name: 'Felixlândia' },
      { id: 2530, name: 'Fernandes Tourinho' },
      { id: 2531, name: 'Ferros' },
      { id: 2532, name: 'Fervedouro' },
      { id: 2533, name: 'Florestal' },
      { id: 2534, name: 'Formiga' },
      { id: 2535, name: 'Formoso' },
      { id: 2536, name: 'Fortaleza de Minas' },
      { id: 2537, name: 'Fortuna de Minas' },
      { id: 2538, name: 'Francisco Badaró' },
      { id: 2539, name: 'Francisco Dumont' },
      { id: 2540, name: 'Francisco Sá' },
      { id: 2541, name: 'Franciscópolis' },
      { id: 2542, name: 'Frei Gaspar' },
      { id: 2543, name: 'Frei Inocêncio' },
      { id: 2544, name: 'Frei Lagonegro' },
      { id: 2545, name: 'Fronteira' },
      { id: 2546, name: 'Fronteira dos Vales' },
      { id: 2547, name: 'Fruta de Leite' },
      { id: 2548, name: 'Frutal' },
      { id: 2549, name: 'Funilândia' },
      { id: 2550, name: 'Galiléia' },
      { id: 2551, name: 'Gameleiras' },
      { id: 2552, name: 'Glaucilândia' },
      { id: 2553, name: 'Goiabeira' },
      { id: 2554, name: 'Goianá' },
      { id: 2555, name: 'Gonçalves' },
      { id: 2556, name: 'Gonzaga' },
      { id: 2557, name: 'Gouveia' },
      { id: 2558, name: 'Governador Valadares' },
      { id: 2559, name: 'Grão Mogol' },
      { id: 2560, name: 'Grupiara' },
      { id: 2561, name: 'Guanhães' },
      { id: 2562, name: 'Guapé' },
      { id: 2563, name: 'Guaraciaba' },
      { id: 2564, name: 'Guaraciama' },
      { id: 2565, name: 'Guaranésia' },
      { id: 2566, name: 'Guarani' },
      { id: 2567, name: 'Guarará' },
      { id: 2568, name: 'Guarda-Mor' },
      { id: 2569, name: 'Guaxupé' },
      { id: 2570, name: 'Guidoval' },
      { id: 2571, name: 'Guimarânia' },
      { id: 2572, name: 'Guiricema' },
      { id: 2573, name: 'Gurinhatã' },
      { id: 2574, name: 'Heliodora' },
      { id: 2575, name: 'Iapu' },
      { id: 2576, name: 'Ibertioga' },
      { id: 2577, name: 'Ibiá' },
      { id: 2578, name: 'Ibiaí' },
      { id: 2579, name: 'Ibiracatu' },
      { id: 2580, name: 'Ibiraci' },
      { id: 2581, name: 'Ibirité' },
      { id: 2582, name: 'Ibitiúra de Minas' },
      { id: 2583, name: 'Ibituruna' },
      { id: 2584, name: 'Icaraí de Minas' },
      { id: 2585, name: 'Igarapé' },
      { id: 2586, name: 'Igaratinga' },
      { id: 2587, name: 'Iguatama' },
      { id: 2588, name: 'Ijaci' },
      { id: 2589, name: 'Ilicínea' },
      { id: 2590, name: 'Imbé de Minas' },
      { id: 2591, name: 'Inconfidentes' },
      { id: 2592, name: 'Indaiabira' },
      { id: 2593, name: 'Indianópolis' },
      { id: 2594, name: 'Ingaí' },
      { id: 2595, name: 'Inhapim' },
      { id: 2596, name: 'Inhaúma' },
      { id: 2597, name: 'Inimutaba' },
      { id: 2598, name: 'Ipaba' },
      { id: 2599, name: 'Ipanema' },
      { id: 2600, name: 'Ipatinga' },
      { id: 2601, name: 'Ipiaçu' },
      { id: 2602, name: 'Ipuiúna' },
      { id: 2603, name: 'Iraí de Minas' },
      { id: 2604, name: 'Itabira' },
      { id: 2605, name: 'Itabirinha' },
      { id: 2606, name: 'Itabirito' },
      { id: 2607, name: 'Itacambira' },
      { id: 2608, name: 'Itacarambi' },
      { id: 2609, name: 'Itaguara' },
      { id: 2610, name: 'Itaipé' },
      { id: 2611, name: 'Itajubá' },
      { id: 2612, name: 'Itamarandiba' },
      { id: 2613, name: 'Itamarati de Minas' },
      { id: 2614, name: 'Itambacuri' },
      { id: 2615, name: 'Itambé do Mato Dentro' },
      { id: 2616, name: 'Itamogi' },
      { id: 2617, name: 'Itamonte' },
      { id: 2618, name: 'Itanhandu' },
      { id: 2619, name: 'Itanhomi' },
      { id: 2620, name: 'Itaobim' },
      { id: 2621, name: 'Itapagipe' },
      { id: 2622, name: 'Itapecerica' },
      { id: 2623, name: 'Itapeva' },
      { id: 2624, name: 'Itatiaiuçu' },
      { id: 2625, name: 'Itaú de Minas' },
      { id: 2626, name: 'Itaúna' },
      { id: 2627, name: 'Itaverava' },
      { id: 2628, name: 'Itinga' },
      { id: 2629, name: 'Itueta' },
      { id: 2630, name: 'Ituiutaba' },
      { id: 2631, name: 'Itumirim' },
      { id: 2632, name: 'Iturama' },
      { id: 2633, name: 'Itutinga' },
      { id: 2634, name: 'Jaboticatubas' },
      { id: 2635, name: 'Jacinto' },
      { id: 2636, name: 'Jacuí' },
      { id: 2637, name: 'Jacutinga' },
      { id: 2638, name: 'Jaguaraçu' },
      { id: 2639, name: 'Jaíba' },
      { id: 2640, name: 'Jampruca' },
      { id: 2641, name: 'Janaúba' },
      { id: 2642, name: 'Januária' },
      { id: 2643, name: 'Japaraíba' },
      { id: 2644, name: 'Japonvar' },
      { id: 2645, name: 'Jeceaba' },
      { id: 2646, name: 'Jenipapo de Minas' },
      { id: 2647, name: 'Jequeri' },
      { id: 2648, name: 'Jequitaí' },
      { id: 2649, name: 'Jequitibá' },
      { id: 2650, name: 'Jequitinhonha' },
      { id: 2651, name: 'Jesuânia' },
      { id: 2652, name: 'Joaíma' },
      { id: 2653, name: 'Joanésia' },
      { id: 2654, name: 'João Monlevade' },
      { id: 2655, name: 'João Pinheiro' },
      { id: 2656, name: 'Joaquim Felício' },
      { id: 2657, name: 'Jordânia' },
      { id: 2658, name: 'José Gonçalves de Minas' },
      { id: 2659, name: 'José Raydan' },
      { id: 2660, name: 'Josenópolis' },
      { id: 2662, name: 'Juatuba' },
      { id: 2663, name: 'Juiz de Fora' },
      { id: 2664, name: 'Juramento' },
      { id: 2665, name: 'Juruaia' },
      { id: 2666, name: 'Juvenília' },
      { id: 2667, name: 'Ladainha' },
      { id: 2668, name: 'Lagamar' },
      { id: 2669, name: 'Lagoa da Prata' },
      { id: 2670, name: 'Lagoa dos Patos' },
      { id: 2671, name: 'Lagoa Dourada' },
      { id: 2672, name: 'Lagoa Formosa' },
      { id: 2673, name: 'Lagoa Grande' },
      { id: 2674, name: 'Lagoa Santa' },
      { id: 2675, name: 'Lajinha' },
      { id: 2676, name: 'Lambari' },
      { id: 2677, name: 'Lamim' },
      { id: 2678, name: 'Laranjal' },
      { id: 2679, name: 'Lassance' },
      { id: 2680, name: 'Lavras' },
      { id: 2681, name: 'Leandro Ferreira' },
      { id: 2682, name: 'Leme do Prado' },
      { id: 2683, name: 'Leopoldina' },
      { id: 2684, name: 'Liberdade' },
      { id: 2685, name: 'Lima Duarte' },
      { id: 2686, name: 'Limeira do Oeste' },
      { id: 2687, name: 'Lontra' },
      { id: 2688, name: 'Luisburgo' },
      { id: 2689, name: 'Luislândia' },
      { id: 2690, name: 'Luminárias' },
      { id: 2691, name: 'Luz' },
      { id: 2692, name: 'Machacalis' },
      { id: 2693, name: 'Machado' },
      { id: 2694, name: 'Madre de Deus de Minas' },
      { id: 2695, name: 'Malacacheta' },
      { id: 2696, name: 'Mamonas' },
      { id: 2697, name: 'Manga' },
      { id: 2698, name: 'Manhuaçu' },
      { id: 2699, name: 'Manhumirim' },
      { id: 2700, name: 'Mantena' },
      { id: 2702, name: 'Mar de Espanha' },
      { id: 2701, name: 'Maravilhas' },
      { id: 2703, name: 'Maria da Fé' },
      { id: 2704, name: 'Mariana' },
      { id: 2705, name: 'Marilac' },
      { id: 2706, name: 'Mário Campos' },
      { id: 2707, name: 'Maripá de Minas' },
      { id: 2708, name: 'Marliéria' },
      { id: 2709, name: 'Marmelópolis' },
      { id: 2710, name: 'Martinho Campos' },
      { id: 2711, name: 'Martins Soares' },
      { id: 2712, name: 'Mata Verde' },
      { id: 2713, name: 'Materlândia' },
      { id: 2714, name: 'Mateus Leme' },
      { id: 3089, name: 'Mathias Lobato' },
      { id: 2715, name: 'Matias Barbosa' },
      { id: 2716, name: 'Matias Cardoso' },
      { id: 2717, name: 'Matipó' },
      { id: 2718, name: 'Mato Verde' },
      { id: 2719, name: 'Matozinhos' },
      { id: 2720, name: 'Matutina' },
      { id: 2721, name: 'Medeiros' },
      { id: 2722, name: 'Medina' },
      { id: 2723, name: 'Mendes Pimentel' },
      { id: 2724, name: 'Mercês' },
      { id: 2725, name: 'Mesquita' },
      { id: 2726, name: 'Minas Novas' },
      { id: 2727, name: 'Minduri' },
      { id: 2728, name: 'Mirabela' },
      { id: 2729, name: 'Miradouro' },
      { id: 2730, name: 'Miraí' },
      { id: 2731, name: 'Miravânia' },
      { id: 2732, name: 'Moeda' },
      { id: 2733, name: 'Moema' },
      { id: 2734, name: 'Monjolos' },
      { id: 2735, name: 'Monsenhor Paulo' },
      { id: 2736, name: 'Montalvânia' },
      { id: 2737, name: 'Monte Alegre de Minas' },
      { id: 2738, name: 'Monte Azul' },
      { id: 2739, name: 'Monte Belo' },
      { id: 2740, name: 'Monte Carmelo' },
      { id: 2741, name: 'Monte Formoso' },
      { id: 2742, name: 'Monte Santo de Minas' },
      { id: 2744, name: 'Monte Sião' },
      { id: 2743, name: 'Montes Claros' },
      { id: 2745, name: 'Montezuma' },
      { id: 2746, name: 'Morada Nova de Minas' },
      { id: 2747, name: 'Morro da Garça' },
      { id: 2748, name: 'Morro do Pilar' },
      { id: 2749, name: 'Munhoz' },
      { id: 2750, name: 'Muriaé' },
      { id: 2751, name: 'Mutum' },
      { id: 2752, name: 'Muzambinho' },
      { id: 2753, name: 'Nacip Raydan' },
      { id: 2754, name: 'Nanuque' },
      { id: 2755, name: 'Naque' },
      { id: 2756, name: 'Natalândia' },
      { id: 2757, name: 'Natércia' },
      { id: 2758, name: 'Nazareno' },
      { id: 2759, name: 'Nepomuceno' },
      { id: 2760, name: 'Ninheira' },
      { id: 2761, name: 'Nova Belém' },
      { id: 2762, name: 'Nova Era' },
      { id: 2763, name: 'Nova Lima' },
      { id: 2764, name: 'Nova Módica' },
      { id: 2765, name: 'Nova Ponte' },
      { id: 2766, name: 'Nova Porteirinha' },
      { id: 2767, name: 'Nova Resende' },
      { id: 2768, name: 'Nova Serrana' },
      { id: 2661, name: 'Nova União' },
      { id: 2769, name: 'Novo Cruzeiro' },
      { id: 2770, name: 'Novo Oriente de Minas' },
      { id: 2771, name: 'Novorizonte' },
      { id: 2772, name: 'Olaria' },
      { id: 2773, name: "Olhos-d'Água" },
      { id: 2774, name: 'Olímpio Noronha' },
      { id: 2775, name: 'Oliveira' },
      { id: 2776, name: 'Oliveira Fortes' },
      { id: 2777, name: 'Onça de Pitangui' },
      { id: 2778, name: 'Oratórios' },
      { id: 2779, name: 'Orizânia' },
      { id: 2780, name: 'Ouro Branco' },
      { id: 2781, name: 'Ouro Fino' },
      { id: 2782, name: 'Ouro Preto' },
      { id: 2783, name: 'Ouro Verde de Minas' },
      { id: 2784, name: 'Padre Carvalho' },
      { id: 2785, name: 'Padre Paraíso' },
      { id: 2788, name: 'Pai Pedro' },
      { id: 2786, name: 'Paineiras' },
      { id: 2787, name: 'Pains' },
      { id: 2789, name: 'Paiva' },
      { id: 2790, name: 'Palma' },
      { id: 2791, name: 'Palmópolis' },
      { id: 2792, name: 'Papagaios' },
      { id: 2794, name: 'Pará de Minas' },
      { id: 2793, name: 'Paracatu' },
      { id: 2795, name: 'Paraguaçu' },
      { id: 2796, name: 'Paraisópolis' },
      { id: 2797, name: 'Paraopeba' },
      { id: 2799, name: 'Passa Quatro' },
      { id: 2800, name: 'Passa Tempo' },
      { id: 2801, name: 'Passa-Vinte' },
      { id: 2798, name: 'Passabém' },
      { id: 2802, name: 'Passos' },
      { id: 2803, name: 'Patis' },
      { id: 2804, name: 'Patos de Minas' },
      { id: 2805, name: 'Patrocínio' },
      { id: 2806, name: 'Patrocínio do Muriaé' },
      { id: 2807, name: 'Paula Cândido' },
      { id: 2808, name: 'Paulistas' },
      { id: 2809, name: 'Pavão' },
      { id: 2810, name: 'Peçanha' },
      { id: 2811, name: 'Pedra Azul' },
      { id: 2812, name: 'Pedra Bonita' },
      { id: 2813, name: 'Pedra do Anta' },
      { id: 2814, name: 'Pedra do Indaiá' },
      { id: 2815, name: 'Pedra Dourada' },
      { id: 2816, name: 'Pedralva' },
      { id: 2817, name: 'Pedras de Maria da Cruz' },
      { id: 2818, name: 'Pedrinópolis' },
      { id: 2819, name: 'Pedro Leopoldo' },
      { id: 2820, name: 'Pedro Teixeira' },
      { id: 2821, name: 'Pequeri' },
      { id: 2822, name: 'Pequi' },
      { id: 2823, name: 'Perdigão' },
      { id: 2824, name: 'Perdizes' },
      { id: 2825, name: 'Perdões' },
      { id: 2826, name: 'Periquito' },
      { id: 2827, name: 'Pescador' },
      { id: 2828, name: 'Piau' },
      { id: 2829, name: 'Piedade de Caratinga' },
      { id: 2830, name: 'Piedade de Ponte Nova' },
      { id: 2831, name: 'Piedade do Rio Grande' },
      { id: 2832, name: 'Piedade dos Gerais' },
      { id: 2833, name: 'Pimenta' },
      { id: 2834, name: "Pingo-d'Água" },
      { id: 2835, name: 'Pintópolis' },
      { id: 2836, name: 'Piracema' },
      { id: 2837, name: 'Pirajuba' },
      { id: 2838, name: 'Piranga' },
      { id: 2839, name: 'Piranguçu' },
      { id: 2840, name: 'Piranguinho' },
      { id: 2841, name: 'Pirapetinga' },
      { id: 2842, name: 'Pirapora' },
      { id: 2843, name: 'Piraúba' },
      { id: 2844, name: 'Pitangui' },
      { id: 2845, name: 'Piumhi' },
      { id: 2846, name: 'Planura' },
      { id: 2847, name: 'Poço Fundo' },
      { id: 2848, name: 'Poços de Caldas' },
      { id: 2849, name: 'Pocrane' },
      { id: 2850, name: 'Pompéu' },
      { id: 2851, name: 'Ponte Nova' },
      { id: 2852, name: 'Ponto Chique' },
      { id: 2853, name: 'Ponto dos Volantes' },
      { id: 2854, name: 'Porteirinha' },
      { id: 2855, name: 'Porto Firme' },
      { id: 2856, name: 'Poté' },
      { id: 2857, name: 'Pouso Alegre' },
      { id: 2858, name: 'Pouso Alto' },
      { id: 2859, name: 'Prados' },
      { id: 2860, name: 'Prata' },
      { id: 2861, name: 'Pratápolis' },
      { id: 2862, name: 'Pratinha' },
      { id: 2863, name: 'Presidente Bernardes' },
      { id: 2864, name: 'Presidente Juscelino' },
      { id: 2865, name: 'Presidente Kubitschek' },
      { id: 2866, name: 'Presidente Olegário' },
      { id: 2868, name: 'Prudente de Morais' },
      { id: 2869, name: 'Quartel Geral' },
      { id: 2870, name: 'Queluzito' },
      { id: 2871, name: 'Raposos' },
      { id: 2872, name: 'Raul Soares' },
      { id: 2873, name: 'Recreio' },
      { id: 2874, name: 'Reduto' },
      { id: 2875, name: 'Resende Costa' },
      { id: 2876, name: 'Resplendor' },
      { id: 2877, name: 'Ressaquinha' },
      { id: 2878, name: 'Riachinho' },
      { id: 2879, name: 'Riacho dos Machados' },
      { id: 2880, name: 'Ribeirão das Neves' },
      { id: 2881, name: 'Ribeirão Vermelho' },
      { id: 2882, name: 'Rio Acima' },
      { id: 2883, name: 'Rio Casca' },
      { id: 2885, name: 'Rio do Prado' },
      { id: 2884, name: 'Rio Doce' },
      { id: 2886, name: 'Rio Espera' },
      { id: 2887, name: 'Rio Manso' },
      { id: 2888, name: 'Rio Novo' },
      { id: 2889, name: 'Rio Paranaíba' },
      { id: 2890, name: 'Rio Pardo de Minas' },
      { id: 2891, name: 'Rio Piracicaba' },
      { id: 2892, name: 'Rio Pomba' },
      { id: 2893, name: 'Rio Preto' },
      { id: 2894, name: 'Rio Vermelho' },
      { id: 2895, name: 'Ritápolis' },
      { id: 2896, name: 'Rochedo de Minas' },
      { id: 2897, name: 'Rodeiro' },
      { id: 2898, name: 'Romaria' },
      { id: 2899, name: 'Rosário da Limeira' },
      { id: 2900, name: 'Rubelita' },
      { id: 2901, name: 'Rubim' },
      { id: 2902, name: 'Sabará' },
      { id: 2903, name: 'Sabinópolis' },
      { id: 2904, name: 'Sacramento' },
      { id: 2905, name: 'Salinas' },
      { id: 2906, name: 'Salto da Divisa' },
      { id: 2907, name: 'Santa Bárbara' },
      { id: 2908, name: 'Santa Bárbara do Leste' },
      { id: 2909, name: 'Santa Bárbara do Monte Verde' },
      { id: 2910, name: 'Santa Bárbara do Tugúrio' },
      { id: 2911, name: 'Santa Cruz de Minas' },
      { id: 2912, name: 'Santa Cruz de Salinas' },
      { id: 2913, name: 'Santa Cruz do Escalvado' },
      { id: 2914, name: 'Santa Efigênia de Minas' },
      { id: 2915, name: 'Santa Fé de Minas' },
      { id: 2916, name: 'Santa Helena de Minas' },
      { id: 2917, name: 'Santa Juliana' },
      { id: 2918, name: 'Santa Luzia' },
      { id: 2919, name: 'Santa Margarida' },
      { id: 2920, name: 'Santa Maria de Itabira' },
      { id: 2921, name: 'Santa Maria do Salto' },
      { id: 2922, name: 'Santa Maria do Suaçuí' },
      { id: 2933, name: 'Santa Rita de Caldas' },
      { id: 2936, name: 'Santa Rita de Ibitipoca' },
      { id: 2934, name: 'Santa Rita de Jacutinga' },
      { id: 2935, name: 'Santa Rita de Minas' },
      { id: 2937, name: 'Santa Rita do Itueto' },
      { id: 2938, name: 'Santa Rita do Sapucaí' },
      { id: 2939, name: 'Santa Rosa da Serra' },
      { id: 2940, name: 'Santa Vitória' },
      { id: 2923, name: 'Santana da Vargem' },
      { id: 2924, name: 'Santana de Cataguases' },
      { id: 2925, name: 'Santana de Pirapama' },
      { id: 2926, name: 'Santana do Deserto' },
      { id: 2927, name: 'Santana do Garambéu' },
      { id: 2928, name: 'Santana do Jacaré' },
      { id: 2929, name: 'Santana do Manhuaçu' },
      { id: 2930, name: 'Santana do Paraíso' },
      { id: 2931, name: 'Santana do Riacho' },
      { id: 2932, name: 'Santana dos Montes' },
      { id: 2941, name: 'Santo Antônio do Amparo' },
      { id: 2942, name: 'Santo Antônio do Aventureiro' },
      { id: 2943, name: 'Santo Antônio do Grama' },
      { id: 2944, name: 'Santo Antônio do Itambé' },
      { id: 2945, name: 'Santo Antônio do Jacinto' },
      { id: 2946, name: 'Santo Antônio do Monte' },
      { id: 2947, name: 'Santo Antônio do Retiro' },
      { id: 2948, name: 'Santo Antônio do Rio Abaixo' },
      { id: 2949, name: 'Santo Hipólito' },
      { id: 2950, name: 'Santos Dumont' },
      { id: 2951, name: 'São Bento Abade' },
      { id: 2952, name: 'São Brás do Suaçuí' },
      { id: 2953, name: 'São Domingos das Dores' },
      { id: 2954, name: 'São Domingos do Prata' },
      { id: 2955, name: 'São Félix de Minas' },
      { id: 2956, name: 'São Francisco' },
      { id: 2957, name: 'São Francisco de Paula' },
      { id: 2958, name: 'São Francisco de Sales' },
      { id: 2959, name: 'São Francisco do Glória' },
      { id: 2960, name: 'São Geraldo' },
      { id: 2961, name: 'São Geraldo da Piedade' },
      { id: 2962, name: 'São Geraldo do Baixio' },
      { id: 2963, name: 'São Gonçalo do Abaeté' },
      { id: 2964, name: 'São Gonçalo do Pará' },
      { id: 2965, name: 'São Gonçalo do Rio Abaixo' },
      { id: 2527, name: 'São Gonçalo do Rio Preto' },
      { id: 2966, name: 'São Gonçalo do Sapucaí' },
      { id: 2967, name: 'São Gotardo' },
      { id: 2968, name: 'São João Batista do Glória' },
      { id: 2969, name: 'São João da Lagoa' },
      { id: 2970, name: 'São João da Mata' },
      { id: 2971, name: 'São João da Ponte' },
      { id: 2972, name: 'São João das Missões' },
      { id: 2973, name: 'São João del Rei' },
      { id: 2974, name: 'São João do Manhuaçu' },
      { id: 2975, name: 'São João do Manteninha' },
      { id: 2976, name: 'São João do Oriente' },
      { id: 2977, name: 'São João do Pacuí' },
      { id: 2978, name: 'São João do Paraíso' },
      { id: 2979, name: 'São João Evangelista' },
      { id: 2980, name: 'São João Nepomuceno' },
      { id: 2981, name: 'São Joaquim de Bicas' },
      { id: 2982, name: 'São José da Barra' },
      { id: 2983, name: 'São José da Lapa' },
      { id: 2984, name: 'São José da Safira' },
      { id: 2985, name: 'São José da Varginha' },
      { id: 2986, name: 'São José do Alegre' },
      { id: 2987, name: 'São José do Divino' },
      { id: 2988, name: 'São José do Goiabal' },
      { id: 2989, name: 'São José do Jacuri' },
      { id: 2990, name: 'São José do Mantimento' },
      { id: 2991, name: 'São Lourenço' },
      { id: 2992, name: 'São Miguel do Anta' },
      { id: 2993, name: 'São Pedro da União' },
      { id: 2995, name: 'São Pedro do Suaçuí' },
      { id: 2994, name: 'São Pedro dos Ferros' },
      { id: 2996, name: 'São Romão' },
      { id: 2997, name: 'São Roque de Minas' },
      { id: 2998, name: 'São Sebastião da Bela Vista' },
      { id: 2999, name: 'São Sebastião da Vargem Alegre' },
      { id: 3000, name: 'São Sebastião do Anta' },
      { id: 3001, name: 'São Sebastião do Maranhão' },
      { id: 3002, name: 'São Sebastião do Oeste' },
      { id: 3003, name: 'São Sebastião do Paraíso' },
      { id: 3004, name: 'São Sebastião do Rio Preto' },
      { id: 3005, name: 'São Sebastião do Rio Verde' },
      { id: 3008, name: 'São Thomé das Letras' },
      { id: 3006, name: 'São Tiago' },
      { id: 3007, name: 'São Tomás de Aquino' },
      { id: 3009, name: 'São Vicente de Minas' },
      { id: 3010, name: 'Sapucaí-Mirim' },
      { id: 3011, name: 'Sardoá' },
      { id: 3012, name: 'Sarzedo' },
      { id: 3014, name: 'Sem-Peixe' },
      { id: 3015, name: 'Senador Amaral' },
      { id: 3016, name: 'Senador Cortes' },
      { id: 3017, name: 'Senador Firmino' },
      { id: 3018, name: 'Senador José Bento' },
      { id: 3019, name: 'Senador Modestino Gonçalves' },
      { id: 3020, name: 'Senhora de Oliveira' },
      { id: 3021, name: 'Senhora do Porto' },
      { id: 3022, name: 'Senhora dos Remédios' },
      { id: 3023, name: 'Sericita' },
      { id: 3024, name: 'Seritinga' },
      { id: 3025, name: 'Serra Azul de Minas' },
      { id: 3026, name: 'Serra da Saudade' },
      { id: 3028, name: 'Serra do Salitre' },
      { id: 3027, name: 'Serra dos Aimorés' },
      { id: 3029, name: 'Serrania' },
      { id: 3030, name: 'Serranópolis de Minas' },
      { id: 3031, name: 'Serranos' },
      { id: 3032, name: 'Serro' },
      { id: 3033, name: 'Sete Lagoas' },
      { id: 3013, name: 'Setubinha' },
      { id: 3034, name: 'Silveirânia' },
      { id: 3035, name: 'Silvianópolis' },
      { id: 3036, name: 'Simão Pereira' },
      { id: 3037, name: 'Simonésia' },
      { id: 3038, name: 'Sobrália' },
      { id: 3039, name: 'Soledade de Minas' },
      { id: 3040, name: 'Tabuleiro' },
      { id: 3041, name: 'Taiobeiras' },
      { id: 3042, name: 'Taparuba' },
      { id: 3043, name: 'Tapira' },
      { id: 3044, name: 'Tapiraí' },
      { id: 3045, name: 'Taquaraçu de Minas' },
      { id: 3046, name: 'Tarumirim' },
      { id: 3047, name: 'Teixeiras' },
      { id: 3048, name: 'Teófilo Otoni' },
      { id: 3049, name: 'Timóteo' },
      { id: 3050, name: 'Tiradentes' },
      { id: 3051, name: 'Tiros' },
      { id: 3052, name: 'Tocantins' },
      { id: 3053, name: 'Tocos do Moji' },
      { id: 3054, name: 'Toledo' },
      { id: 3055, name: 'Tombos' },
      { id: 3056, name: 'Três Corações' },
      { id: 3057, name: 'Três Marias' },
      { id: 3058, name: 'Três Pontas' },
      { id: 3059, name: 'Tumiritinga' },
      { id: 3060, name: 'Tupaciguara' },
      { id: 3061, name: 'Turmalina' },
      { id: 3062, name: 'Turvolândia' },
      { id: 3063, name: 'Ubá' },
      { id: 3064, name: 'Ubaí' },
      { id: 3065, name: 'Ubaporanga' },
      { id: 3066, name: 'Uberaba' },
      { id: 3067, name: 'Uberlândia' },
      { id: 3068, name: 'Umburatiba' },
      { id: 3069, name: 'Unaí' },
      { id: 3070, name: 'União de Minas' },
      { id: 3071, name: 'Uruana de Minas' },
      { id: 3072, name: 'Urucânia' },
      { id: 3073, name: 'Urucuia' },
      { id: 3074, name: 'Vargem Alegre' },
      { id: 3075, name: 'Vargem Bonita' },
      { id: 3076, name: 'Vargem Grande do Rio Pardo' },
      { id: 3077, name: 'Varginha' },
      { id: 3078, name: 'Varjão de Minas' },
      { id: 3079, name: 'Várzea da Palma' },
      { id: 3080, name: 'Varzelândia' },
      { id: 3081, name: 'Vazante' },
      { id: 3082, name: 'Verdelândia' },
      { id: 3083, name: 'Veredinha' },
      { id: 3084, name: 'Veríssimo' },
      { id: 3085, name: 'Vermelho Novo' },
      { id: 3086, name: 'Vespasiano' },
      { id: 3087, name: 'Viçosa' },
      { id: 3088, name: 'Vieiras' },
      { id: 3090, name: 'Virgem da Lapa' },
      { id: 3091, name: 'Virgínia' },
      { id: 3092, name: 'Virginópolis' },
      { id: 3093, name: 'Virgolândia' },
      { id: 3094, name: 'Visconde do Rio Branco' },
      { id: 3095, name: 'Volta Grande' },
      { id: 3096, name: 'Wenceslau Braz' },
    ],
  },
  {
    id: 24,
    name: 'MS',
    fullname: 'Mato Grosso do Sul',
    cities: [
      { id: 5119, name: 'Campo Grande' },
      { id: 5100, name: 'Água Clara' },
      { id: 5101, name: 'Alcinópolis' },
      { id: 5102, name: 'Amambai' },
      { id: 5103, name: 'Anastácio' },
      { id: 5104, name: 'Anaurilândia' },
      { id: 5105, name: 'Angélica' },
      { id: 5106, name: 'Antônio João' },
      { id: 5107, name: 'Aparecida do Taboado' },
      { id: 5108, name: 'Aquidauana' },
      { id: 5109, name: 'Aral Moreira' },
      { id: 5110, name: 'Bandeirantes' },
      { id: 5111, name: 'Bataguassu' },
      { id: 5112, name: 'Batayporã' },
      { id: 5113, name: 'Bela Vista' },
      { id: 5114, name: 'Bodoquena' },
      { id: 5115, name: 'Bonito' },
      { id: 5116, name: 'Brasilândia' },
      { id: 5117, name: 'Caarapó' },
      { id: 5118, name: 'Camapuã' },
      { id: 5119, name: 'Campo Grande' },
      { id: 5120, name: 'Caracol' },
      { id: 5121, name: 'Cassilândia' },
      { id: 5122, name: 'Chapadão do Sul' },
      { id: 5123, name: 'Corguinho' },
      { id: 5124, name: 'Coronel Sapucaia' },
      { id: 5125, name: 'Corumbá' },
      { id: 5126, name: 'Costa Rica' },
      { id: 5127, name: 'Coxim' },
      { id: 5128, name: 'Deodápolis' },
      { id: 5129, name: 'Dois Irmãos do Buriti' },
      { id: 5130, name: 'Douradina' },
      { id: 5131, name: 'Dourados' },
      { id: 5132, name: 'Eldorado' },
      { id: 5133, name: 'Fátima do Sul' },
      { id: 5134, name: 'Figueirão' },
      { id: 5135, name: 'Glória de Dourados' },
      { id: 5136, name: 'Guia Lopes da Laguna' },
      { id: 5137, name: 'Iguatemi' },
      { id: 5138, name: 'Inocência' },
      { id: 5139, name: 'Itaporã' },
      { id: 5140, name: 'Itaquiraí' },
      { id: 5141, name: 'Ivinhema' },
      { id: 5142, name: 'Japorã' },
      { id: 5143, name: 'Jaraguari' },
      { id: 5144, name: 'Jardim' },
      { id: 5145, name: 'Jateí' },
      { id: 5146, name: 'Juti' },
      { id: 5147, name: 'Ladário' },
      { id: 5148, name: 'Laguna Carapã' },
      { id: 5149, name: 'Maracaju' },
      { id: 5150, name: 'Miranda' },
      { id: 5151, name: 'Mundo Novo' },
      { id: 5152, name: 'Naviraí' },
      { id: 5153, name: 'Nioaque' },
      { id: 5154, name: 'Nova Alvorada do Sul' },
      { id: 5155, name: 'Nova Andradina' },
      { id: 5156, name: 'Novo Horizonte do Sul' },
      { id: 5157, name: 'Paranaíba' },
      { id: 5158, name: 'Paranhos' },
      { id: 5159, name: 'Pedro Gomes' },
      { id: 5160, name: 'Ponta Porã' },
      { id: 5161, name: 'Porto Murtinho' },
      { id: 5162, name: 'Ribas do Rio Pardo' },
      { id: 5163, name: 'Rio Brilhante' },
      { id: 5164, name: 'Rio Negro' },
      { id: 5165, name: 'Rio Verde de Mato Grosso' },
      { id: 5166, name: 'Rochedo' },
      { id: 5167, name: 'Santa Rita do Pardo' },
      { id: 5168, name: 'São Gabriel do Oeste' },
      { id: 5170, name: 'Selvíria' },
      { id: 5169, name: 'Sete Quedas' },
      { id: 5171, name: 'Sidrolândia' },
      { id: 5172, name: 'Sonora' },
      { id: 5173, name: 'Tacuru' },
      { id: 5174, name: 'Taquarussu' },
      { id: 5175, name: 'Terenos' },
      { id: 5176, name: 'Três Lagoas' },
      { id: 5177, name: 'Vicentina' },
    ],
  },
  {
    id: 25,
    name: 'MT',
    fullname: 'Mato Grosso',
    cities: [
      { id: 5215, name: 'Cuiabá' },
      { id: 5178, name: 'Acorizal' },
      { id: 5179, name: 'Água Boa' },
      { id: 5180, name: 'Alta Floresta' },
      { id: 5181, name: 'Alto Araguaia' },
      { id: 5182, name: 'Alto Boa Vista' },
      { id: 5183, name: 'Alto Garças' },
      { id: 5184, name: 'Alto Paraguai' },
      { id: 5185, name: 'Alto Taquari' },
      { id: 5186, name: 'Apiacás' },
      { id: 5187, name: 'Araguaiana' },
      { id: 5188, name: 'Araguainha' },
      { id: 5189, name: 'Araputanga' },
      { id: 5190, name: 'Arenápolis' },
      { id: 5191, name: 'Aripuanã' },
      { id: 5192, name: 'Barão de Melgaço' },
      { id: 5193, name: 'Barra do Bugres' },
      { id: 5194, name: 'Barra do Garças' },
      { id: 5195, name: 'Bom Jesus do Araguaia' },
      { id: 5196, name: 'Brasnorte' },
      { id: 5197, name: 'Cáceres' },
      { id: 5198, name: 'Campinápolis' },
      { id: 5199, name: 'Campo Novo do Parecis' },
      { id: 5200, name: 'Campo Verde' },
      { id: 5201, name: 'Campos de Júlio' },
      { id: 5202, name: 'Canabrava do Norte' },
      { id: 5203, name: 'Canarana' },
      { id: 5204, name: 'Carlinda' },
      { id: 5205, name: 'Castanheira' },
      { id: 5206, name: 'Chapada dos Guimarães' },
      { id: 5207, name: 'Cláudia' },
      { id: 5208, name: 'Cocalinho' },
      { id: 5209, name: 'Colíder' },
      { id: 5210, name: 'Colniza' },
      { id: 5211, name: 'Comodoro' },
      { id: 5212, name: 'Confresa' },
      { id: 5213, name: "Conquista D'Oeste" },
      { id: 5214, name: 'Cotriguaçu' },
      { id: 5215, name: 'Cuiabá' },
      { id: 5216, name: 'Curvelândia' },
      { id: 5217, name: 'Denise' },
      { id: 5218, name: 'Diamantino' },
      { id: 5219, name: 'Dom Aquino' },
      { id: 5220, name: 'Feliz Natal' },
      { id: 5221, name: "Figueirópolis D'Oeste" },
      { id: 5222, name: 'Gaúcha do Norte' },
      { id: 5223, name: 'General Carneiro' },
      { id: 5224, name: "Glória D'Oeste" },
      { id: 5225, name: 'Guarantã do Norte' },
      { id: 5226, name: 'Guiratinga' },
      { id: 5227, name: 'Indiavaí' },
      { id: 5228, name: 'Ipiranga do Norte' },
      { id: 5229, name: 'Itanhangá' },
      { id: 5230, name: 'Itaúba' },
      { id: 5231, name: 'Itiquira' },
      { id: 5232, name: 'Jaciara' },
      { id: 5233, name: 'Jangada' },
      { id: 5234, name: 'Jauru' },
      { id: 5235, name: 'Juara' },
      { id: 5236, name: 'Juína' },
      { id: 5237, name: 'Juruena' },
      { id: 5238, name: 'Juscimeira' },
      { id: 5239, name: "Lambari D'Oeste" },
      { id: 5240, name: 'Lucas do Rio Verde' },
      { id: 5241, name: 'Luciara' },
      { id: 5243, name: 'Marcelândia' },
      { id: 5244, name: 'Matupá' },
      { id: 5245, name: "Mirassol d'Oeste" },
      { id: 5246, name: 'Nobres' },
      { id: 5247, name: 'Nortelândia' },
      { id: 5248, name: 'Nossa Senhora do Livramento' },
      { id: 5249, name: 'Nova Bandeirantes' },
      { id: 5253, name: 'Nova Brasilândia' },
      { id: 5254, name: 'Nova Canaã do Norte' },
      { id: 5315, name: 'Nova Guarita' },
      { id: 5251, name: 'Nova Lacerda' },
      { id: 5316, name: 'Nova Marilândia' },
      { id: 5317, name: 'Nova Maringá' },
      { id: 5318, name: 'Nova Monte Verde' },
      { id: 5255, name: 'Nova Mutum' },
      { id: 5250, name: 'Nova Nazaré' },
      { id: 5256, name: 'Nova Olímpia' },
      { id: 5252, name: 'Nova Santa Helena' },
      { id: 5257, name: 'Nova Ubiratã' },
      { id: 5258, name: 'Nova Xavantina' },
      { id: 5260, name: 'Novo Horizonte do Norte' },
      { id: 5259, name: 'Novo Mundo' },
      { id: 5264, name: 'Novo Santo Antônio' },
      { id: 5261, name: 'Novo São Joaquim' },
      { id: 5262, name: 'Paranaíta' },
      { id: 5263, name: 'Paranatinga' },
      { id: 5265, name: 'Pedra Preta' },
      { id: 5266, name: 'Peixoto de Azevedo' },
      { id: 5267, name: 'Planalto da Serra' },
      { id: 5268, name: 'Poconé' },
      { id: 5269, name: 'Pontal do Araguaia' },
      { id: 5270, name: 'Ponte Branca' },
      { id: 5271, name: 'Pontes e Lacerda' },
      { id: 5272, name: 'Porto Alegre do Norte' },
      { id: 5273, name: 'Porto dos Gaúchos' },
      { id: 5274, name: 'Porto Esperidião' },
      { id: 5275, name: 'Porto Estrela' },
      { id: 5276, name: 'Poxoréo' },
      { id: 5277, name: 'Primavera do Leste' },
      { id: 5278, name: 'Querência' },
      { id: 5280, name: 'Reserva do Cabaçal' },
      { id: 5281, name: 'Ribeirão Cascalheira' },
      { id: 5282, name: 'Ribeirãozinho' },
      { id: 5283, name: 'Rio Branco' },
      { id: 5290, name: 'Rondolândia' },
      { id: 5291, name: 'Rondonópolis' },
      { id: 5292, name: 'Rosário Oeste' },
      { id: 5294, name: 'Salto do Céu' },
      { id: 5284, name: 'Santa Carmem' },
      { id: 5293, name: 'Santa Cruz do Xingu' },
      { id: 5295, name: 'Santa Rita do Trivelato' },
      { id: 5296, name: 'Santa Terezinha' },
      { id: 5285, name: 'Santo Afonso' },
      { id: 5297, name: 'Santo Antônio do Leste' },
      { id: 5298, name: 'Santo Antônio do Leverger' },
      { id: 5299, name: 'São Félix do Araguaia' },
      { id: 5286, name: 'São José do Povo' },
      { id: 5287, name: 'São José do Rio Claro' },
      { id: 5288, name: 'São José do Xingu' },
      { id: 5279, name: 'São José dos Quatro Marcos' },
      { id: 5289, name: 'São Pedro da Cipa' },
      { id: 5300, name: 'Sapezal' },
      { id: 5301, name: 'Serra Nova Dourada' },
      { id: 5302, name: 'Sinop' },
      { id: 5303, name: 'Sorriso' },
      { id: 5304, name: 'Tabaporã' },
      { id: 5305, name: 'Tangará da Serra' },
      { id: 5306, name: 'Tapurah' },
      { id: 5307, name: 'Terra Nova do Norte' },
      { id: 5308, name: 'Tesouro' },
      { id: 5309, name: 'Torixoréu' },
      { id: 5310, name: 'União do Sul' },
      { id: 5311, name: 'Vale de São Domingos' },
      { id: 5312, name: 'Várzea Grande' },
      { id: 5313, name: 'Vera' },
      { id: 5242, name: 'Vila Bela da Santíssima Trindade' },
      { id: 5314, name: 'Vila Rica' },
    ],
  },
  {
    id: 5,
    name: 'PA',
    fullname: 'Pará',
    cities: [
      { id: 170, name: 'Belém' },
      { id: 152, name: 'Abaetetuba' },
      { id: 153, name: 'Abel Figueiredo' },
      { id: 154, name: 'Acará' },
      { id: 155, name: 'Afuá' },
      { id: 156, name: 'Água Azul do Norte' },
      { id: 157, name: 'Alenquer' },
      { id: 158, name: 'Almeirim' },
      { id: 159, name: 'Altamira' },
      { id: 160, name: 'Anajás' },
      { id: 161, name: 'Ananindeua' },
      { id: 162, name: 'Anapu' },
      { id: 163, name: 'Augusto Corrêa' },
      { id: 164, name: 'Aurora do Pará' },
      { id: 165, name: 'Aveiro' },
      { id: 166, name: 'Bagre' },
      { id: 167, name: 'Baião' },
      { id: 168, name: 'Bannach' },
      { id: 169, name: 'Barcarena' },
      { id: 170, name: 'Belém' },
      { id: 171, name: 'Belterra' },
      { id: 172, name: 'Benevides' },
      { id: 173, name: 'Bom Jesus do Tocantins' },
      { id: 174, name: 'Bonito' },
      { id: 175, name: 'Bragança' },
      { id: 176, name: 'Brasil Novo' },
      { id: 177, name: 'Brejo Grande do Araguaia' },
      { id: 178, name: 'Breu Branco' },
      { id: 179, name: 'Breves' },
      { id: 180, name: 'Bujaru' },
      { id: 182, name: 'Cachoeira do Arari' },
      { id: 181, name: 'Cachoeira do Piriá' },
      { id: 183, name: 'Cametá' },
      { id: 184, name: 'Canaã dos Carajás' },
      { id: 185, name: 'Capanema' },
      { id: 186, name: 'Capitão Poço' },
      { id: 187, name: 'Castanhal' },
      { id: 188, name: 'Chaves' },
      { id: 189, name: 'Colares' },
      { id: 190, name: 'Conceição do Araguaia' },
      { id: 191, name: 'Concórdia do Pará' },
      { id: 192, name: 'Cumaru do Norte' },
      { id: 193, name: 'Curionópolis' },
      { id: 194, name: 'Curralinho' },
      { id: 195, name: 'Curuá' },
      { id: 196, name: 'Curuçá' },
      { id: 197, name: 'Dom Eliseu' },
      { id: 198, name: 'Eldorado dos Carajás' },
      { id: 199, name: 'Faro' },
      { id: 200, name: 'Floresta do Araguaia' },
      { id: 201, name: 'Garrafão do Norte' },
      { id: 202, name: 'Goianésia do Pará' },
      { id: 203, name: 'Gurupá' },
      { id: 204, name: 'Igarapé-Açu' },
      { id: 205, name: 'Igarapé-Miri' },
      { id: 206, name: 'Inhangapi' },
      { id: 207, name: 'Ipixuna do Pará' },
      { id: 208, name: 'Irituia' },
      { id: 209, name: 'Itaituba' },
      { id: 210, name: 'Itupiranga' },
      { id: 211, name: 'Jacareacanga' },
      { id: 212, name: 'Jacundá' },
      { id: 213, name: 'Juruti' },
      { id: 214, name: 'Limoeiro do Ajuru' },
      { id: 215, name: 'Mãe do Rio' },
      { id: 216, name: 'Magalhães Barata' },
      { id: 217, name: 'Marabá' },
      { id: 218, name: 'Maracanã' },
      { id: 219, name: 'Marapanim' },
      { id: 220, name: 'Marituba' },
      { id: 221, name: 'Medicilândia' },
      { id: 222, name: 'Melgaço' },
      { id: 223, name: 'Mocajuba' },
      { id: 224, name: 'Moju' },
      { id: 225, name: 'Monte Alegre' },
      { id: 226, name: 'Muaná' },
      { id: 227, name: 'Nova Esperança do Piriá' },
      { id: 228, name: 'Nova Ipixuna' },
      { id: 229, name: 'Nova Timboteua' },
      { id: 230, name: 'Novo Progresso' },
      { id: 231, name: 'Novo Repartimento' },
      { id: 232, name: 'Óbidos' },
      { id: 233, name: 'Oeiras do Pará' },
      { id: 234, name: 'Oriximiná' },
      { id: 235, name: 'Ourém' },
      { id: 236, name: 'Ourilândia do Norte' },
      { id: 237, name: 'Pacajá' },
      { id: 238, name: 'Palestina do Pará' },
      { id: 239, name: 'Paragominas' },
      { id: 240, name: 'Parauapebas' },
      { id: 241, name: "Pau D'Arco" },
      { id: 242, name: 'Peixe-Boi' },
      { id: 243, name: 'Piçarra' },
      { id: 244, name: 'Placas' },
      { id: 245, name: 'Ponta de Pedras' },
      { id: 246, name: 'Portel' },
      { id: 247, name: 'Porto de Moz' },
      { id: 248, name: 'Prainha' },
      { id: 249, name: 'Primavera' },
      { id: 250, name: 'Quatipuru' },
      { id: 251, name: 'Redenção' },
      { id: 252, name: 'Rio Maria' },
      { id: 253, name: 'Rondon do Pará' },
      { id: 254, name: 'Rurópolis' },
      { id: 255, name: 'Salinópolis' },
      { id: 256, name: 'Salvaterra' },
      { id: 257, name: 'Santa Bárbara do Pará' },
      { id: 258, name: 'Santa Cruz do Arari' },
      { id: 259, name: 'Santa Isabel do Pará' },
      { id: 260, name: 'Santa Luzia do Pará' },
      { id: 261, name: 'Santa Maria das Barreiras' },
      { id: 262, name: 'Santa Maria do Pará' },
      { id: 263, name: 'Santana do Araguaia' },
      { id: 264, name: 'Santarém' },
      { id: 265, name: 'Santarém Novo' },
      { id: 266, name: 'Santo Antônio do Tauá' },
      { id: 267, name: 'São Caetano de Odivelas' },
      { id: 268, name: 'São Domingos do Araguaia' },
      { id: 269, name: 'São Domingos do Capim' },
      { id: 270, name: 'São Félix do Xingu' },
      { id: 271, name: 'São Francisco do Pará' },
      { id: 272, name: 'São Geraldo do Araguaia' },
      { id: 273, name: 'São João da Ponta' },
      { id: 274, name: 'São João de Pirabas' },
      { id: 275, name: 'São João do Araguaia' },
      { id: 276, name: 'São Miguel do Guamá' },
      { id: 277, name: 'São Sebastião da Boa Vista' },
      { id: 278, name: 'Sapucaia' },
      { id: 279, name: 'Senador José Porfírio' },
      { id: 280, name: 'Soure' },
      { id: 281, name: 'Tailândia' },
      { id: 282, name: 'Terra Alta' },
      { id: 283, name: 'Terra Santa' },
      { id: 284, name: 'Tomé-Açu' },
      { id: 285, name: 'Tracuateua' },
      { id: 286, name: 'Trairão' },
      { id: 287, name: 'Tucumã' },
      { id: 288, name: 'Tucuruí' },
      { id: 289, name: 'Ulianópolis' },
      { id: 290, name: 'Uruará' },
      { id: 291, name: 'Vigia' },
      { id: 292, name: 'Viseu' },
      { id: 293, name: 'Vitória do Xingu' },
      { id: 294, name: 'Xinguara' },
    ],
  },
  {
    id: 12,
    name: 'PB',
    fullname: 'Paraíba',
    cities: [
      { id: 1337, name: 'João Pessoa' },
      { id: 1242, name: 'Água Branca' },
      { id: 1243, name: 'Aguiar' },
      { id: 1244, name: 'Alagoa Grande' },
      { id: 1245, name: 'Alagoa Nova' },
      { id: 1246, name: 'Alagoinha' },
      { id: 1247, name: 'Alcantil' },
      { id: 1248, name: 'Algodão de Jandaíra' },
      { id: 1249, name: 'Alhandra' },
      { id: 1251, name: 'Amparo' },
      { id: 1252, name: 'Aparecida' },
      { id: 1253, name: 'Araçagi' },
      { id: 1254, name: 'Arara' },
      { id: 1255, name: 'Araruna' },
      { id: 1256, name: 'Areia' },
      { id: 1257, name: 'Areia de Baraúnas' },
      { id: 1258, name: 'Areial' },
      { id: 1259, name: 'Aroeiras' },
      { id: 1260, name: 'Assunção' },
      { id: 1261, name: 'Baía da Traição' },
      { id: 1262, name: 'Bananeiras' },
      { id: 1263, name: 'Baraúna' },
      { id: 1265, name: 'Barra de Santa Rosa' },
      { id: 1264, name: 'Barra de Santana' },
      { id: 1266, name: 'Barra de São Miguel' },
      { id: 1267, name: 'Bayeux' },
      { id: 1268, name: 'Belém' },
      { id: 1269, name: 'Belém do Brejo do Cruz' },
      { id: 1270, name: 'Bernardino Batista' },
      { id: 1271, name: 'Boa Ventura' },
      { id: 1272, name: 'Boa Vista' },
      { id: 1273, name: 'Bom Jesus' },
      { id: 1274, name: 'Bom Sucesso' },
      { id: 1275, name: 'Bonito de Santa Fé' },
      { id: 1276, name: 'Boqueirão' },
      { id: 1278, name: 'Borborema' },
      { id: 1279, name: 'Brejo do Cruz' },
      { id: 1280, name: 'Brejo dos Santos' },
      { id: 1281, name: 'Caaporã' },
      { id: 1282, name: 'Cabaceiras' },
      { id: 1283, name: 'Cabedelo' },
      { id: 1284, name: 'Cachoeira dos Índios' },
      { id: 1285, name: 'Cacimba de Areia' },
      { id: 1286, name: 'Cacimba de Dentro' },
      { id: 1287, name: 'Cacimbas' },
      { id: 1288, name: 'Caiçara' },
      { id: 1289, name: 'Cajazeiras' },
      { id: 1290, name: 'Cajazeirinhas' },
      { id: 1291, name: 'Caldas Brandão' },
      { id: 1292, name: 'Camalaú' },
      { id: 1293, name: 'Campina Grande' },
      { id: 1454, name: 'Campo de Santana' },
      { id: 1294, name: 'Capim' },
      { id: 1295, name: 'Caraúbas' },
      { id: 1296, name: 'Carrapateira' },
      { id: 1297, name: 'Casserengue' },
      { id: 1298, name: 'Catingueira' },
      { id: 1299, name: 'Catolé do Rocha' },
      { id: 1300, name: 'Caturité' },
      { id: 1301, name: 'Conceição' },
      { id: 1302, name: 'Condado' },
      { id: 1303, name: 'Conde' },
      { id: 1304, name: 'Congo' },
      { id: 1305, name: 'Coremas' },
      { id: 1306, name: 'Coxixola' },
      { id: 1307, name: 'Cruz do Espírito Santo' },
      { id: 1308, name: 'Cubati' },
      { id: 1309, name: 'Cuité' },
      { id: 1311, name: 'Cuité de Mamanguape' },
      { id: 1310, name: 'Cuitegi' },
      { id: 1312, name: 'Curral de Cima' },
      { id: 1313, name: 'Curral Velho' },
      { id: 1314, name: 'Damião' },
      { id: 1315, name: 'Desterro' },
      { id: 1317, name: 'Diamante' },
      { id: 1318, name: 'Dona Inês' },
      { id: 1319, name: 'Duas Estradas' },
      { id: 1320, name: 'Emas' },
      { id: 1321, name: 'Esperança' },
      { id: 1322, name: 'Fagundes' },
      { id: 1323, name: 'Frei Martinho' },
      { id: 1324, name: 'Gado Bravo' },
      { id: 1325, name: 'Guarabira' },
      { id: 1326, name: 'Gurinhém' },
      { id: 1327, name: 'Gurjão' },
      { id: 1328, name: 'Ibiara' },
      { id: 1277, name: 'Igaracy' },
      { id: 1329, name: 'Imaculada' },
      { id: 1330, name: 'Ingá' },
      { id: 1331, name: 'Itabaiana' },
      { id: 1332, name: 'Itaporanga' },
      { id: 1333, name: 'Itapororoca' },
      { id: 1334, name: 'Itatuba' },
      { id: 1335, name: 'Jacaraú' },
      { id: 1336, name: 'Jericó' },
      { id: 1337, name: 'João Pessoa' },
      { id: 1338, name: 'Juarez Távora' },
      { id: 1339, name: 'Juazeirinho' },
      { id: 1340, name: 'Junco do Seridó' },
      { id: 1341, name: 'Juripiranga' },
      { id: 1342, name: 'Juru' },
      { id: 1343, name: 'Lagoa' },
      { id: 1344, name: 'Lagoa de Dentro' },
      { id: 1345, name: 'Lagoa Seca' },
      { id: 1346, name: 'Lastro' },
      { id: 1347, name: 'Livramento' },
      { id: 1348, name: 'Logradouro' },
      { id: 1349, name: 'Lucena' },
      { id: 1350, name: "Mãe d'Água" },
      { id: 1351, name: 'Malta' },
      { id: 1352, name: 'Mamanguape' },
      { id: 1353, name: 'Manaíra' },
      { id: 1354, name: 'Marcação' },
      { id: 1355, name: 'Mari' },
      { id: 1356, name: 'Marizópolis' },
      { id: 1357, name: 'Massaranduba' },
      { id: 1358, name: 'Mataraca' },
      { id: 1359, name: 'Matinhas' },
      { id: 1360, name: 'Mato Grosso' },
      { id: 1361, name: 'Maturéia' },
      { id: 1362, name: 'Mogeiro' },
      { id: 1363, name: 'Montadas' },
      { id: 1364, name: 'Monte Horebe' },
      { id: 1365, name: 'Monteiro' },
      { id: 1366, name: 'Mulungu' },
      { id: 1367, name: 'Natuba' },
      { id: 1368, name: 'Nazarezinho' },
      { id: 1369, name: 'Nova Floresta' },
      { id: 1370, name: 'Nova Olinda' },
      { id: 1371, name: 'Nova Palmeira' },
      { id: 1372, name: "Olho d'Água" },
      { id: 1373, name: 'Olivedos' },
      { id: 1374, name: 'Ouro Velho' },
      { id: 1375, name: 'Parari' },
      { id: 1376, name: 'Passagem' },
      { id: 1377, name: 'Patos' },
      { id: 1378, name: 'Paulista' },
      { id: 1379, name: 'Pedra Branca' },
      { id: 1380, name: 'Pedra Lavrada' },
      { id: 1381, name: 'Pedras de Fogo' },
      { id: 1399, name: 'Pedro Régis' },
      { id: 1382, name: 'Piancó' },
      { id: 1383, name: 'Picuí' },
      { id: 1384, name: 'Pilar' },
      { id: 1385, name: 'Pilões' },
      { id: 1386, name: 'Pilõezinhos' },
      { id: 1387, name: 'Pirpirituba' },
      { id: 1388, name: 'Pitimbu' },
      { id: 1389, name: 'Pocinhos' },
      { id: 1390, name: 'Poço Dantas' },
      { id: 1391, name: 'Poço de José de Moura' },
      { id: 1392, name: 'Pombal' },
      { id: 1393, name: 'Prata' },
      { id: 1394, name: 'Princesa Isabel' },
      { id: 1395, name: 'Puxinanã' },
      { id: 1396, name: 'Queimadas' },
      { id: 1397, name: 'Quixabá' },
      { id: 1398, name: 'Remígio' },
      { id: 1400, name: 'Riachão' },
      { id: 1401, name: 'Riachão do Bacamarte' },
      { id: 1402, name: 'Riachão do Poço' },
      { id: 1403, name: 'Riacho de Santo Antônio' },
      { id: 1404, name: 'Riacho dos Cavalos' },
      { id: 1405, name: 'Rio Tinto' },
      { id: 1406, name: 'Salgadinho' },
      { id: 1407, name: 'Salgado de São Félix' },
      { id: 1408, name: 'Santa Cecília' },
      { id: 1409, name: 'Santa Cruz' },
      { id: 1410, name: 'Santa Helena' },
      { id: 1411, name: 'Santa Inês' },
      { id: 1412, name: 'Santa Luzia' },
      { id: 1416, name: 'Santa Rita' },
      { id: 1417, name: 'Santa Teresinha' },
      { id: 1413, name: 'Santana de Mangueira' },
      { id: 1414, name: 'Santana dos Garrotes' },
      { id: 1415, name: 'Santarém' },
      { id: 1418, name: 'Santo André' },
      { id: 1420, name: 'São Bentinho' },
      { id: 1419, name: 'São Bento' },
      { id: 1422, name: 'São Domingos' },
      { id: 1421, name: 'São Domingos do Cariri' },
      { id: 1423, name: 'São Francisco' },
      { id: 1424, name: 'São João do Cariri' },
      { id: 1250, name: 'São João do Rio do Peixe' },
      { id: 1425, name: 'São João do Tigre' },
      { id: 1426, name: 'São José da Lagoa Tapada' },
      { id: 1427, name: 'São José de Caiana' },
      { id: 1428, name: 'São José de Espinharas' },
      { id: 1430, name: 'São José de Piranhas' },
      { id: 1431, name: 'São José de Princesa' },
      { id: 1432, name: 'São José do Bonfim' },
      { id: 1433, name: 'São José do Brejo do Cruz' },
      { id: 1434, name: 'São José do Sabugi' },
      { id: 1435, name: 'São José dos Cordeiros' },
      { id: 1429, name: 'São José dos Ramos' },
      { id: 1436, name: 'São Mamede' },
      { id: 1437, name: 'São Miguel de Taipu' },
      { id: 1438, name: 'São Sebastião de Lagoa de Roça' },
      { id: 1439, name: 'São Sebastião do Umbuzeiro' },
      { id: 1440, name: 'Sapé' },
      { id: 1441, name: 'Seridó' },
      { id: 1442, name: 'Serra Branca' },
      { id: 1443, name: 'Serra da Raiz' },
      { id: 1444, name: 'Serra Grande' },
      { id: 1445, name: 'Serra Redonda' },
      { id: 1446, name: 'Serraria' },
      { id: 1447, name: 'Sertãozinho' },
      { id: 1448, name: 'Sobrado' },
      { id: 1449, name: 'Solânea' },
      { id: 1450, name: 'Soledade' },
      { id: 1451, name: 'Sossêgo' },
      { id: 1452, name: 'Sousa' },
      { id: 1453, name: 'Sumé' },
      { id: 1455, name: 'Taperoá' },
      { id: 1456, name: 'Tavares' },
      { id: 1457, name: 'Teixeira' },
      { id: 1458, name: 'Tenório' },
      { id: 1459, name: 'Triunfo' },
      { id: 1460, name: 'Uiraúna' },
      { id: 1461, name: 'Umbuzeiro' },
      { id: 1462, name: 'Várzea' },
      { id: 1463, name: 'Vieirópolis' },
      { id: 1316, name: 'Vista Serrana' },
      { id: 1464, name: 'Zabelê' },
    ],
  },
  {
    id: 13,
    name: 'PE',
    fullname: 'Pernambuco',
    cities: [
      { id: 1596, name: 'Recife' },
      { id: 1465, name: 'Abreu e Lima' },
      { id: 1466, name: 'Afogados da Ingazeira' },
      { id: 1467, name: 'Afrânio' },
      { id: 1468, name: 'Agrestina' },
      { id: 1469, name: 'Água Preta' },
      { id: 1470, name: 'Águas Belas' },
      { id: 1471, name: 'Alagoinha' },
      { id: 1472, name: 'Aliança' },
      { id: 1473, name: 'Altinho' },
      { id: 1474, name: 'Amaraji' },
      { id: 1475, name: 'Angelim' },
      { id: 1476, name: 'Araçoiaba' },
      { id: 1477, name: 'Araripina' },
      { id: 1478, name: 'Arcoverde' },
      { id: 1479, name: 'Barra de Guabiraba' },
      { id: 1480, name: 'Barreiros' },
      { id: 1481, name: 'Belém de Maria' },
      { id: 1482, name: 'Belém de São Francisco' },
      { id: 1483, name: 'Belo Jardim' },
      { id: 1484, name: 'Betânia' },
      { id: 1485, name: 'Bezerros' },
      { id: 1486, name: 'Bodocó' },
      { id: 1487, name: 'Bom Conselho' },
      { id: 1488, name: 'Bom Jardim' },
      { id: 1489, name: 'Bonito' },
      { id: 1490, name: 'Brejão' },
      { id: 1491, name: 'Brejinho' },
      { id: 1492, name: 'Brejo da Madre de Deus' },
      { id: 1493, name: 'Buenos Aires' },
      { id: 1494, name: 'Buíque' },
      { id: 1495, name: 'Cabo de Santo Agostinho' },
      { id: 1496, name: 'Cabrobó' },
      { id: 1497, name: 'Cachoeirinha' },
      { id: 1498, name: 'Caetés' },
      { id: 1499, name: 'Calçado' },
      { id: 1500, name: 'Calumbi' },
      { id: 1501, name: 'Camaragibe' },
      { id: 1502, name: 'Camocim de São Félix' },
      { id: 1503, name: 'Camutanga' },
      { id: 1504, name: 'Canhotinho' },
      { id: 1505, name: 'Capoeiras' },
      { id: 1506, name: 'Carnaíba' },
      { id: 1507, name: 'Carnaubeira da Penha' },
      { id: 1508, name: 'Carpina' },
      { id: 1509, name: 'Caruaru' },
      { id: 1510, name: 'Casinhas' },
      { id: 1511, name: 'Catende' },
      { id: 1512, name: 'Cedro' },
      { id: 1513, name: 'Chã de Alegria' },
      { id: 1514, name: 'Chã Grande' },
      { id: 1515, name: 'Condado' },
      { id: 1516, name: 'Correntes' },
      { id: 1517, name: 'Cortês' },
      { id: 1518, name: 'Cumaru' },
      { id: 1519, name: 'Cupira' },
      { id: 1520, name: 'Custódia' },
      { id: 1521, name: 'Dormentes' },
      { id: 1522, name: 'Escada' },
      { id: 1523, name: 'Exu' },
      { id: 1524, name: 'Feira Nova' },
      { id: 1525, name: 'Fernando de Noronha' },
      { id: 1526, name: 'Ferreiros' },
      { id: 1527, name: 'Flores' },
      { id: 1528, name: 'Floresta' },
      { id: 1529, name: 'Frei Miguelinho' },
      { id: 1530, name: 'Gameleira' },
      { id: 1531, name: 'Garanhuns' },
      { id: 1532, name: 'Glória do Goitá' },
      { id: 1533, name: 'Goiana' },
      { id: 1534, name: 'Granito' },
      { id: 1535, name: 'Gravatá' },
      { id: 1536, name: 'Iati' },
      { id: 1537, name: 'Ibimirim' },
      { id: 1538, name: 'Ibirajuba' },
      { id: 1539, name: 'Igarassu' },
      { id: 1540, name: 'Iguaraci' },
      { id: 1547, name: 'Ilha de Itamaracá' },
      { id: 1541, name: 'Inajá' },
      { id: 1542, name: 'Ingazeira' },
      { id: 1543, name: 'Ipojuca' },
      { id: 1544, name: 'Ipubi' },
      { id: 1545, name: 'Itacuruba' },
      { id: 1546, name: 'Itaíba' },
      { id: 1548, name: 'Itambé' },
      { id: 1549, name: 'Itapetim' },
      { id: 1550, name: 'Itapissuma' },
      { id: 1551, name: 'Itaquitinga' },
      { id: 1552, name: 'Jaboatão dos Guararapes' },
      { id: 1553, name: 'Jaqueira' },
      { id: 1554, name: 'Jataúba' },
      { id: 1555, name: 'Jatobá' },
      { id: 1556, name: 'João Alfredo' },
      { id: 1557, name: 'Joaquim Nabuco' },
      { id: 1558, name: 'Jucati' },
      { id: 1559, name: 'Jupi' },
      { id: 1560, name: 'Jurema' },
      { id: 1561, name: 'Lagoa do Carro' },
      { id: 1562, name: 'Lagoa do Itaenga' },
      { id: 1563, name: 'Lagoa do Ouro' },
      { id: 1564, name: 'Lagoa dos Gatos' },
      { id: 1565, name: 'Lagoa Grande' },
      { id: 1566, name: 'Lajedo' },
      { id: 1567, name: 'Limoeiro' },
      { id: 1568, name: 'Macaparana' },
      { id: 1569, name: 'Machados' },
      { id: 1570, name: 'Manari' },
      { id: 1571, name: 'Maraial' },
      { id: 1572, name: 'Mirandiba' },
      { id: 1626, name: 'Moreilândia' },
      { id: 1573, name: 'Moreno' },
      { id: 1574, name: 'Nazaré da Mata' },
      { id: 1575, name: 'Olinda' },
      { id: 1576, name: 'Orobó' },
      { id: 1577, name: 'Orocó' },
      { id: 1578, name: 'Ouricuri' },
      { id: 1579, name: 'Palmares' },
      { id: 1580, name: 'Palmeirina' },
      { id: 1581, name: 'Panelas' },
      { id: 1582, name: 'Paranatama' },
      { id: 1583, name: 'Parnamirim' },
      { id: 1584, name: 'Passira' },
      { id: 1585, name: 'Paudalho' },
      { id: 1586, name: 'Paulista' },
      { id: 1587, name: 'Pedra' },
      { id: 1588, name: 'Pesqueira' },
      { id: 1589, name: 'Petrolândia' },
      { id: 1590, name: 'Petrolina' },
      { id: 1591, name: 'Poção' },
      { id: 1592, name: 'Pombos' },
      { id: 1593, name: 'Primavera' },
      { id: 1594, name: 'Quipapá' },
      { id: 1595, name: 'Quixaba' },
      { id: 1596, name: 'Recife' },
      { id: 1597, name: 'Riacho das Almas' },
      { id: 1598, name: 'Ribeirão' },
      { id: 1599, name: 'Rio Formoso' },
      { id: 1600, name: 'Sairé' },
      { id: 1601, name: 'Salgadinho' },
      { id: 1602, name: 'Salgueiro' },
      { id: 1603, name: 'Saloá' },
      { id: 1604, name: 'Sanharó' },
      { id: 1605, name: 'Santa Cruz' },
      { id: 1606, name: 'Santa Cruz da Baixa Verde' },
      { id: 1607, name: 'Santa Cruz do Capibaribe' },
      { id: 1608, name: 'Santa Filomena' },
      { id: 1609, name: 'Santa Maria da Boa Vista' },
      { id: 1610, name: 'Santa Maria do Cambucá' },
      { id: 1611, name: 'Santa Terezinha' },
      { id: 1612, name: 'São Benedito do Sul' },
      { id: 1613, name: 'São Bento do Una' },
      { id: 1614, name: 'São Caitano' },
      { id: 1615, name: 'São João' },
      { id: 1616, name: 'São Joaquim do Monte' },
      { id: 1617, name: 'São José da Coroa Grande' },
      { id: 1618, name: 'São José do Belmonte' },
      { id: 1619, name: 'São José do Egito' },
      { id: 1620, name: 'São Lourenço da Mata' },
      { id: 1621, name: 'São Vicente Ferrer' },
      { id: 1622, name: 'Serra Talhada' },
      { id: 1623, name: 'Serrita' },
      { id: 1624, name: 'Sertânia' },
      { id: 1625, name: 'Sirinhaém' },
      { id: 1627, name: 'Solidão' },
      { id: 1628, name: 'Surubim' },
      { id: 1629, name: 'Tabira' },
      { id: 1630, name: 'Tacaimbó' },
      { id: 1631, name: 'Tacaratu' },
      { id: 1632, name: 'Tamandaré' },
      { id: 1633, name: 'Taquaritinga do Norte' },
      { id: 1634, name: 'Terezinha' },
      { id: 1635, name: 'Terra Nova' },
      { id: 1636, name: 'Timbaúba' },
      { id: 1637, name: 'Toritama' },
      { id: 1638, name: 'Tracunhaém' },
      { id: 1639, name: 'Trindade' },
      { id: 1640, name: 'Triunfo' },
      { id: 1641, name: 'Tupanatinga' },
      { id: 1642, name: 'Tuparetama' },
      { id: 1643, name: 'Venturosa' },
      { id: 1644, name: 'Verdejante' },
      { id: 1645, name: 'Vertente do Lério' },
      { id: 1646, name: 'Vertentes' },
      { id: 1647, name: 'Vicência' },
      { id: 1648, name: 'Vitória de Santo Antão' },
      { id: 1649, name: 'Xexéu' },
    ],
  },
  {
    id: 9,
    name: 'PI',
    fullname: 'Piauí',
    cities: [
      { id: 882, name: 'Teresina' },
      { id: 667, name: 'Acauã' },
      { id: 668, name: 'Agricolândia' },
      { id: 669, name: 'Água Branca' },
      { id: 670, name: 'Alagoinha do Piauí' },
      { id: 671, name: 'Alegrete do Piauí' },
      { id: 672, name: 'Alto Longá' },
      { id: 673, name: 'Altos' },
      { id: 674, name: 'Alvorada do Gurguéia' },
      { id: 675, name: 'Amarante' },
      { id: 676, name: 'Angical do Piauí' },
      { id: 677, name: 'Anísio de Abreu' },
      { id: 678, name: 'Antônio Almeida' },
      { id: 679, name: 'Aroazes' },
      { id: 680, name: 'Aroeiras do Itaim' },
      { id: 681, name: 'Arraial' },
      { id: 682, name: 'Assunção do Piauí' },
      { id: 683, name: 'Avelino Lopes' },
      { id: 684, name: 'Baixa Grande do Ribeiro' },
      { id: 685, name: "Barra D'Alcântara" },
      { id: 686, name: 'Barras' },
      { id: 687, name: 'Barreiras do Piauí' },
      { id: 688, name: 'Barro Duro' },
      { id: 689, name: 'Batalha' },
      { id: 690, name: 'Bela Vista do Piauí' },
      { id: 691, name: 'Belém do Piauí' },
      { id: 692, name: 'Beneditinos' },
      { id: 693, name: 'Bertolínia' },
      { id: 694, name: 'Betânia do Piauí' },
      { id: 695, name: 'Boa Hora' },
      { id: 696, name: 'Bocaina' },
      { id: 697, name: 'Bom Jesus' },
      { id: 698, name: 'Bom Princípio do Piauí' },
      { id: 699, name: 'Bonfim do Piauí' },
      { id: 700, name: 'Boqueirão do Piauí' },
      { id: 701, name: 'Brasileira' },
      { id: 702, name: 'Brejo do Piauí' },
      { id: 703, name: 'Buriti dos Lopes' },
      { id: 704, name: 'Buriti dos Montes' },
      { id: 705, name: 'Cabeceiras do Piauí' },
      { id: 706, name: 'Cajazeiras do Piauí' },
      { id: 707, name: 'Cajueiro da Praia' },
      { id: 708, name: 'Caldeirão Grande do Piauí' },
      { id: 709, name: 'Campinas do Piauí' },
      { id: 710, name: 'Campo Alegre do Fidalgo' },
      { id: 711, name: 'Campo Grande do Piauí' },
      { id: 712, name: 'Campo Largo do Piauí' },
      { id: 713, name: 'Campo Maior' },
      { id: 714, name: 'Canavieira' },
      { id: 715, name: 'Canto do Buriti' },
      { id: 716, name: 'Capitão de Campos' },
      { id: 717, name: 'Capitão Gervásio Oliveira' },
      { id: 718, name: 'Caracol' },
      { id: 719, name: 'Caraúbas do Piauí' },
      { id: 720, name: 'Caridade do Piauí' },
      { id: 721, name: 'Castelo do Piauí' },
      { id: 722, name: 'Caxingó' },
      { id: 723, name: 'Cocal' },
      { id: 724, name: 'Cocal de Telha' },
      { id: 725, name: 'Cocal dos Alves' },
      { id: 726, name: 'Coivaras' },
      { id: 727, name: 'Colônia do Gurguéia' },
      { id: 728, name: 'Colônia do Piauí' },
      { id: 729, name: 'Conceição do Canindé' },
      { id: 730, name: 'Coronel José Dias' },
      { id: 731, name: 'Corrente' },
      { id: 732, name: 'Cristalândia do Piauí' },
      { id: 733, name: 'Cristino Castro' },
      { id: 734, name: 'Curimatá' },
      { id: 735, name: 'Currais' },
      { id: 737, name: 'Curral Novo do Piauí' },
      { id: 736, name: 'Curralinhos' },
      { id: 738, name: 'Demerval Lobão' },
      { id: 739, name: 'Dirceu Arcoverde' },
      { id: 740, name: 'Dom Expedito Lopes' },
      { id: 742, name: 'Dom Inocêncio' },
      { id: 741, name: 'Domingos Mourão' },
      { id: 743, name: 'Elesbão Veloso' },
      { id: 744, name: 'Eliseu Martins' },
      { id: 745, name: 'Esperantina' },
      { id: 746, name: 'Fartura do Piauí' },
      { id: 747, name: 'Flores do Piauí' },
      { id: 748, name: 'Floresta do Piauí' },
      { id: 749, name: 'Floriano' },
      { id: 750, name: 'Francinópolis' },
      { id: 751, name: 'Francisco Ayres' },
      { id: 752, name: 'Francisco Macedo' },
      { id: 753, name: 'Francisco Santos' },
      { id: 754, name: 'Fronteiras' },
      { id: 755, name: 'Geminiano' },
      { id: 756, name: 'Gilbués' },
      { id: 757, name: 'Guadalupe' },
      { id: 758, name: 'Guaribas' },
      { id: 759, name: 'Hugo Napoleão' },
      { id: 760, name: 'Ilha Grande' },
      { id: 761, name: 'Inhuma' },
      { id: 762, name: 'Ipiranga do Piauí' },
      { id: 763, name: 'Isaías Coelho' },
      { id: 764, name: 'Itainópolis' },
      { id: 765, name: 'Itaueira' },
      { id: 766, name: 'Jacobina do Piauí' },
      { id: 767, name: 'Jaicós' },
      { id: 768, name: 'Jardim do Mulato' },
      { id: 769, name: 'Jatobá do Piauí' },
      { id: 770, name: 'Jerumenha' },
      { id: 771, name: 'João Costa' },
      { id: 772, name: 'Joaquim Pires' },
      { id: 773, name: 'Joca Marques' },
      { id: 774, name: 'José de Freitas' },
      { id: 775, name: 'Juazeiro do Piauí' },
      { id: 776, name: 'Júlio Borges' },
      { id: 777, name: 'Jurema' },
      { id: 779, name: 'Lagoa Alegre' },
      { id: 781, name: 'Lagoa de São Francisco' },
      { id: 780, name: 'Lagoa do Barro do Piauí' },
      { id: 782, name: 'Lagoa do Piauí' },
      { id: 783, name: 'Lagoa do Sítio' },
      { id: 778, name: 'Lagoinha do Piauí' },
      { id: 784, name: 'Landri Sales' },
      { id: 785, name: 'Luís Correia' },
      { id: 786, name: 'Luzilândia' },
      { id: 787, name: 'Madeiro' },
      { id: 788, name: 'Manoel Emídio' },
      { id: 789, name: 'Marcolândia' },
      { id: 790, name: 'Marcos Parente' },
      { id: 791, name: 'Massapê do Piauí' },
      { id: 792, name: 'Matias Olímpio' },
      { id: 793, name: 'Miguel Alves' },
      { id: 794, name: 'Miguel Leão' },
      { id: 795, name: 'Milton Brandão' },
      { id: 796, name: 'Monsenhor Gil' },
      { id: 797, name: 'Monsenhor Hipólito' },
      { id: 798, name: 'Monte Alegre do Piauí' },
      { id: 799, name: 'Morro Cabeça no Tempo' },
      { id: 800, name: 'Morro do Chapéu do Piauí' },
      { id: 801, name: 'Murici dos Portelas' },
      { id: 802, name: 'Nazaré do Piauí' },
      { id: 803, name: 'Nazária' },
      { id: 804, name: 'Nossa Senhora de Nazaré' },
      { id: 805, name: 'Nossa Senhora dos Remédios' },
      { id: 825, name: 'Nova Santa Rita' },
      { id: 806, name: 'Novo Oriente do Piauí' },
      { id: 807, name: 'Novo Santo Antônio' },
      { id: 808, name: 'Oeiras' },
      { id: 809, name: "Olho D'Água do Piauí" },
      { id: 810, name: 'Padre Marcos' },
      { id: 811, name: 'Paes Landim' },
      { id: 812, name: 'Pajeú do Piauí' },
      { id: 813, name: 'Palmeira do Piauí' },
      { id: 814, name: 'Palmeirais' },
      { id: 815, name: 'Paquetá' },
      { id: 816, name: 'Parnaguá' },
      { id: 817, name: 'Parnaíba' },
      { id: 818, name: 'Passagem Franca do Piauí' },
      { id: 819, name: 'Patos do Piauí' },
      { id: 820, name: "Pau D'Arco do Piauí" },
      { id: 821, name: 'Paulistana' },
      { id: 822, name: 'Pavussu' },
      { id: 823, name: 'Pedro II' },
      { id: 824, name: 'Pedro Laurentino' },
      { id: 826, name: 'Picos' },
      { id: 827, name: 'Pimenteiras' },
      { id: 828, name: 'Pio IX' },
      { id: 829, name: 'Piracuruca' },
      { id: 830, name: 'Piripiri' },
      { id: 831, name: 'Porto' },
      { id: 832, name: 'Porto Alegre do Piauí' },
      { id: 833, name: 'Prata do Piauí' },
      { id: 834, name: 'Queimada Nova' },
      { id: 835, name: 'Redenção do Gurguéia' },
      { id: 836, name: 'Regeneração' },
      { id: 837, name: 'Riacho Frio' },
      { id: 838, name: 'Ribeira do Piauí' },
      { id: 839, name: 'Ribeiro Gonçalves' },
      { id: 840, name: 'Rio Grande do Piauí' },
      { id: 841, name: 'Santa Cruz do Piauí' },
      { id: 842, name: 'Santa Cruz dos Milagres' },
      { id: 843, name: 'Santa Filomena' },
      { id: 844, name: 'Santa Luz' },
      { id: 846, name: 'Santa Rosa do Piauí' },
      { id: 845, name: 'Santana do Piauí' },
      { id: 847, name: 'Santo Antônio de Lisboa' },
      { id: 848, name: 'Santo Antônio dos Milagres' },
      { id: 849, name: 'Santo Inácio do Piauí' },
      { id: 850, name: 'São Braz do Piauí' },
      { id: 851, name: 'São Félix do Piauí' },
      { id: 852, name: 'São Francisco de Assis do Piauí' },
      { id: 853, name: 'São Francisco do Piauí' },
      { id: 854, name: 'São Gonçalo do Gurguéia' },
      { id: 855, name: 'São Gonçalo do Piauí' },
      { id: 856, name: 'São João da Canabrava' },
      { id: 857, name: 'São João da Fronteira' },
      { id: 858, name: 'São João da Serra' },
      { id: 859, name: 'São João da Varjota' },
      { id: 860, name: 'São João do Arraial' },
      { id: 861, name: 'São João do Piauí' },
      { id: 862, name: 'São José do Divino' },
      { id: 863, name: 'São José do Peixe' },
      { id: 864, name: 'São José do Piauí' },
      { id: 865, name: 'São Julião' },
      { id: 866, name: 'São Lourenço do Piauí' },
      { id: 867, name: 'São Luis do Piauí' },
      { id: 868, name: 'São Miguel da Baixa Grande' },
      { id: 869, name: 'São Miguel do Fidalgo' },
      { id: 870, name: 'São Miguel do Tapuio' },
      { id: 871, name: 'São Pedro do Piauí' },
      { id: 872, name: 'São Raimundo Nonato' },
      { id: 873, name: 'Sebastião Barros' },
      { id: 874, name: 'Sebastião Leal' },
      { id: 875, name: 'Sigefredo Pacheco' },
      { id: 876, name: 'Simões' },
      { id: 877, name: 'Simplício Mendes' },
      { id: 878, name: 'Socorro do Piauí' },
      { id: 879, name: 'Sussuapara' },
      { id: 880, name: 'Tamboril do Piauí' },
      { id: 881, name: 'Tanque do Piauí' },
      { id: 882, name: 'Teresina' },
      { id: 883, name: 'União' },
      { id: 884, name: 'Uruçuí' },
      { id: 885, name: 'Valença do Piauí' },
      { id: 886, name: 'Várzea Branca' },
      { id: 887, name: 'Várzea Grande' },
      { id: 888, name: 'Vera Mendes' },
      { id: 889, name: 'Vila Nova do Piauí' },
      { id: 890, name: 'Wall Ferraz' },
    ],
  },
  {
    id: 21,
    name: 'PR',
    fullname: 'Paraná',
    cities: [
      { id: 4005, name: 'Curitiba' },
      { id: 3912, name: 'Abatiá' },
      { id: 3913, name: 'Adrianópolis' },
      { id: 3914, name: 'Agudos do Sul' },
      { id: 3915, name: 'Almirante Tamandaré' },
      { id: 3916, name: 'Altamira do Paraná' },
      { id: 4306, name: 'Alto Paraíso' },
      { id: 3918, name: 'Alto Paraná' },
      { id: 3919, name: 'Alto Piquiri' },
      { id: 3917, name: 'Altônia' },
      { id: 3920, name: 'Alvorada do Sul' },
      { id: 3921, name: 'Amaporã' },
      { id: 3922, name: 'Ampére' },
      { id: 3923, name: 'Anahy' },
      { id: 3924, name: 'Andirá' },
      { id: 3925, name: 'Ângulo' },
      { id: 3926, name: 'Antonina' },
      { id: 3927, name: 'Antônio Olinto' },
      { id: 3928, name: 'Apucarana' },
      { id: 3929, name: 'Arapongas' },
      { id: 3930, name: 'Arapoti' },
      { id: 3931, name: 'Arapuã' },
      { id: 3932, name: 'Araruna' },
      { id: 3933, name: 'Araucária' },
      { id: 3934, name: 'Ariranha do Ivaí' },
      { id: 3935, name: 'Assaí' },
      { id: 3936, name: 'Assis Chateaubriand' },
      { id: 3937, name: 'Astorga' },
      { id: 3938, name: 'Atalaia' },
      { id: 3939, name: 'Balsa Nova' },
      { id: 3940, name: 'Bandeirantes' },
      { id: 3941, name: 'Barbosa Ferraz' },
      { id: 3943, name: 'Barra do Jacaré' },
      { id: 3942, name: 'Barracão' },
      { id: 3944, name: 'Bela Vista da Caroba' },
      { id: 3945, name: 'Bela Vista do Paraíso' },
      { id: 3946, name: 'Bituruna' },
      { id: 3947, name: 'Boa Esperança' },
      { id: 3948, name: 'Boa Esperança do Iguaçu' },
      { id: 3949, name: 'Boa Ventura de São Roque' },
      { id: 3950, name: 'Boa Vista da Aparecida' },
      { id: 3951, name: 'Bocaiúva do Sul' },
      { id: 3952, name: 'Bom Jesus do Sul' },
      { id: 3953, name: 'Bom Sucesso' },
      { id: 3954, name: 'Bom Sucesso do Sul' },
      { id: 3955, name: 'Borrazópolis' },
      { id: 3956, name: 'Braganey' },
      { id: 3957, name: 'Brasilândia do Sul' },
      { id: 3958, name: 'Cafeara' },
      { id: 3959, name: 'Cafelândia' },
      { id: 3960, name: 'Cafezal do Sul' },
      { id: 3961, name: 'Califórnia' },
      { id: 3962, name: 'Cambará' },
      { id: 3963, name: 'Cambé' },
      { id: 3964, name: 'Cambira' },
      { id: 3965, name: 'Campina da Lagoa' },
      { id: 3966, name: 'Campina do Simão' },
      { id: 3967, name: 'Campina Grande do Sul' },
      { id: 3968, name: 'Campo Bonito' },
      { id: 3969, name: 'Campo do Tenente' },
      { id: 3970, name: 'Campo Largo' },
      { id: 3971, name: 'Campo Magro' },
      { id: 3972, name: 'Campo Mourão' },
      { id: 3973, name: 'Cândido de Abreu' },
      { id: 3974, name: 'Candói' },
      { id: 3975, name: 'Cantagalo' },
      { id: 3976, name: 'Capanema' },
      { id: 3977, name: 'Capitão Leônidas Marques' },
      { id: 3978, name: 'Carambeí' },
      { id: 3979, name: 'Carlópolis' },
      { id: 3980, name: 'Cascavel' },
      { id: 3981, name: 'Castro' },
      { id: 3982, name: 'Catanduvas' },
      { id: 3983, name: 'Centenário do Sul' },
      { id: 3984, name: 'Cerro Azul' },
      { id: 3985, name: 'Céu Azul' },
      { id: 3986, name: 'Chopinzinho' },
      { id: 3987, name: 'Cianorte' },
      { id: 3988, name: 'Cidade Gaúcha' },
      { id: 3989, name: 'Clevelândia' },
      { id: 3990, name: 'Colombo' },
      { id: 3991, name: 'Colorado' },
      { id: 3992, name: 'Congonhinhas' },
      { id: 3993, name: 'Conselheiro Mairinck' },
      { id: 3994, name: 'Contenda' },
      { id: 3995, name: 'Corbélia' },
      { id: 3996, name: 'Cornélio Procópio' },
      { id: 3997, name: 'Coronel Domingos Soares' },
      { id: 3998, name: 'Coronel Vivida' },
      { id: 3999, name: 'Corumbataí do Sul' },
      { id: 4003, name: 'Cruz Machado' },
      { id: 4000, name: 'Cruzeiro do Iguaçu' },
      { id: 4001, name: 'Cruzeiro do Oeste' },
      { id: 4002, name: 'Cruzeiro do Sul' },
      { id: 4004, name: 'Cruzmaltina' },
      { id: 4005, name: 'Curitiba' },
      { id: 4006, name: 'Curiúva' },
      { id: 4009, name: "Diamante D'Oeste" },
      { id: 4007, name: 'Diamante do Norte' },
      { id: 4008, name: 'Diamante do Sul' },
      { id: 4010, name: 'Dois Vizinhos' },
      { id: 4011, name: 'Douradina' },
      { id: 4012, name: 'Doutor Camargo' },
      { id: 4307, name: 'Doutor Ulysses' },
      { id: 4013, name: 'Enéas Marques' },
      { id: 4014, name: 'Engenheiro Beltrão' },
      { id: 4016, name: 'Entre Rios do Oeste' },
      { id: 4015, name: 'Esperança Nova' },
      { id: 4017, name: 'Espigão Alto do Iguaçu' },
      { id: 4018, name: 'Farol' },
      { id: 4019, name: 'Faxinal' },
      { id: 4020, name: 'Fazenda Rio Grande' },
      { id: 4021, name: 'Fênix' },
      { id: 4022, name: 'Fernandes Pinheiro' },
      { id: 4023, name: 'Figueira' },
      { id: 4025, name: 'Flor da Serra do Sul' },
      { id: 4024, name: 'Floraí' },
      { id: 4026, name: 'Floresta' },
      { id: 4027, name: 'Florestópolis' },
      { id: 4028, name: 'Flórida' },
      { id: 4029, name: 'Formosa do Oeste' },
      { id: 4030, name: 'Foz do Iguaçu' },
      { id: 4033, name: 'Foz do Jordão' },
      { id: 4031, name: 'Francisco Alves' },
      { id: 4032, name: 'Francisco Beltrão' },
      { id: 4034, name: 'General Carneiro' },
      { id: 4035, name: 'Godoy Moreira' },
      { id: 4036, name: 'Goioerê' },
      { id: 4037, name: 'Goioxim' },
      { id: 4038, name: 'Grandes Rios' },
      { id: 4039, name: 'Guaíra' },
      { id: 4040, name: 'Guairaçá' },
      { id: 4041, name: 'Guamiranga' },
      { id: 4042, name: 'Guapirama' },
      { id: 4043, name: 'Guaporema' },
      { id: 4044, name: 'Guaraci' },
      { id: 4045, name: 'Guaraniaçu' },
      { id: 4046, name: 'Guarapuava' },
      { id: 4047, name: 'Guaraqueçaba' },
      { id: 4048, name: 'Guaratuba' },
      { id: 4049, name: 'Honório Serpa' },
      { id: 4050, name: 'Ibaiti' },
      { id: 4051, name: 'Ibema' },
      { id: 4052, name: 'Ibiporã' },
      { id: 4053, name: 'Icaraíma' },
      { id: 4054, name: 'Iguaraçu' },
      { id: 4055, name: 'Iguatu' },
      { id: 4056, name: 'Imbaú' },
      { id: 4057, name: 'Imbituva' },
      { id: 4058, name: 'Inácio Martins' },
      { id: 4059, name: 'Inajá' },
      { id: 4060, name: 'Indianópolis' },
      { id: 4061, name: 'Ipiranga' },
      { id: 4062, name: 'Iporã' },
      { id: 4063, name: 'Iracema do Oeste' },
      { id: 4064, name: 'Irati' },
      { id: 4065, name: 'Iretama' },
      { id: 4066, name: 'Itaguajé' },
      { id: 4067, name: 'Itaipulândia' },
      { id: 4068, name: 'Itambaracá' },
      { id: 4069, name: 'Itambé' },
      { id: 4070, name: "Itapejara d'Oeste" },
      { id: 4071, name: 'Itaperuçu' },
      { id: 4072, name: 'Itaúna do Sul' },
      { id: 4073, name: 'Ivaí' },
      { id: 4074, name: 'Ivaiporã' },
      { id: 4075, name: 'Ivaté' },
      { id: 4076, name: 'Ivatuba' },
      { id: 4077, name: 'Jaboti' },
      { id: 4078, name: 'Jacarezinho' },
      { id: 4079, name: 'Jaguapitã' },
      { id: 4080, name: 'Jaguariaíva' },
      { id: 4081, name: 'Jandaia do Sul' },
      { id: 4082, name: 'Janiópolis' },
      { id: 4083, name: 'Japira' },
      { id: 4084, name: 'Japurá' },
      { id: 4085, name: 'Jardim Alegre' },
      { id: 4086, name: 'Jardim Olinda' },
      { id: 4087, name: 'Jataizinho' },
      { id: 4088, name: 'Jesuítas' },
      { id: 4089, name: 'Joaquim Távora' },
      { id: 4090, name: 'Jundiaí do Sul' },
      { id: 4091, name: 'Juranda' },
      { id: 4092, name: 'Jussara' },
      { id: 4093, name: 'Kaloré' },
      { id: 4094, name: 'Lapa' },
      { id: 4095, name: 'Laranjal' },
      { id: 4096, name: 'Laranjeiras do Sul' },
      { id: 4097, name: 'Leópolis' },
      { id: 4098, name: 'Lidianópolis' },
      { id: 4099, name: 'Lindoeste' },
      { id: 4100, name: 'Loanda' },
      { id: 4101, name: 'Lobato' },
      { id: 4102, name: 'Londrina' },
      { id: 4103, name: 'Luiziana' },
      { id: 4104, name: 'Lunardelli' },
      { id: 4105, name: 'Lupionópolis' },
      { id: 4106, name: 'Mallet' },
      { id: 4107, name: 'Mamborê' },
      { id: 4108, name: 'Mandaguaçu' },
      { id: 4109, name: 'Mandaguari' },
      { id: 4110, name: 'Mandirituba' },
      { id: 4111, name: 'Manfrinópolis' },
      { id: 4112, name: 'Mangueirinha' },
      { id: 4113, name: 'Manoel Ribas' },
      { id: 4114, name: 'Marechal Cândido Rondon' },
      { id: 4115, name: 'Maria Helena' },
      { id: 4116, name: 'Marialva' },
      { id: 4117, name: 'Marilândia do Sul' },
      { id: 4118, name: 'Marilena' },
      { id: 4119, name: 'Mariluz' },
      { id: 4120, name: 'Maringá' },
      { id: 4121, name: 'Mariópolis' },
      { id: 4122, name: 'Maripá' },
      { id: 4123, name: 'Marmeleiro' },
      { id: 4124, name: 'Marquinho' },
      { id: 4125, name: 'Marumbi' },
      { id: 4126, name: 'Matelândia' },
      { id: 4127, name: 'Matinhos' },
      { id: 4128, name: 'Mato Rico' },
      { id: 4129, name: 'Mauá da Serra' },
      { id: 4130, name: 'Medianeira' },
      { id: 4131, name: 'Mercedes' },
      { id: 4132, name: 'Mirador' },
      { id: 4133, name: 'Miraselva' },
      { id: 4134, name: 'Missal' },
      { id: 4135, name: 'Moreira Sales' },
      { id: 4136, name: 'Morretes' },
      { id: 4137, name: 'Munhoz de Melo' },
      { id: 4138, name: 'Nossa Senhora das Graças' },
      { id: 4139, name: 'Nova Aliança do Ivaí' },
      { id: 4140, name: 'Nova América da Colina' },
      { id: 4141, name: 'Nova Aurora' },
      { id: 4142, name: 'Nova Cantu' },
      { id: 4143, name: 'Nova Esperança' },
      { id: 4144, name: 'Nova Esperança do Sudoeste' },
      { id: 4145, name: 'Nova Fátima' },
      { id: 4146, name: 'Nova Laranjeiras' },
      { id: 4147, name: 'Nova Londrina' },
      { id: 4148, name: 'Nova Olímpia' },
      { id: 4151, name: 'Nova Prata do Iguaçu' },
      { id: 4149, name: 'Nova Santa Bárbara' },
      { id: 4150, name: 'Nova Santa Rosa' },
      { id: 4152, name: 'Nova Tebas' },
      { id: 4153, name: 'Novo Itacolomi' },
      { id: 4154, name: 'Ortigueira' },
      { id: 4155, name: 'Ourizona' },
      { id: 4156, name: 'Ouro Verde do Oeste' },
      { id: 4157, name: 'Paiçandu' },
      { id: 4158, name: 'Palmas' },
      { id: 4159, name: 'Palmeira' },
      { id: 4160, name: 'Palmital' },
      { id: 4161, name: 'Palotina' },
      { id: 4162, name: 'Paraíso do Norte' },
      { id: 4163, name: 'Paranacity' },
      { id: 4164, name: 'Paranaguá' },
      { id: 4165, name: 'Paranapoema' },
      { id: 4166, name: 'Paranavaí' },
      { id: 4167, name: 'Pato Bragado' },
      { id: 4168, name: 'Pato Branco' },
      { id: 4169, name: 'Paula Freitas' },
      { id: 4170, name: 'Paulo Frontin' },
      { id: 4171, name: 'Peabiru' },
      { id: 4172, name: 'Perobal' },
      { id: 4173, name: 'Pérola' },
      { id: 4174, name: "Pérola d'Oeste" },
      { id: 4175, name: 'Piên' },
      { id: 4176, name: 'Pinhais' },
      { id: 4178, name: 'Pinhal de São Bento' },
      { id: 4177, name: 'Pinhalão' },
      { id: 4179, name: 'Pinhão' },
      { id: 4180, name: 'Piraí do Sul' },
      { id: 4181, name: 'Piraquara' },
      { id: 4182, name: 'Pitanga' },
      { id: 4183, name: 'Pitangueiras' },
      { id: 4184, name: 'Planaltina do Paraná' },
      { id: 4185, name: 'Planalto' },
      { id: 4186, name: 'Ponta Grossa' },
      { id: 4187, name: 'Pontal do Paraná' },
      { id: 4188, name: 'Porecatu' },
      { id: 4189, name: 'Porto Amazonas' },
      { id: 4190, name: 'Porto Barreiro' },
      { id: 4191, name: 'Porto Rico' },
      { id: 4192, name: 'Porto Vitória' },
      { id: 4193, name: 'Prado Ferreira' },
      { id: 4194, name: 'Pranchita' },
      { id: 4195, name: 'Presidente Castelo Branco' },
      { id: 4196, name: 'Primeiro de Maio' },
      { id: 4197, name: 'Prudentópolis' },
      { id: 4198, name: 'Quarto Centenário' },
      { id: 4199, name: 'Quatiguá' },
      { id: 4200, name: 'Quatro Barras' },
      { id: 4201, name: 'Quatro Pontes' },
      { id: 4202, name: 'Quedas do Iguaçu' },
      { id: 4203, name: 'Querência do Norte' },
      { id: 4204, name: 'Quinta do Sol' },
      { id: 4205, name: 'Quitandinha' },
      { id: 4206, name: 'Ramilândia' },
      { id: 4207, name: 'Rancho Alegre' },
      { id: 4208, name: "Rancho Alegre D'Oeste" },
      { id: 4209, name: 'Realeza' },
      { id: 4210, name: 'Rebouças' },
      { id: 4211, name: 'Renascença' },
      { id: 4212, name: 'Reserva' },
      { id: 4213, name: 'Reserva do Iguaçu' },
      { id: 4214, name: 'Ribeirão Claro' },
      { id: 4215, name: 'Ribeirão do Pinhal' },
      { id: 4216, name: 'Rio Azul' },
      { id: 4217, name: 'Rio Bom' },
      { id: 4218, name: 'Rio Bonito do Iguaçu' },
      { id: 4219, name: 'Rio Branco do Ivaí' },
      { id: 4220, name: 'Rio Branco do Sul' },
      { id: 4221, name: 'Rio Negro' },
      { id: 4222, name: 'Rolândia' },
      { id: 4223, name: 'Roncador' },
      { id: 4224, name: 'Rondon' },
      { id: 4225, name: 'Rosário do Ivaí' },
      { id: 4226, name: 'Sabáudia' },
      { id: 4227, name: 'Salgado Filho' },
      { id: 4228, name: 'Salto do Itararé' },
      { id: 4229, name: 'Salto do Lontra' },
      { id: 4230, name: 'Santa Amélia' },
      { id: 4231, name: 'Santa Cecília do Pavão' },
      { id: 4232, name: 'Santa Cruz de Monte Castelo' },
      { id: 4233, name: 'Santa Fé' },
      { id: 4234, name: 'Santa Helena' },
      { id: 4235, name: 'Santa Inês' },
      { id: 4236, name: 'Santa Isabel do Ivaí' },
      { id: 4237, name: 'Santa Izabel do Oeste' },
      { id: 4238, name: 'Santa Lúcia' },
      { id: 4239, name: 'Santa Maria do Oeste' },
      { id: 4240, name: 'Santa Mariana' },
      { id: 4241, name: 'Santa Mônica' },
      { id: 4243, name: 'Santa Tereza do Oeste' },
      { id: 4244, name: 'Santa Terezinha de Itaipu' },
      { id: 4242, name: 'Santana do Itararé' },
      { id: 4245, name: 'Santo Antônio da Platina' },
      { id: 4246, name: 'Santo Antônio do Caiuá' },
      { id: 4247, name: 'Santo Antônio do Paraíso' },
      { id: 4248, name: 'Santo Antônio do Sudoeste' },
      { id: 4249, name: 'Santo Inácio' },
      { id: 4250, name: 'São Carlos do Ivaí' },
      { id: 4251, name: 'São Jerônimo da Serra' },
      { id: 4252, name: 'São João' },
      { id: 4253, name: 'São João do Caiuá' },
      { id: 4254, name: 'São João do Ivaí' },
      { id: 4255, name: 'São João do Triunfo' },
      { id: 4256, name: "São Jorge d'Oeste" },
      { id: 4257, name: 'São Jorge do Ivaí' },
      { id: 4258, name: 'São Jorge do Patrocínio' },
      { id: 4259, name: 'São José da Boa Vista' },
      { id: 4260, name: 'São José das Palmeiras' },
      { id: 4261, name: 'São José dos Pinhais' },
      { id: 4262, name: 'São Manoel do Paraná' },
      { id: 4263, name: 'São Mateus do Sul' },
      { id: 4264, name: 'São Miguel do Iguaçu' },
      { id: 4265, name: 'São Pedro do Iguaçu' },
      { id: 4266, name: 'São Pedro do Ivaí' },
      { id: 4267, name: 'São Pedro do Paraná' },
      { id: 4268, name: 'São Sebastião da Amoreira' },
      { id: 4269, name: 'São Tomé' },
      { id: 4270, name: 'Sapopema' },
      { id: 4271, name: 'Sarandi' },
      { id: 4272, name: 'Saudade do Iguaçu' },
      { id: 4273, name: 'Sengés' },
      { id: 4274, name: 'Serranópolis do Iguaçu' },
      { id: 4275, name: 'Sertaneja' },
      { id: 4276, name: 'Sertanópolis' },
      { id: 4277, name: 'Siqueira Campos' },
      { id: 4278, name: 'Sulina' },
      { id: 4279, name: 'Tamarana' },
      { id: 4280, name: 'Tamboara' },
      { id: 4281, name: 'Tapejara' },
      { id: 4282, name: 'Tapira' },
      { id: 4283, name: 'Teixeira Soares' },
      { id: 4284, name: 'Telêmaco Borba' },
      { id: 4285, name: 'Terra Boa' },
      { id: 4286, name: 'Terra Rica' },
      { id: 4287, name: 'Terra Roxa' },
      { id: 4288, name: 'Tibagi' },
      { id: 4289, name: 'Tijucas do Sul' },
      { id: 4290, name: 'Toledo' },
      { id: 4291, name: 'Tomazina' },
      { id: 4292, name: 'Três Barras do Paraná' },
      { id: 4293, name: 'Tunas do Paraná' },
      { id: 4294, name: 'Tuneiras do Oeste' },
      { id: 4295, name: 'Tupãssi' },
      { id: 4296, name: 'Turvo' },
      { id: 4297, name: 'Ubiratã' },
      { id: 4298, name: 'Umuarama' },
      { id: 4299, name: 'União da Vitória' },
      { id: 4300, name: 'Uniflor' },
      { id: 4301, name: 'Uraí' },
      { id: 4303, name: 'Ventania' },
      { id: 4304, name: 'Vera Cruz do Oeste' },
      { id: 4305, name: 'Verê' },
      { id: 4308, name: 'Virmond' },
      { id: 4309, name: 'Vitorino' },
      { id: 4302, name: 'Wenceslau Braz' },
      { id: 4310, name: 'Xambrê' },
    ],
  },
  {
    id: 19,
    name: 'RJ',
    fullname: 'Rio de Janeiro',
    cities: [
      { id: 3242, name: 'Rio de Janeiro' },
      { id: 3175, name: 'Angra dos Reis' },
      { id: 3176, name: 'Aperibé' },
      { id: 3177, name: 'Araruama' },
      { id: 3178, name: 'Areal' },
      { id: 3179, name: 'Armação dos Búzios' },
      { id: 3180, name: 'Arraial do Cabo' },
      { id: 3181, name: 'Barra do Piraí' },
      { id: 3182, name: 'Barra Mansa' },
      { id: 3183, name: 'Belford Roxo' },
      { id: 3184, name: 'Bom Jardim' },
      { id: 3185, name: 'Bom Jesus do Itabapoana' },
      { id: 3186, name: 'Cabo Frio' },
      { id: 3187, name: 'Cachoeiras de Macacu' },
      { id: 3188, name: 'Cambuci' },
      { id: 3191, name: 'Campos dos Goytacazes' },
      { id: 3192, name: 'Cantagalo' },
      { id: 3189, name: 'Carapebus' },
      { id: 3193, name: 'Cardoso Moreira' },
      { id: 3194, name: 'Carmo' },
      { id: 3195, name: 'Casimiro de Abreu' },
      { id: 3190, name: 'Comendador Levy Gasparian' },
      { id: 3196, name: 'Conceição de Macabu' },
      { id: 3197, name: 'Cordeiro' },
      { id: 3198, name: 'Duas Barras' },
      { id: 3199, name: 'Duque de Caxias' },
      { id: 3200, name: 'Engenheiro Paulo de Frontin' },
      { id: 3201, name: 'Guapimirim' },
      { id: 3202, name: 'Iguaba Grande' },
      { id: 3203, name: 'Itaboraí' },
      { id: 3204, name: 'Itaguaí' },
      { id: 3205, name: 'Italva' },
      { id: 3206, name: 'Itaocara' },
      { id: 3207, name: 'Itaperuna' },
      { id: 3208, name: 'Itatiaia' },
      { id: 3209, name: 'Japeri' },
      { id: 3210, name: 'Laje do Muriaé' },
      { id: 3211, name: 'Macaé' },
      { id: 3212, name: 'Macuco' },
      { id: 3213, name: 'Magé' },
      { id: 3214, name: 'Mangaratiba' },
      { id: 3215, name: 'Maricá' },
      { id: 3216, name: 'Mendes' },
      { id: 3217, name: 'Mesquita' },
      { id: 3218, name: 'Miguel Pereira' },
      { id: 3219, name: 'Miracema' },
      { id: 3220, name: 'Natividade' },
      { id: 3221, name: 'Nilópolis' },
      { id: 3222, name: 'Niterói' },
      { id: 3223, name: 'Nova Friburgo' },
      { id: 3224, name: 'Nova Iguaçu' },
      { id: 3225, name: 'Paracambi' },
      { id: 3226, name: 'Paraíba do Sul' },
      { id: 3227, name: 'Paraty' },
      { id: 3228, name: 'Paty do Alferes' },
      { id: 3229, name: 'Petrópolis' },
      { id: 3230, name: 'Pinheiral' },
      { id: 3231, name: 'Piraí' },
      { id: 3232, name: 'Porciúncula' },
      { id: 3233, name: 'Porto Real' },
      { id: 3234, name: 'Quatis' },
      { id: 3235, name: 'Queimados' },
      { id: 3236, name: 'Quissamã' },
      { id: 3237, name: 'Resende' },
      { id: 3238, name: 'Rio Bonito' },
      { id: 3239, name: 'Rio Claro' },
      { id: 3240, name: 'Rio das Flores' },
      { id: 3241, name: 'Rio das Ostras' },
      { id: 3242, name: 'Rio de Janeiro' },
      { id: 3243, name: 'Santa Maria Madalena' },
      { id: 3244, name: 'Santo Antônio de Pádua' },
      { id: 3246, name: 'São Fidélis' },
      { id: 3245, name: 'São Francisco de Itabapoana' },
      { id: 3247, name: 'São Gonçalo' },
      { id: 3248, name: 'São João da Barra' },
      { id: 3249, name: 'São João de Meriti' },
      { id: 3250, name: 'São José de Ubá' },
      { id: 3251, name: 'São José do Vale do Rio Preto' },
      { id: 3252, name: 'São Pedro da Aldeia' },
      { id: 3253, name: 'São Sebastião do Alto' },
      { id: 3254, name: 'Sapucaia' },
      { id: 3255, name: 'Saquarema' },
      { id: 3256, name: 'Seropédica' },
      { id: 3257, name: 'Silva Jardim' },
      { id: 3258, name: 'Sumidouro' },
      { id: 3259, name: 'Tanguá' },
      { id: 3260, name: 'Teresópolis' },
      { id: 3261, name: 'Trajano de Moraes' },
      { id: 3262, name: 'Três Rios' },
      { id: 3263, name: 'Valença' },
      { id: 3264, name: 'Varre-Sai' },
      { id: 3265, name: 'Vassouras' },
      { id: 3266, name: 'Volta Redonda' },
    ],
  },
  {
    id: 11,
    name: 'RN',
    fullname: 'Rio Grande do Norte',
    cities: [
      { id: 1163, name: 'Natal' },
      { id: 1075, name: 'Acari' },
      { id: 1076, name: 'Açu' },
      { id: 1077, name: 'Afonso Bezerra' },
      { id: 1078, name: 'Água Nova' },
      { id: 1079, name: 'Alexandria' },
      { id: 1080, name: 'Almino Afonso' },
      { id: 1081, name: 'Alto do Rodrigues' },
      { id: 1082, name: 'Angicos' },
      { id: 1083, name: 'Antônio Martins' },
      { id: 1084, name: 'Apodi' },
      { id: 1085, name: 'Areia Branca' },
      { id: 1086, name: 'Arês' },
      { id: 1087, name: 'Augusto Severo' },
      { id: 1088, name: 'Baía Formosa' },
      { id: 1089, name: 'Baraúna' },
      { id: 1090, name: 'Barcelona' },
      { id: 1091, name: 'Bento Fernandes' },
      { id: 1092, name: 'Bodó' },
      { id: 1093, name: 'Bom Jesus' },
      { id: 1094, name: 'Brejinho' },
      { id: 1095, name: 'Caiçara do Norte' },
      { id: 1096, name: 'Caiçara do Rio do Vento' },
      { id: 1097, name: 'Caicó' },
      { id: 1098, name: 'Campo Redondo' },
      { id: 1099, name: 'Canguaretama' },
      { id: 1100, name: 'Caraúbas' },
      { id: 1101, name: 'Carnaúba dos Dantas' },
      { id: 1102, name: 'Carnaubais' },
      { id: 1103, name: 'Ceará-Mirim' },
      { id: 1104, name: 'Cerro Corá' },
      { id: 1105, name: 'Coronel Ezequiel' },
      { id: 1106, name: 'Coronel João Pessoa' },
      { id: 1107, name: 'Cruzeta' },
      { id: 1108, name: 'Currais Novos' },
      { id: 1109, name: 'Doutor Severiano' },
      { id: 1111, name: 'Encanto' },
      { id: 1112, name: 'Equador' },
      { id: 1113, name: 'Espírito Santo' },
      { id: 1114, name: 'Extremoz' },
      { id: 1115, name: 'Felipe Guerra' },
      { id: 1116, name: 'Fernando Pedroza' },
      { id: 1117, name: 'Florânia' },
      { id: 1118, name: 'Francisco Dantas' },
      { id: 1119, name: 'Frutuoso Gomes' },
      { id: 1120, name: 'Galinhos' },
      { id: 1121, name: 'Goianinha' },
      { id: 1122, name: 'Governador Dix-Sept Rosado' },
      { id: 1123, name: 'Grossos' },
      { id: 1124, name: 'Guamaré' },
      { id: 1125, name: 'Ielmo Marinho' },
      { id: 1126, name: 'Ipanguaçu' },
      { id: 1127, name: 'Ipueira' },
      { id: 1128, name: 'Itajá' },
      { id: 1129, name: 'Itaú' },
      { id: 1130, name: 'Jaçanã' },
      { id: 1131, name: 'Jandaíra' },
      { id: 1132, name: 'Janduís' },
      { id: 1133, name: 'Januário Cicco' },
      { id: 1134, name: 'Japi' },
      { id: 1135, name: 'Jardim de Angicos' },
      { id: 1136, name: 'Jardim de Piranhas' },
      { id: 1137, name: 'Jardim do Seridó' },
      { id: 1138, name: 'João Câmara' },
      { id: 1139, name: 'João Dias' },
      { id: 1140, name: 'José da Penha' },
      { id: 1141, name: 'Jucurutu' },
      { id: 1142, name: 'Jundiá' },
      { id: 1143, name: "Lagoa d'Anta" },
      { id: 1144, name: 'Lagoa de Pedras' },
      { id: 1145, name: 'Lagoa de Velhos' },
      { id: 1146, name: 'Lagoa Nova' },
      { id: 1147, name: 'Lagoa Salgada' },
      { id: 1148, name: 'Lajes' },
      { id: 1149, name: 'Lajes Pintadas' },
      { id: 1150, name: 'Lucrécia' },
      { id: 1151, name: 'Luís Gomes' },
      { id: 1152, name: 'Macaíba' },
      { id: 1153, name: 'Macau' },
      { id: 1154, name: 'Major Sales' },
      { id: 1155, name: 'Marcelino Vieira' },
      { id: 1156, name: 'Martins' },
      { id: 1157, name: 'Maxaranguape' },
      { id: 1158, name: 'Messias Targino' },
      { id: 1159, name: 'Montanhas' },
      { id: 1160, name: 'Monte Alegre' },
      { id: 1161, name: 'Monte das Gameleiras' },
      { id: 1162, name: 'Mossoró' },
      { id: 1163, name: 'Natal' },
      { id: 1164, name: 'Nísia Floresta' },
      { id: 1165, name: 'Nova Cruz' },
      { id: 1166, name: "Olho-d'Água do Borges" },
      { id: 1167, name: 'Ouro Branco' },
      { id: 1168, name: 'Paraná' },
      { id: 1169, name: 'Paraú' },
      { id: 1170, name: 'Parazinho' },
      { id: 1171, name: 'Parelhas' },
      { id: 1110, name: 'Parnamirim' },
      { id: 1173, name: 'Passa e Fica' },
      { id: 1174, name: 'Passagem' },
      { id: 1175, name: 'Patu' },
      { id: 1177, name: 'Pau dos Ferros' },
      { id: 1178, name: 'Pedra Grande' },
      { id: 1179, name: 'Pedra Preta' },
      { id: 1180, name: 'Pedro Avelino' },
      { id: 1181, name: 'Pedro Velho' },
      { id: 1182, name: 'Pendências' },
      { id: 1183, name: 'Pilões' },
      { id: 1184, name: 'Poço Branco' },
      { id: 1185, name: 'Portalegre' },
      { id: 1186, name: 'Porto do Mangue' },
      { id: 1187, name: 'Presidente Juscelino' },
      { id: 1188, name: 'Pureza' },
      { id: 1189, name: 'Rafael Fernandes' },
      { id: 1190, name: 'Rafael Godeiro' },
      { id: 1191, name: 'Riacho da Cruz' },
      { id: 1192, name: 'Riacho de Santana' },
      { id: 1193, name: 'Riachuelo' },
      { id: 1172, name: 'Rio do Fogo' },
      { id: 1194, name: 'Rodolfo Fernandes' },
      { id: 1196, name: 'Ruy Barbosa' },
      { id: 1197, name: 'Santa Cruz' },
      { id: 1176, name: 'Santa Maria' },
      { id: 1198, name: 'Santana do Matos' },
      { id: 1199, name: 'Santana do Seridó' },
      { id: 1200, name: 'Santo Antônio' },
      { id: 1201, name: 'São Bento do Norte' },
      { id: 1202, name: 'São Bento do Trairí' },
      { id: 1203, name: 'São Fernando' },
      { id: 1204, name: 'São Francisco do Oeste' },
      { id: 1205, name: 'São Gonçalo do Amarante' },
      { id: 1206, name: 'São João do Sabugi' },
      { id: 1207, name: 'São José de Mipibu' },
      { id: 1208, name: 'São José do Campestre' },
      { id: 1209, name: 'São José do Seridó' },
      { id: 1210, name: 'São Miguel' },
      { id: 1211, name: 'São Miguel do Gostoso' },
      { id: 1212, name: 'São Paulo do Potengi' },
      { id: 1213, name: 'São Pedro' },
      { id: 1214, name: 'São Rafael' },
      { id: 1215, name: 'São Tomé' },
      { id: 1216, name: 'São Vicente' },
      { id: 1217, name: 'Senador Elói de Souza' },
      { id: 1218, name: 'Senador Georgino Avelino' },
      { id: 1219, name: 'Serra de São Bento' },
      { id: 1220, name: 'Serra do Mel' },
      { id: 1221, name: 'Serra Negra do Norte' },
      { id: 1222, name: 'Serrinha' },
      { id: 1223, name: 'Serrinha dos Pintos' },
      { id: 1224, name: 'Severiano Melo' },
      { id: 1225, name: 'Sítio Novo' },
      { id: 1226, name: 'Taboleiro Grande' },
      { id: 1227, name: 'Taipu' },
      { id: 1228, name: 'Tangará' },
      { id: 1229, name: 'Tenente Ananias' },
      { id: 1230, name: 'Tenente Laurentino Cruz' },
      { id: 1195, name: 'Tibau' },
      { id: 1231, name: 'Tibau do Sul' },
      { id: 1232, name: 'Timbaúba dos Batistas' },
      { id: 1233, name: 'Touros' },
      { id: 1234, name: 'Triunfo Potiguar' },
      { id: 1235, name: 'Umarizal' },
      { id: 1236, name: 'Upanema' },
      { id: 1237, name: 'Várzea' },
      { id: 1238, name: 'Venha-Ver' },
      { id: 1239, name: 'Vera Cruz' },
      { id: 1240, name: 'Viçosa' },
      { id: 1241, name: 'Vila Flor' },
    ],
  },
  {
    id: 1,
    name: 'RO',
    fullname: 'Rondônia',
    cities: [
      { id: 17, name: 'Porto Velho' },
      { id: 1, name: "Alta Floresta D'Oeste" },
      { id: 26, name: 'Alto Alegre dos Parecis' },
      { id: 27, name: 'Alto Paraíso' },
      { id: 25, name: "Alvorada D'Oeste" },
      { id: 2, name: 'Ariquemes' },
      { id: 28, name: 'Buritis' },
      { id: 3, name: 'Cabixi' },
      { id: 30, name: 'Cacaulândia' },
      { id: 4, name: 'Cacoal' },
      { id: 31, name: 'Campo Novo de Rondônia' },
      { id: 32, name: 'Candeias do Jamari' },
      { id: 33, name: 'Castanheiras' },
      { id: 5, name: 'Cerejeiras' },
      { id: 34, name: 'Chupinguaia' },
      { id: 6, name: 'Colorado do Oeste' },
      { id: 7, name: 'Corumbiara' },
      { id: 8, name: 'Costa Marques' },
      { id: 35, name: 'Cujubim' },
      { id: 9, name: "Espigão D'Oeste" },
      { id: 36, name: 'Governador Jorge Teixeira' },
      { id: 10, name: 'Guajará-Mirim' },
      { id: 37, name: 'Itapuã do Oeste' },
      { id: 11, name: 'Jaru' },
      { id: 12, name: 'Ji-Paraná' },
      { id: 13, name: "Machadinho D'Oeste" },
      { id: 38, name: 'Ministro Andreazza' },
      { id: 39, name: 'Mirante da Serra' },
      { id: 40, name: 'Monte Negro' },
      { id: 14, name: "Nova Brasilândia D'Oeste" },
      { id: 24, name: 'Nova Mamoré' },
      { id: 41, name: 'Nova União' },
      { id: 29, name: 'Novo Horizonte do Oeste' },
      { id: 15, name: 'Ouro Preto do Oeste' },
      { id: 42, name: 'Parecis' },
      { id: 16, name: 'Pimenta Bueno' },
      { id: 43, name: 'Pimenteiras do Oeste' },
      { id: 17, name: 'Porto Velho' },
      { id: 18, name: 'Presidente Médici' },
      { id: 44, name: 'Primavera de Rondônia' },
      { id: 19, name: 'Rio Crespo' },
      { id: 20, name: 'Rolim de Moura' },
      { id: 21, name: "Santa Luzia D'Oeste" },
      { id: 45, name: "São Felipe D'Oeste" },
      { id: 46, name: 'São Francisco do Guaporé' },
      { id: 23, name: 'São Miguel do Guaporé' },
      { id: 47, name: 'Seringueiras' },
      { id: 48, name: 'Teixeirópolis' },
      { id: 49, name: 'Theobroma' },
      { id: 50, name: 'Urupá' },
      { id: 51, name: 'Vale do Anari' },
      { id: 52, name: 'Vale do Paraíso' },
      { id: 22, name: 'Vilhena' },
    ],
  },
  {
    id: 4,
    name: 'RR',
    fullname: 'Roraima',
    cities: [
      { id: 139, name: 'Boa Vista' },
      { id: 138, name: 'Alto Alegre' },
      { id: 137, name: 'Amajari' },
      { id: 139, name: 'Boa Vista' },
      { id: 140, name: 'Bonfim' },
      { id: 141, name: 'Cantá' },
      { id: 142, name: 'Caracaraí' },
      { id: 143, name: 'Caroebe' },
      { id: 144, name: 'Iracema' },
      { id: 145, name: 'Mucajaí' },
      { id: 146, name: 'Normandia' },
      { id: 147, name: 'Pacaraima' },
      { id: 148, name: 'Rorainópolis' },
      { id: 149, name: 'São João da Baliza' },
      { id: 150, name: 'São Luiz' },
      { id: 151, name: 'Uiramutã' },
    ],
  },
  {
    id: 23,
    name: 'RS',
    fullname: 'Rio Grande do Sul',
    cities: [
      { id: 4928, name: 'Porto Alegre' },
      { id: 4604, name: 'Aceguá' },
      { id: 4605, name: 'Água Santa' },
      { id: 4606, name: 'Agudo' },
      { id: 4607, name: 'Ajuricaba' },
      { id: 4608, name: 'Alecrim' },
      { id: 4609, name: 'Alegrete' },
      { id: 4610, name: 'Alegria' },
      { id: 4611, name: 'Almirante Tamandaré do Sul' },
      { id: 4612, name: 'Alpestre' },
      { id: 4613, name: 'Alto Alegre' },
      { id: 4614, name: 'Alto Feliz' },
      { id: 4615, name: 'Alvorada' },
      { id: 4616, name: 'Amaral Ferrador' },
      { id: 4617, name: 'Ametista do Sul' },
      { id: 4618, name: 'André da Rocha' },
      { id: 4619, name: 'Anta Gorda' },
      { id: 4620, name: 'Antônio Prado' },
      { id: 4621, name: 'Arambaré' },
      { id: 4622, name: 'Araricá' },
      { id: 4623, name: 'Aratiba' },
      { id: 4624, name: 'Arroio do Meio' },
      { id: 4626, name: 'Arroio do Padre' },
      { id: 4625, name: 'Arroio do Sal' },
      { id: 4628, name: 'Arroio do Tigre' },
      { id: 4627, name: 'Arroio dos Ratos' },
      { id: 4629, name: 'Arroio Grande' },
      { id: 4630, name: 'Arvorezinha' },
      { id: 4631, name: 'Augusto Pestana' },
      { id: 4632, name: 'Áurea' },
      { id: 4633, name: 'Bagé' },
      { id: 4634, name: 'Balneário Pinhal' },
      { id: 4635, name: 'Barão' },
      { id: 4636, name: 'Barão de Cotegipe' },
      { id: 4637, name: 'Barão do Triunfo' },
      { id: 4639, name: 'Barra do Guarita' },
      { id: 4640, name: 'Barra do Quaraí' },
      { id: 4641, name: 'Barra do Ribeiro' },
      { id: 4642, name: 'Barra do Rio Azul' },
      { id: 4643, name: 'Barra Funda' },
      { id: 4638, name: 'Barracão' },
      { id: 4644, name: 'Barros Cassal' },
      { id: 4645, name: 'Benjamin Constant do Sul' },
      { id: 4646, name: 'Bento Gonçalves' },
      { id: 4647, name: 'Boa Vista das Missões' },
      { id: 4648, name: 'Boa Vista do Buricá' },
      { id: 4649, name: 'Boa Vista do Cadeado' },
      { id: 4650, name: 'Boa Vista do Incra' },
      { id: 4651, name: 'Boa Vista do Sul' },
      { id: 4652, name: 'Bom Jesus' },
      { id: 4653, name: 'Bom Princípio' },
      { id: 4654, name: 'Bom Progresso' },
      { id: 4655, name: 'Bom Retiro do Sul' },
      { id: 4656, name: 'Boqueirão do Leão' },
      { id: 4657, name: 'Bossoroca' },
      { id: 4658, name: 'Bozano' },
      { id: 4659, name: 'Braga' },
      { id: 4660, name: 'Brochier' },
      { id: 4661, name: 'Butiá' },
      { id: 4662, name: 'Caçapava do Sul' },
      { id: 4663, name: 'Cacequi' },
      { id: 4664, name: 'Cachoeira do Sul' },
      { id: 4665, name: 'Cachoeirinha' },
      { id: 4666, name: 'Cacique Doble' },
      { id: 4667, name: 'Caibaté' },
      { id: 4668, name: 'Caiçara' },
      { id: 4669, name: 'Camaquã' },
      { id: 4670, name: 'Camargo' },
      { id: 4671, name: 'Cambará do Sul' },
      { id: 4672, name: 'Campestre da Serra' },
      { id: 4673, name: 'Campina das Missões' },
      { id: 4674, name: 'Campinas do Sul' },
      { id: 4675, name: 'Campo Bom' },
      { id: 4676, name: 'Campo Novo' },
      { id: 4677, name: 'Campos Borges' },
      { id: 4678, name: 'Candelária' },
      { id: 4679, name: 'Cândido Godói' },
      { id: 4680, name: 'Candiota' },
      { id: 4681, name: 'Canela' },
      { id: 4682, name: 'Canguçu' },
      { id: 4683, name: 'Canoas' },
      { id: 4684, name: 'Canudos do Vale' },
      { id: 4685, name: 'Capão Bonito do Sul' },
      { id: 4686, name: 'Capão da Canoa' },
      { id: 4687, name: 'Capão do Cipó' },
      { id: 4688, name: 'Capão do Leão' },
      { id: 4690, name: 'Capela de Santana' },
      { id: 4691, name: 'Capitão' },
      { id: 4689, name: 'Capivari do Sul' },
      { id: 4693, name: 'Caraá' },
      { id: 4692, name: 'Carazinho' },
      { id: 4694, name: 'Carlos Barbosa' },
      { id: 4695, name: 'Carlos Gomes' },
      { id: 4696, name: 'Casca' },
      { id: 4697, name: 'Caseiros' },
      { id: 4698, name: 'Catuípe' },
      { id: 4699, name: 'Caxias do Sul' },
      { id: 4700, name: 'Centenário' },
      { id: 4701, name: 'Cerrito' },
      { id: 4702, name: 'Cerro Branco' },
      { id: 4703, name: 'Cerro Grande' },
      { id: 4704, name: 'Cerro Grande do Sul' },
      { id: 4705, name: 'Cerro Largo' },
      { id: 4706, name: 'Chapada' },
      { id: 4707, name: 'Charqueadas' },
      { id: 4708, name: 'Charrua' },
      { id: 4709, name: 'Chiapetta' },
      { id: 4710, name: 'Chuí' },
      { id: 4711, name: 'Chuvisca' },
      { id: 4712, name: 'Cidreira' },
      { id: 4713, name: 'Ciríaco' },
      { id: 4714, name: 'Colinas' },
      { id: 4715, name: 'Colorado' },
      { id: 4716, name: 'Condor' },
      { id: 4717, name: 'Constantina' },
      { id: 4718, name: 'Coqueiro Baixo' },
      { id: 4719, name: 'Coqueiros do Sul' },
      { id: 4720, name: 'Coronel Barros' },
      { id: 4721, name: 'Coronel Bicaco' },
      { id: 4722, name: 'Coronel Pilar' },
      { id: 4723, name: 'Cotiporã' },
      { id: 4724, name: 'Coxilha' },
      { id: 4725, name: 'Crissiumal' },
      { id: 4726, name: 'Cristal' },
      { id: 4727, name: 'Cristal do Sul' },
      { id: 4728, name: 'Cruz Alta' },
      { id: 4729, name: 'Cruzaltense' },
      { id: 4730, name: 'Cruzeiro do Sul' },
      { id: 4731, name: 'David Canabarro' },
      { id: 4732, name: 'Derrubadas' },
      { id: 4733, name: 'Dezesseis de Novembro' },
      { id: 4734, name: 'Dilermando de Aguiar' },
      { id: 4735, name: 'Dois Irmãos' },
      { id: 4736, name: 'Dois Irmãos das Missões' },
      { id: 4737, name: 'Dois Lajeados' },
      { id: 4738, name: 'Dom Feliciano' },
      { id: 4740, name: 'Dom Pedrito' },
      { id: 4739, name: 'Dom Pedro de Alcântara' },
      { id: 4741, name: 'Dona Francisca' },
      { id: 4742, name: 'Doutor Maurício Cardoso' },
      { id: 4743, name: 'Doutor Ricardo' },
      { id: 4744, name: 'Eldorado do Sul' },
      { id: 4745, name: 'Encantado' },
      { id: 4746, name: 'Encruzilhada do Sul' },
      { id: 4747, name: 'Engenho Velho' },
      { id: 4749, name: 'Entre Rios do Sul' },
      { id: 4748, name: 'Entre-Ijuís' },
      { id: 4750, name: 'Erebango' },
      { id: 4751, name: 'Erechim' },
      { id: 4752, name: 'Ernestina' },
      { id: 4754, name: 'Erval Grande' },
      { id: 4755, name: 'Erval Seco' },
      { id: 4756, name: 'Esmeralda' },
      { id: 4757, name: 'Esperança do Sul' },
      { id: 4758, name: 'Espumoso' },
      { id: 4759, name: 'Estação' },
      { id: 4760, name: 'Estância Velha' },
      { id: 4761, name: 'Esteio' },
      { id: 4762, name: 'Estrela' },
      { id: 4763, name: 'Estrela Velha' },
      { id: 4764, name: 'Eugênio de Castro' },
      { id: 4765, name: 'Fagundes Varela' },
      { id: 4766, name: 'Farroupilha' },
      { id: 4767, name: 'Faxinal do Soturno' },
      { id: 4768, name: 'Faxinalzinho' },
      { id: 4769, name: 'Fazenda Vilanova' },
      { id: 4770, name: 'Feliz' },
      { id: 4771, name: 'Flores da Cunha' },
      { id: 4772, name: 'Floriano Peixoto' },
      { id: 4773, name: 'Fontoura Xavier' },
      { id: 4774, name: 'Formigueiro' },
      { id: 4775, name: 'Forquetinha' },
      { id: 4776, name: 'Fortaleza dos Valos' },
      { id: 4777, name: 'Frederico Westphalen' },
      { id: 4778, name: 'Garibaldi' },
      { id: 4779, name: 'Garruchos' },
      { id: 4780, name: 'Gaurama' },
      { id: 4781, name: 'General Câmara' },
      { id: 4782, name: 'Gentil' },
      { id: 4783, name: 'Getúlio Vargas' },
      { id: 4784, name: 'Giruá' },
      { id: 4785, name: 'Glorinha' },
      { id: 4786, name: 'Gramado' },
      { id: 4787, name: 'Gramado dos Loureiros' },
      { id: 4788, name: 'Gramado Xavier' },
      { id: 4789, name: 'Gravataí' },
      { id: 4790, name: 'Guabiju' },
      { id: 4791, name: 'Guaíba' },
      { id: 4792, name: 'Guaporé' },
      { id: 4793, name: 'Guarani das Missões' },
      { id: 4794, name: 'Harmonia' },
      { id: 4753, name: 'Herval' },
      { id: 4795, name: 'Herveiras' },
      { id: 4796, name: 'Horizontina' },
      { id: 4797, name: 'Hulha Negra' },
      { id: 4798, name: 'Humaitá' },
      { id: 4799, name: 'Ibarama' },
      { id: 4800, name: 'Ibiaçá' },
      { id: 4801, name: 'Ibiraiaras' },
      { id: 4802, name: 'Ibirapuitã' },
      { id: 4803, name: 'Ibirubá' },
      { id: 4804, name: 'Igrejinha' },
      { id: 4805, name: 'Ijuí' },
      { id: 4806, name: 'Ilópolis' },
      { id: 4807, name: 'Imbé' },
      { id: 4808, name: 'Imigrante' },
      { id: 4809, name: 'Independência' },
      { id: 4810, name: 'Inhacorá' },
      { id: 4811, name: 'Ipê' },
      { id: 4812, name: 'Ipiranga do Sul' },
      { id: 4813, name: 'Iraí' },
      { id: 4814, name: 'Itaara' },
      { id: 4815, name: 'Itacurubi' },
      { id: 4816, name: 'Itapuca' },
      { id: 4817, name: 'Itaqui' },
      { id: 4818, name: 'Itati' },
      { id: 4819, name: 'Itatiba do Sul' },
      { id: 4820, name: 'Ivorá' },
      { id: 4821, name: 'Ivoti' },
      { id: 4822, name: 'Jaboticaba' },
      { id: 4823, name: 'Jacuizinho' },
      { id: 4824, name: 'Jacutinga' },
      { id: 4825, name: 'Jaguarão' },
      { id: 4826, name: 'Jaguari' },
      { id: 4827, name: 'Jaquirana' },
      { id: 4828, name: 'Jari' },
      { id: 4829, name: 'Jóia' },
      { id: 4830, name: 'Júlio de Castilhos' },
      { id: 4831, name: 'Lagoa Bonita do Sul' },
      { id: 4833, name: 'Lagoa dos Três Cantos' },
      { id: 4834, name: 'Lagoa Vermelha' },
      { id: 4832, name: 'Lagoão' },
      { id: 4835, name: 'Lajeado' },
      { id: 4836, name: 'Lajeado do Bugre' },
      { id: 4837, name: 'Lavras do Sul' },
      { id: 4838, name: 'Liberato Salzano' },
      { id: 4839, name: 'Lindolfo Collor' },
      { id: 4840, name: 'Linha Nova' },
      { id: 4842, name: 'Maçambará' },
      { id: 4841, name: 'Machadinho' },
      { id: 4843, name: 'Mampituba' },
      { id: 4844, name: 'Manoel Viana' },
      { id: 4845, name: 'Maquiné' },
      { id: 4846, name: 'Maratá' },
      { id: 4847, name: 'Marau' },
      { id: 4848, name: 'Marcelino Ramos' },
      { id: 4849, name: 'Mariana Pimentel' },
      { id: 4850, name: 'Mariano Moro' },
      { id: 4851, name: 'Marques de Souza' },
      { id: 4852, name: 'Mata' },
      { id: 4853, name: 'Mato Castelhano' },
      { id: 4854, name: 'Mato Leitão' },
      { id: 4855, name: 'Mato Queimado' },
      { id: 4856, name: 'Maximiliano de Almeida' },
      { id: 4857, name: 'Minas do Leão' },
      { id: 4858, name: 'Miraguaí' },
      { id: 4859, name: 'Montauri' },
      { id: 4860, name: 'Monte Alegre dos Campos' },
      { id: 4861, name: 'Monte Belo do Sul' },
      { id: 4862, name: 'Montenegro' },
      { id: 4863, name: 'Mormaço' },
      { id: 4864, name: 'Morrinhos do Sul' },
      { id: 4865, name: 'Morro Redondo' },
      { id: 4866, name: 'Morro Reuter' },
      { id: 4867, name: 'Mostardas' },
      { id: 4868, name: 'Muçum' },
      { id: 4869, name: 'Muitos Capões' },
      { id: 4870, name: 'Muliterno' },
      { id: 4871, name: 'Não-Me-Toque' },
      { id: 4872, name: 'Nicolau Vergueiro' },
      { id: 4873, name: 'Nonoai' },
      { id: 4874, name: 'Nova Alvorada' },
      { id: 4875, name: 'Nova Araçá' },
      { id: 4876, name: 'Nova Bassano' },
      { id: 4877, name: 'Nova Boa Vista' },
      { id: 4878, name: 'Nova Bréscia' },
      { id: 4879, name: 'Nova Candelária' },
      { id: 4880, name: 'Nova Esperança do Sul' },
      { id: 4881, name: 'Nova Hartz' },
      { id: 4882, name: 'Nova Pádua' },
      { id: 4883, name: 'Nova Palma' },
      { id: 4884, name: 'Nova Petrópolis' },
      { id: 4885, name: 'Nova Prata' },
      { id: 4886, name: 'Nova Ramada' },
      { id: 4887, name: 'Nova Roma do Sul' },
      { id: 4888, name: 'Nova Santa Rita' },
      { id: 4894, name: 'Novo Barreiro' },
      { id: 4889, name: 'Novo Cabrais' },
      { id: 4890, name: 'Novo Hamburgo' },
      { id: 4891, name: 'Novo Machado' },
      { id: 4892, name: 'Novo Tiradentes' },
      { id: 4893, name: 'Novo Xingu' },
      { id: 4895, name: 'Osório' },
      { id: 4896, name: 'Paim Filho' },
      { id: 4897, name: 'Palmares do Sul' },
      { id: 4898, name: 'Palmeira das Missões' },
      { id: 4899, name: 'Palmitinho' },
      { id: 4900, name: 'Panambi' },
      { id: 4901, name: 'Pantano Grande' },
      { id: 4902, name: 'Paraí' },
      { id: 4903, name: 'Paraíso do Sul' },
      { id: 4904, name: 'Pareci Novo' },
      { id: 4905, name: 'Parobé' },
      { id: 4906, name: 'Passa Sete' },
      { id: 4907, name: 'Passo do Sobrado' },
      { id: 4908, name: 'Passo Fundo' },
      { id: 4909, name: 'Paulo Bento' },
      { id: 4910, name: 'Paverama' },
      { id: 4911, name: 'Pedras Altas' },
      { id: 4912, name: 'Pedro Osório' },
      { id: 4913, name: 'Pejuçara' },
      { id: 4914, name: 'Pelotas' },
      { id: 4915, name: 'Picada Café' },
      { id: 4916, name: 'Pinhal' },
      { id: 4917, name: 'Pinhal da Serra' },
      { id: 4918, name: 'Pinhal Grande' },
      { id: 4919, name: 'Pinheirinho do Vale' },
      { id: 4920, name: 'Pinheiro Machado' },
      { id: 4921, name: 'Pirapó' },
      { id: 4922, name: 'Piratini' },
      { id: 4923, name: 'Planalto' },
      { id: 4924, name: 'Poço das Antas' },
      { id: 4925, name: 'Pontão' },
      { id: 4926, name: 'Ponte Preta' },
      { id: 4927, name: 'Portão' },
      { id: 4928, name: 'Porto Alegre' },
      { id: 4929, name: 'Porto Lucena' },
      { id: 4930, name: 'Porto Mauá' },
      { id: 4931, name: 'Porto Vera Cruz' },
      { id: 4932, name: 'Porto Xavier' },
      { id: 4933, name: 'Pouso Novo' },
      { id: 4934, name: 'Presidente Lucena' },
      { id: 4935, name: 'Progresso' },
      { id: 4936, name: 'Protásio Alves' },
      { id: 4937, name: 'Putinga' },
      { id: 4938, name: 'Quaraí' },
      { id: 4939, name: 'Quatro Irmãos' },
      { id: 4940, name: 'Quevedos' },
      { id: 4941, name: 'Quinze de Novembro' },
      { id: 4942, name: 'Redentora' },
      { id: 4943, name: 'Relvado' },
      { id: 4944, name: 'Restinga Seca' },
      { id: 4945, name: 'Rio dos Índios' },
      { id: 4946, name: 'Rio Grande' },
      { id: 4947, name: 'Rio Pardo' },
      { id: 4948, name: 'Riozinho' },
      { id: 4949, name: 'Roca Sales' },
      { id: 4950, name: 'Rodeio Bonito' },
      { id: 4951, name: 'Rolador' },
      { id: 4952, name: 'Rolante' },
      { id: 4953, name: 'Ronda Alta' },
      { id: 4954, name: 'Rondinha' },
      { id: 4955, name: 'Roque Gonzales' },
      { id: 4956, name: 'Rosário do Sul' },
      { id: 4957, name: 'Sagrada Família' },
      { id: 4958, name: 'Saldanha Marinho' },
      { id: 4959, name: 'Salto do Jacuí' },
      { id: 4960, name: 'Salvador das Missões' },
      { id: 4961, name: 'Salvador do Sul' },
      { id: 4962, name: 'Sananduva' },
      { id: 4971, name: "Sant' Ana do Livramento" },
      { id: 4963, name: 'Santa Bárbara do Sul' },
      { id: 4964, name: 'Santa Cecília do Sul' },
      { id: 4965, name: 'Santa Clara do Sul' },
      { id: 4966, name: 'Santa Cruz do Sul' },
      { id: 4969, name: 'Santa Margarida do Sul' },
      { id: 4967, name: 'Santa Maria' },
      { id: 4968, name: 'Santa Maria do Herval' },
      { id: 4972, name: 'Santa Rosa' },
      { id: 4973, name: 'Santa Tereza' },
      { id: 4974, name: 'Santa Vitória do Palmar' },
      { id: 4970, name: 'Santana da Boa Vista' },
      { id: 4975, name: 'Santiago' },
      { id: 4976, name: 'Santo Ângelo' },
      { id: 4978, name: 'Santo Antônio da Patrulha' },
      { id: 4979, name: 'Santo Antônio das Missões' },
      { id: 4977, name: 'Santo Antônio do Palma' },
      { id: 4980, name: 'Santo Antônio do Planalto' },
      { id: 4981, name: 'Santo Augusto' },
      { id: 4982, name: 'Santo Cristo' },
      { id: 4983, name: 'Santo Expedito do Sul' },
      { id: 4984, name: 'São Borja' },
      { id: 4985, name: 'São Domingos do Sul' },
      { id: 4986, name: 'São Francisco de Assis' },
      { id: 4987, name: 'São Francisco de Paula' },
      { id: 4988, name: 'São Gabriel' },
      { id: 4989, name: 'São Jerônimo' },
      { id: 4990, name: 'São João da Urtiga' },
      { id: 4991, name: 'São João do Polêsine' },
      { id: 4992, name: 'São Jorge' },
      { id: 4993, name: 'São José das Missões' },
      { id: 4994, name: 'São José do Herval' },
      { id: 4995, name: 'São José do Hortêncio' },
      { id: 4996, name: 'São José do Inhacorá' },
      { id: 4997, name: 'São José do Norte' },
      { id: 4998, name: 'São José do Ouro' },
      { id: 4999, name: 'São José do Sul' },
      { id: 5000, name: 'São José dos Ausentes' },
      { id: 5001, name: 'São Leopoldo' },
      { id: 5002, name: 'São Lourenço do Sul' },
      { id: 5003, name: 'São Luiz Gonzaga' },
      { id: 5004, name: 'São Marcos' },
      { id: 5005, name: 'São Martinho' },
      { id: 5006, name: 'São Martinho da Serra' },
      { id: 5007, name: 'São Miguel das Missões' },
      { id: 5008, name: 'São Nicolau' },
      { id: 5009, name: 'São Paulo das Missões' },
      { id: 5010, name: 'São Pedro da Serra' },
      { id: 5011, name: 'São Pedro das Missões' },
      { id: 5012, name: 'São Pedro do Butiá' },
      { id: 5013, name: 'São Pedro do Sul' },
      { id: 5014, name: 'São Sebastião do Caí' },
      { id: 5015, name: 'São Sepé' },
      { id: 5016, name: 'São Valentim' },
      { id: 5017, name: 'São Valentim do Sul' },
      { id: 5018, name: 'São Valério do Sul' },
      { id: 5019, name: 'São Vendelino' },
      { id: 5020, name: 'São Vicente do Sul' },
      { id: 5021, name: 'Sapiranga' },
      { id: 5022, name: 'Sapucaia do Sul' },
      { id: 5023, name: 'Sarandi' },
      { id: 5024, name: 'Seberi' },
      { id: 5025, name: 'Sede Nova' },
      { id: 5026, name: 'Segredo' },
      { id: 5027, name: 'Selbach' },
      { id: 5028, name: 'Senador Salgado Filho' },
      { id: 5029, name: 'Sentinela do Sul' },
      { id: 5030, name: 'Serafina Corrêa' },
      { id: 5031, name: 'Sério' },
      { id: 5032, name: 'Sertão' },
      { id: 5033, name: 'Sertão Santana' },
      { id: 5034, name: 'Sete de Setembro' },
      { id: 5035, name: 'Severiano de Almeida' },
      { id: 5036, name: 'Silveira Martins' },
      { id: 5037, name: 'Sinimbu' },
      { id: 5038, name: 'Sobradinho' },
      { id: 5039, name: 'Soledade' },
      { id: 5040, name: 'Tabaí' },
      { id: 5041, name: 'Tapejara' },
      { id: 5042, name: 'Tapera' },
      { id: 5043, name: 'Tapes' },
      { id: 5044, name: 'Taquara' },
      { id: 5045, name: 'Taquari' },
      { id: 5046, name: 'Taquaruçu do Sul' },
      { id: 5047, name: 'Tavares' },
      { id: 5048, name: 'Tenente Portela' },
      { id: 5049, name: 'Terra de Areia' },
      { id: 5050, name: 'Teutônia' },
      { id: 5051, name: 'Tio Hugo' },
      { id: 5052, name: 'Tiradentes do Sul' },
      { id: 5053, name: 'Toropi' },
      { id: 5054, name: 'Torres' },
      { id: 5055, name: 'Tramandaí' },
      { id: 5056, name: 'Travesseiro' },
      { id: 5057, name: 'Três Arroios' },
      { id: 5058, name: 'Três Cachoeiras' },
      { id: 5059, name: 'Três Coroas' },
      { id: 5060, name: 'Três de Maio' },
      { id: 5061, name: 'Três Forquilhas' },
      { id: 5062, name: 'Três Palmeiras' },
      { id: 5063, name: 'Três Passos' },
      { id: 5064, name: 'Trindade do Sul' },
      { id: 5065, name: 'Triunfo' },
      { id: 5066, name: 'Tucunduva' },
      { id: 5067, name: 'Tunas' },
      { id: 5068, name: 'Tupanci do Sul' },
      { id: 5069, name: 'Tupanciretã' },
      { id: 5070, name: 'Tupandi' },
      { id: 5071, name: 'Tuparendi' },
      { id: 5072, name: 'Turuçu' },
      { id: 5073, name: 'Ubiretama' },
      { id: 5074, name: 'União da Serra' },
      { id: 5075, name: 'Unistalda' },
      { id: 5076, name: 'Uruguaiana' },
      { id: 5077, name: 'Vacaria' },
      { id: 5079, name: 'Vale do Sol' },
      { id: 5080, name: 'Vale Real' },
      { id: 5078, name: 'Vale Verde' },
      { id: 5081, name: 'Vanini' },
      { id: 5082, name: 'Venâncio Aires' },
      { id: 5083, name: 'Vera Cruz' },
      { id: 5084, name: 'Veranópolis' },
      { id: 5085, name: 'Vespasiano Correa' },
      { id: 5086, name: 'Viadutos' },
      { id: 5087, name: 'Viamão' },
      { id: 5088, name: 'Vicente Dutra' },
      { id: 5089, name: 'Victor Graeff' },
      { id: 5090, name: 'Vila Flores' },
      { id: 5091, name: 'Vila Lângaro' },
      { id: 5092, name: 'Vila Maria' },
      { id: 5093, name: 'Vila Nova do Sul' },
      { id: 5094, name: 'Vista Alegre' },
      { id: 5095, name: 'Vista Alegre do Prata' },
      { id: 5096, name: 'Vista Gaúcha' },
      { id: 5097, name: 'Vitória das Missões' },
      { id: 5098, name: 'Westfalia' },
      { id: 5099, name: 'Xangri-lá' },
    ],
  },
  {
    id: 22,
    name: 'SC',
    fullname: 'Santa Catarina',
    cities: [
      { id: 4398, name: 'Florianópolis' },
      { id: 4311, name: 'Abdon Batista' },
      { id: 4312, name: 'Abelardo Luz' },
      { id: 4313, name: 'Agrolândia' },
      { id: 4314, name: 'Agronômica' },
      { id: 4315, name: 'Água Doce' },
      { id: 4316, name: 'Águas de Chapecó' },
      { id: 4317, name: 'Águas Frias' },
      { id: 4318, name: 'Águas Mornas' },
      { id: 4319, name: 'Alfredo Wagner' },
      { id: 4320, name: 'Alto Bela Vista' },
      { id: 4321, name: 'Anchieta' },
      { id: 4322, name: 'Angelina' },
      { id: 4323, name: 'Anita Garibaldi' },
      { id: 4324, name: 'Anitápolis' },
      { id: 4325, name: 'Antônio Carlos' },
      { id: 4326, name: 'Apiúna' },
      { id: 4327, name: 'Arabutã' },
      { id: 4328, name: 'Araquari' },
      { id: 4329, name: 'Araranguá' },
      { id: 4330, name: 'Armazém' },
      { id: 4331, name: 'Arroio Trinta' },
      { id: 4332, name: 'Arvoredo' },
      { id: 4333, name: 'Ascurra' },
      { id: 4334, name: 'Atalanta' },
      { id: 4335, name: 'Aurora' },
      { id: 4336, name: 'Balneário Arroio do Silva' },
      { id: 4338, name: 'Balneário Barra do Sul' },
      { id: 4337, name: 'Balneário Camboriú' },
      { id: 4339, name: 'Balneário Gaivota' },
      { id: 4504, name: 'Balneário Piçarras' },
      { id: 4340, name: 'Bandeirante' },
      { id: 4341, name: 'Barra Bonita' },
      { id: 4342, name: 'Barra Velha' },
      { id: 4343, name: 'Bela Vista do Toldo' },
      { id: 4344, name: 'Belmonte' },
      { id: 4345, name: 'Benedito Novo' },
      { id: 4346, name: 'Biguaçu' },
      { id: 4347, name: 'Blumenau' },
      { id: 4348, name: 'Bocaina do Sul' },
      { id: 4350, name: 'Bom Jardim da Serra' },
      { id: 4351, name: 'Bom Jesus' },
      { id: 4352, name: 'Bom Jesus do Oeste' },
      { id: 4353, name: 'Bom Retiro' },
      { id: 4349, name: 'Bombinhas' },
      { id: 4354, name: 'Botuverá' },
      { id: 4355, name: 'Braço do Norte' },
      { id: 4356, name: 'Braço do Trombudo' },
      { id: 4357, name: 'Brunópolis' },
      { id: 4358, name: 'Brusque' },
      { id: 4359, name: 'Caçador' },
      { id: 4360, name: 'Caibi' },
      { id: 4361, name: 'Calmon' },
      { id: 4362, name: 'Camboriú' },
      { id: 4364, name: 'Campo Alegre' },
      { id: 4365, name: 'Campo Belo do Sul' },
      { id: 4366, name: 'Campo Erê' },
      { id: 4367, name: 'Campos Novos' },
      { id: 4368, name: 'Canelinha' },
      { id: 4369, name: 'Canoinhas' },
      { id: 4363, name: 'Capão Alto' },
      { id: 4370, name: 'Capinzal' },
      { id: 4371, name: 'Capivari de Baixo' },
      { id: 4372, name: 'Catanduvas' },
      { id: 4373, name: 'Caxambu do Sul' },
      { id: 4374, name: 'Celso Ramos' },
      { id: 4375, name: 'Cerro Negro' },
      { id: 4376, name: 'Chapadão do Lageado' },
      { id: 4377, name: 'Chapecó' },
      { id: 4378, name: 'Cocal do Sul' },
      { id: 4379, name: 'Concórdia' },
      { id: 4380, name: 'Cordilheira Alta' },
      { id: 4381, name: 'Coronel Freitas' },
      { id: 4382, name: 'Coronel Martins' },
      { id: 4384, name: 'Correia Pinto' },
      { id: 4383, name: 'Corupá' },
      { id: 4385, name: 'Criciúma' },
      { id: 4386, name: 'Cunha Porã' },
      { id: 4387, name: 'Cunhataí' },
      { id: 4388, name: 'Curitibanos' },
      { id: 4389, name: 'Descanso' },
      { id: 4390, name: 'Dionísio Cerqueira' },
      { id: 4391, name: 'Dona Emma' },
      { id: 4392, name: 'Doutor Pedrinho' },
      { id: 4393, name: 'Entre Rios' },
      { id: 4394, name: 'Ermo' },
      { id: 4395, name: 'Erval Velho' },
      { id: 4396, name: 'Faxinal dos Guedes' },
      { id: 4397, name: 'Flor do Sertão' },
      { id: 4398, name: 'Florianópolis' },
      { id: 4399, name: 'Formosa do Sul' },
      { id: 4400, name: 'Forquilhinha' },
      { id: 4401, name: 'Fraiburgo' },
      { id: 4402, name: 'Frei Rogério' },
      { id: 4403, name: 'Galvão' },
      { id: 4404, name: 'Garopaba' },
      { id: 4405, name: 'Garuva' },
      { id: 4406, name: 'Gaspar' },
      { id: 4407, name: 'Governador Celso Ramos' },
      { id: 4408, name: 'Grão Pará' },
      { id: 4409, name: 'Gravatal' },
      { id: 4410, name: 'Guabiruba' },
      { id: 4411, name: 'Guaraciaba' },
      { id: 4412, name: 'Guaramirim' },
      { id: 4413, name: 'Guarujá do Sul' },
      { id: 4414, name: 'Guatambú' },
      { id: 4415, name: "Herval d'Oeste" },
      { id: 4416, name: 'Ibiam' },
      { id: 4417, name: 'Ibicaré' },
      { id: 4418, name: 'Ibirama' },
      { id: 4419, name: 'Içara' },
      { id: 4420, name: 'Ilhota' },
      { id: 4421, name: 'Imaruí' },
      { id: 4422, name: 'Imbituba' },
      { id: 4423, name: 'Imbuia' },
      { id: 4424, name: 'Indaial' },
      { id: 4425, name: 'Iomerê' },
      { id: 4426, name: 'Ipira' },
      { id: 4427, name: 'Iporã do Oeste' },
      { id: 4428, name: 'Ipuaçu' },
      { id: 4429, name: 'Ipumirim' },
      { id: 4430, name: 'Iraceminha' },
      { id: 4431, name: 'Irani' },
      { id: 4432, name: 'Irati' },
      { id: 4433, name: 'Irineópolis' },
      { id: 4434, name: 'Itá' },
      { id: 4435, name: 'Itaiópolis' },
      { id: 4436, name: 'Itajaí' },
      { id: 4437, name: 'Itapema' },
      { id: 4438, name: 'Itapiranga' },
      { id: 4439, name: 'Itapoá' },
      { id: 4440, name: 'Ituporanga' },
      { id: 4441, name: 'Jaborá' },
      { id: 4442, name: 'Jacinto Machado' },
      { id: 4443, name: 'Jaguaruna' },
      { id: 4444, name: 'Jaraguá do Sul' },
      { id: 4445, name: 'Jardinópolis' },
      { id: 4446, name: 'Joaçaba' },
      { id: 4447, name: 'Joinville' },
      { id: 4448, name: 'José Boiteux' },
      { id: 4449, name: 'Jupiá' },
      { id: 4450, name: 'Lacerdópolis' },
      { id: 4451, name: 'Lages' },
      { id: 4452, name: 'Laguna' },
      { id: 4453, name: 'Lajeado Grande' },
      { id: 4454, name: 'Laurentino' },
      { id: 4455, name: 'Lauro Muller' },
      { id: 4456, name: 'Lebon Régis' },
      { id: 4457, name: 'Leoberto Leal' },
      { id: 4458, name: 'Lindóia do Sul' },
      { id: 4459, name: 'Lontras' },
      { id: 4460, name: 'Luiz Alves' },
      { id: 4461, name: 'Luzerna' },
      { id: 4462, name: 'Macieira' },
      { id: 4463, name: 'Mafra' },
      { id: 4464, name: 'Major Gercino' },
      { id: 4465, name: 'Major Vieira' },
      { id: 4466, name: 'Maracajá' },
      { id: 4467, name: 'Maravilha' },
      { id: 4468, name: 'Marema' },
      { id: 4469, name: 'Massaranduba' },
      { id: 4470, name: 'Matos Costa' },
      { id: 4471, name: 'Meleiro' },
      { id: 4472, name: 'Mirim Doce' },
      { id: 4473, name: 'Modelo' },
      { id: 4474, name: 'Mondaí' },
      { id: 4475, name: 'Monte Carlo' },
      { id: 4476, name: 'Monte Castelo' },
      { id: 4477, name: 'Morro da Fumaça' },
      { id: 4478, name: 'Morro Grande' },
      { id: 4479, name: 'Navegantes' },
      { id: 4480, name: 'Nova Erechim' },
      { id: 4481, name: 'Nova Itaberaba' },
      { id: 4482, name: 'Nova Trento' },
      { id: 4483, name: 'Nova Veneza' },
      { id: 4484, name: 'Novo Horizonte' },
      { id: 4485, name: 'Orleans' },
      { id: 4486, name: 'Otacílio Costa' },
      { id: 4487, name: 'Ouro' },
      { id: 4488, name: 'Ouro Verde' },
      { id: 4489, name: 'Paial' },
      { id: 4490, name: 'Painel' },
      { id: 4491, name: 'Palhoça' },
      { id: 4492, name: 'Palma Sola' },
      { id: 4493, name: 'Palmeira' },
      { id: 4494, name: 'Palmitos' },
      { id: 4495, name: 'Papanduva' },
      { id: 4496, name: 'Paraíso' },
      { id: 4497, name: 'Passo de Torres' },
      { id: 4498, name: 'Passos Maia' },
      { id: 4499, name: 'Paulo Lopes' },
      { id: 4500, name: 'Pedras Grandes' },
      { id: 4501, name: 'Penha' },
      { id: 4502, name: 'Peritiba' },
      { id: 4503, name: 'Petrolândia' },
      { id: 4505, name: 'Pinhalzinho' },
      { id: 4506, name: 'Pinheiro Preto' },
      { id: 4507, name: 'Piratuba' },
      { id: 4508, name: 'Planalto Alegre' },
      { id: 4509, name: 'Pomerode' },
      { id: 4510, name: 'Ponte Alta' },
      { id: 4511, name: 'Ponte Alta do Norte' },
      { id: 4512, name: 'Ponte Serrada' },
      { id: 4513, name: 'Porto Belo' },
      { id: 4514, name: 'Porto União' },
      { id: 4515, name: 'Pouso Redondo' },
      { id: 4516, name: 'Praia Grande' },
      { id: 4517, name: 'Presidente Castello Branco' },
      { id: 4518, name: 'Presidente Getúlio' },
      { id: 4519, name: 'Presidente Nereu' },
      { id: 4520, name: 'Princesa' },
      { id: 4521, name: 'Quilombo' },
      { id: 4522, name: 'Rancho Queimado' },
      { id: 4523, name: 'Rio das Antas' },
      { id: 4524, name: 'Rio do Campo' },
      { id: 4525, name: 'Rio do Oeste' },
      { id: 4527, name: 'Rio do Sul' },
      { id: 4526, name: 'Rio dos Cedros' },
      { id: 4528, name: 'Rio Fortuna' },
      { id: 4529, name: 'Rio Negrinho' },
      { id: 4530, name: 'Rio Rufino' },
      { id: 4531, name: 'Riqueza' },
      { id: 4532, name: 'Rodeio' },
      { id: 4533, name: 'Romelândia' },
      { id: 4534, name: 'Salete' },
      { id: 4535, name: 'Saltinho' },
      { id: 4536, name: 'Salto Veloso' },
      { id: 4537, name: 'Sangão' },
      { id: 4538, name: 'Santa Cecília' },
      { id: 4539, name: 'Santa Helena' },
      { id: 4540, name: 'Santa Rosa de Lima' },
      { id: 4541, name: 'Santa Rosa do Sul' },
      { id: 4542, name: 'Santa Terezinha' },
      { id: 4543, name: 'Santa Terezinha do Progresso' },
      { id: 4544, name: 'Santiago do Sul' },
      { id: 4545, name: 'Santo Amaro da Imperatriz' },
      { id: 4547, name: 'São Bento do Sul' },
      { id: 4546, name: 'São Bernardino' },
      { id: 4548, name: 'São Bonifácio' },
      { id: 4549, name: 'São Carlos' },
      { id: 4550, name: 'São Cristovão do Sul' },
      { id: 4551, name: 'São Domingos' },
      { id: 4552, name: 'São Francisco do Sul' },
      { id: 4554, name: 'São João Batista' },
      { id: 4555, name: 'São João do Itaperiú' },
      { id: 4553, name: 'São João do Oeste' },
      { id: 4556, name: 'São João do Sul' },
      { id: 4557, name: 'São Joaquim' },
      { id: 4558, name: 'São José' },
      { id: 4559, name: 'São José do Cedro' },
      { id: 4560, name: 'São José do Cerrito' },
      { id: 4561, name: 'São Lourenço do Oeste' },
      { id: 4562, name: 'São Ludgero' },
      { id: 4563, name: 'São Martinho' },
      { id: 4564, name: 'São Miguel da Boa Vista' },
      { id: 4565, name: 'São Miguel do Oeste' },
      { id: 4566, name: 'São Pedro de Alcântara' },
      { id: 4567, name: 'Saudades' },
      { id: 4568, name: 'Schroeder' },
      { id: 4569, name: 'Seara' },
      { id: 4570, name: 'Serra Alta' },
      { id: 4571, name: 'Siderópolis' },
      { id: 4572, name: 'Sombrio' },
      { id: 4573, name: 'Sul Brasil' },
      { id: 4574, name: 'Taió' },
      { id: 4575, name: 'Tangará' },
      { id: 4576, name: 'Tigrinhos' },
      { id: 4577, name: 'Tijucas' },
      { id: 4578, name: 'Timbé do Sul' },
      { id: 4579, name: 'Timbó' },
      { id: 4580, name: 'Timbó Grande' },
      { id: 4581, name: 'Três Barras' },
      { id: 4582, name: 'Treviso' },
      { id: 4583, name: 'Treze de Maio' },
      { id: 4584, name: 'Treze Tílias' },
      { id: 4585, name: 'Trombudo Central' },
      { id: 4586, name: 'Tubarão' },
      { id: 4587, name: 'Tunápolis' },
      { id: 4588, name: 'Turvo' },
      { id: 4589, name: 'União do Oeste' },
      { id: 4590, name: 'Urubici' },
      { id: 4591, name: 'Urupema' },
      { id: 4592, name: 'Urussanga' },
      { id: 4593, name: 'Vargeão' },
      { id: 4594, name: 'Vargem' },
      { id: 4595, name: 'Vargem Bonita' },
      { id: 4596, name: 'Vidal Ramos' },
      { id: 4597, name: 'Videira' },
      { id: 4598, name: 'Vitor Meireles' },
      { id: 4599, name: 'Witmarsum' },
      { id: 4600, name: 'Xanxerê' },
      { id: 4601, name: 'Xavantina' },
      { id: 4602, name: 'Xaxim' },
      { id: 4603, name: 'Zortéa' },
    ],
  },
  {
    id: 15,
    name: 'SE',
    fullname: 'Sergipe',
    cities: [
      { id: 1754, name: 'Aracaju' },
      { id: 1752, name: 'Amparo de São Francisco' },
      { id: 1753, name: 'Aquidabã' },
      { id: 1754, name: 'Aracaju' },
      { id: 1755, name: 'Arauá' },
      { id: 1756, name: 'Areia Branca' },
      { id: 1757, name: 'Barra dos Coqueiros' },
      { id: 1758, name: 'Boquim' },
      { id: 1759, name: 'Brejo Grande' },
      { id: 1760, name: 'Campo do Brito' },
      { id: 1761, name: 'Canhoba' },
      { id: 1762, name: 'Canindé de São Francisco' },
      { id: 1763, name: 'Capela' },
      { id: 1764, name: 'Carira' },
      { id: 1765, name: 'Carmópolis' },
      { id: 1766, name: 'Cedro de São João' },
      { id: 1767, name: 'Cristinápolis' },
      { id: 1768, name: 'Cumbe' },
      { id: 1769, name: 'Divina Pastora' },
      { id: 1770, name: 'Estância' },
      { id: 1771, name: 'Feira Nova' },
      { id: 1772, name: 'Frei Paulo' },
      { id: 1773, name: 'Gararu' },
      { id: 1774, name: 'General Maynard' },
      { id: 1775, name: 'Gracho Cardoso' },
      { id: 1776, name: 'Ilha das Flores' },
      { id: 1777, name: 'Indiaroba' },
      { id: 1778, name: 'Itabaiana' },
      { id: 1779, name: 'Itabaianinha' },
      { id: 1780, name: 'Itabi' },
      { id: 1781, name: "Itaporanga d'Ajuda" },
      { id: 1782, name: 'Japaratuba' },
      { id: 1783, name: 'Japoatã' },
      { id: 1784, name: 'Lagarto' },
      { id: 1785, name: 'Laranjeiras' },
      { id: 1786, name: 'Macambira' },
      { id: 1787, name: 'Malhada dos Bois' },
      { id: 1788, name: 'Malhador' },
      { id: 1789, name: 'Maruim' },
      { id: 1790, name: 'Moita Bonita' },
      { id: 1791, name: 'Monte Alegre de Sergipe' },
      { id: 1792, name: 'Muribeca' },
      { id: 1793, name: 'Neópolis' },
      { id: 1794, name: 'Nossa Senhora Aparecida' },
      { id: 1795, name: 'Nossa Senhora da Glória' },
      { id: 1796, name: 'Nossa Senhora das Dores' },
      { id: 1797, name: 'Nossa Senhora de Lourdes' },
      { id: 1798, name: 'Nossa Senhora do Socorro' },
      { id: 1799, name: 'Pacatuba' },
      { id: 1800, name: 'Pedra Mole' },
      { id: 1801, name: 'Pedrinhas' },
      { id: 1802, name: 'Pinhão' },
      { id: 1803, name: 'Pirambu' },
      { id: 1804, name: 'Poço Redondo' },
      { id: 1805, name: 'Poço Verde' },
      { id: 1806, name: 'Porto da Folha' },
      { id: 1807, name: 'Propriá' },
      { id: 1808, name: 'Riachão do Dantas' },
      { id: 1809, name: 'Riachuelo' },
      { id: 1810, name: 'Ribeirópolis' },
      { id: 1811, name: 'Rosário do Catete' },
      { id: 1812, name: 'Salgado' },
      { id: 1813, name: 'Santa Luzia do Itanhy' },
      { id: 1815, name: 'Santa Rosa de Lima' },
      { id: 1814, name: 'Santana do São Francisco' },
      { id: 1816, name: 'Santo Amaro das Brotas' },
      { id: 1817, name: 'São Cristóvão' },
      { id: 1818, name: 'São Domingos' },
      { id: 1819, name: 'São Francisco' },
      { id: 1820, name: 'São Miguel do Aleixo' },
      { id: 1821, name: 'Simão Dias' },
      { id: 1822, name: 'Siriri' },
      { id: 1823, name: 'Telha' },
      { id: 1824, name: 'Tobias Barreto' },
      { id: 1825, name: 'Tomar do Geru' },
      { id: 1826, name: 'Umbaúba' },
    ],
  },
  {
    id: 20,
    name: 'SP',
    fullname: 'São Paulo',
    cities: [
      { id: 3829, name: 'São Paulo' },
      { id: 3267, name: 'Adamantina' },
      { id: 3268, name: 'Adolfo' },
      { id: 3269, name: 'Aguaí' },
      { id: 3270, name: 'Águas da Prata' },
      { id: 3271, name: 'Águas de Lindóia' },
      { id: 3272, name: 'Águas de Santa Bárbara' },
      { id: 3273, name: 'Águas de São Pedro' },
      { id: 3274, name: 'Agudos' },
      { id: 3275, name: 'Alambari' },
      { id: 3276, name: 'Alfredo Marcondes' },
      { id: 3277, name: 'Altair' },
      { id: 3278, name: 'Altinópolis' },
      { id: 3279, name: 'Alto Alegre' },
      { id: 3280, name: 'Alumínio' },
      { id: 3281, name: 'Álvares Florence' },
      { id: 3282, name: 'Álvares Machado' },
      { id: 3283, name: 'Álvaro de Carvalho' },
      { id: 3284, name: 'Alvinlândia' },
      { id: 3285, name: 'Americana' },
      { id: 3286, name: 'Américo Brasiliense' },
      { id: 3287, name: 'Américo de Campos' },
      { id: 3288, name: 'Amparo' },
      { id: 3289, name: 'Analândia' },
      { id: 3290, name: 'Andradina' },
      { id: 3291, name: 'Angatuba' },
      { id: 3292, name: 'Anhembi' },
      { id: 3293, name: 'Anhumas' },
      { id: 3294, name: 'Aparecida' },
      { id: 3295, name: "Aparecida d'Oeste" },
      { id: 3296, name: 'Apiaí' },
      { id: 3297, name: 'Araçariguama' },
      { id: 3298, name: 'Araçatuba' },
      { id: 3299, name: 'Araçoiaba da Serra' },
      { id: 3300, name: 'Aramina' },
      { id: 3301, name: 'Arandu' },
      { id: 3302, name: 'Arapeí' },
      { id: 3303, name: 'Araraquara' },
      { id: 3304, name: 'Araras' },
      { id: 3305, name: 'Arco-Íris' },
      { id: 3306, name: 'Arealva' },
      { id: 3307, name: 'Areias' },
      { id: 3308, name: 'Areiópolis' },
      { id: 3309, name: 'Ariranha' },
      { id: 3310, name: 'Artur Nogueira' },
      { id: 3311, name: 'Arujá' },
      { id: 3312, name: 'Aspásia' },
      { id: 3313, name: 'Assis' },
      { id: 3314, name: 'Atibaia' },
      { id: 3315, name: 'Auriflama' },
      { id: 3316, name: 'Avaí' },
      { id: 3317, name: 'Avanhandava' },
      { id: 3318, name: 'Avaré' },
      { id: 3319, name: 'Bady Bassitt' },
      { id: 3320, name: 'Balbinos' },
      { id: 3321, name: 'Bálsamo' },
      { id: 3322, name: 'Bananal' },
      { id: 3323, name: 'Barão de Antonina' },
      { id: 3324, name: 'Barbosa' },
      { id: 3325, name: 'Bariri' },
      { id: 3326, name: 'Barra Bonita' },
      { id: 3327, name: 'Barra do Chapéu' },
      { id: 3328, name: 'Barra do Turvo' },
      { id: 3329, name: 'Barretos' },
      { id: 3330, name: 'Barrinha' },
      { id: 3331, name: 'Barueri' },
      { id: 3332, name: 'Bastos' },
      { id: 3333, name: 'Batatais' },
      { id: 3334, name: 'Bauru' },
      { id: 3335, name: 'Bebedouro' },
      { id: 3336, name: 'Bento de Abreu' },
      { id: 3337, name: 'Bernardino de Campos' },
      { id: 3338, name: 'Bertioga' },
      { id: 3339, name: 'Bilac' },
      { id: 3340, name: 'Birigui' },
      { id: 3341, name: 'Biritiba-Mirim' },
      { id: 3342, name: 'Boa Esperança do Sul' },
      { id: 3343, name: 'Bocaina' },
      { id: 3344, name: 'Bofete' },
      { id: 3345, name: 'Boituva' },
      { id: 3346, name: 'Bom Jesus dos Perdões' },
      { id: 3347, name: 'Bom Sucesso de Itararé' },
      { id: 3348, name: 'Borá' },
      { id: 3349, name: 'Boracéia' },
      { id: 3350, name: 'Borborema' },
      { id: 3351, name: 'Borebi' },
      { id: 3352, name: 'Botucatu' },
      { id: 3353, name: 'Bragança Paulista' },
      { id: 3354, name: 'Braúna' },
      { id: 3355, name: 'Brejo Alegre' },
      { id: 3356, name: 'Brodowski' },
      { id: 3357, name: 'Brotas' },
      { id: 3358, name: 'Buri' },
      { id: 3359, name: 'Buritama' },
      { id: 3360, name: 'Buritizal' },
      { id: 3361, name: 'Cabrália Paulista' },
      { id: 3362, name: 'Cabreúva' },
      { id: 3363, name: 'Caçapava' },
      { id: 3364, name: 'Cachoeira Paulista' },
      { id: 3365, name: 'Caconde' },
      { id: 3366, name: 'Cafelândia' },
      { id: 3367, name: 'Caiabu' },
      { id: 3368, name: 'Caieiras' },
      { id: 3369, name: 'Caiuá' },
      { id: 3370, name: 'Cajamar' },
      { id: 3371, name: 'Cajati' },
      { id: 3372, name: 'Cajobi' },
      { id: 3373, name: 'Cajuru' },
      { id: 3374, name: 'Campina do Monte Alegre' },
      { id: 3375, name: 'Campinas' },
      { id: 3376, name: 'Campo Limpo Paulista' },
      { id: 3377, name: 'Campos do Jordão' },
      { id: 3378, name: 'Campos Novos Paulista' },
      { id: 3379, name: 'Cananéia' },
      { id: 3380, name: 'Canas' },
      { id: 3381, name: 'Cândido Mota' },
      { id: 3382, name: 'Cândido Rodrigues' },
      { id: 3383, name: 'Canitar' },
      { id: 3384, name: 'Capão Bonito' },
      { id: 3385, name: 'Capela do Alto' },
      { id: 3386, name: 'Capivari' },
      { id: 3387, name: 'Caraguatatuba' },
      { id: 3388, name: 'Carapicuíba' },
      { id: 3389, name: 'Cardoso' },
      { id: 3390, name: 'Casa Branca' },
      { id: 3391, name: 'Cássia dos Coqueiros' },
      { id: 3392, name: 'Castilho' },
      { id: 3393, name: 'Catanduva' },
      { id: 3394, name: 'Catiguá' },
      { id: 3395, name: 'Cedral' },
      { id: 3396, name: 'Cerqueira César' },
      { id: 3397, name: 'Cerquilho' },
      { id: 3398, name: 'Cesário Lange' },
      { id: 3399, name: 'Charqueada' },
      { id: 3910, name: 'Chavantes' },
      { id: 3400, name: 'Clementina' },
      { id: 3401, name: 'Colina' },
      { id: 3402, name: 'Colômbia' },
      { id: 3403, name: 'Conchal' },
      { id: 3404, name: 'Conchas' },
      { id: 3405, name: 'Cordeirópolis' },
      { id: 3406, name: 'Coroados' },
      { id: 3407, name: 'Coronel Macedo' },
      { id: 3408, name: 'Corumbataí' },
      { id: 3409, name: 'Cosmópolis' },
      { id: 3410, name: 'Cosmorama' },
      { id: 3411, name: 'Cotia' },
      { id: 3412, name: 'Cravinhos' },
      { id: 3413, name: 'Cristais Paulista' },
      { id: 3414, name: 'Cruzália' },
      { id: 3415, name: 'Cruzeiro' },
      { id: 3416, name: 'Cubatão' },
      { id: 3417, name: 'Cunha' },
      { id: 3418, name: 'Descalvado' },
      { id: 3419, name: 'Diadema' },
      { id: 3420, name: 'Dirce Reis' },
      { id: 3421, name: 'Divinolândia' },
      { id: 3422, name: 'Dobrada' },
      { id: 3423, name: 'Dois Córregos' },
      { id: 3424, name: 'Dolcinópolis' },
      { id: 3425, name: 'Dourado' },
      { id: 3426, name: 'Dracena' },
      { id: 3427, name: 'Duartina' },
      { id: 3428, name: 'Dumont' },
      { id: 3429, name: 'Echaporã' },
      { id: 3430, name: 'Eldorado' },
      { id: 3431, name: 'Elias Fausto' },
      { id: 3432, name: 'Elisiário' },
      { id: 3433, name: 'Embaúba' },
      { id: 3434, name: 'Embu' },
      { id: 3435, name: 'Embu-Guaçu' },
      { id: 3436, name: 'Emilianópolis' },
      { id: 3437, name: 'Engenheiro Coelho' },
      { id: 3438, name: 'Espírito Santo do Pinhal' },
      { id: 3439, name: 'Espírito Santo do Turvo' },
      { id: 3911, name: 'Estiva Gerbi' },
      { id: 3440, name: "Estrela d'Oeste" },
      { id: 3441, name: 'Estrela do Norte' },
      { id: 3442, name: 'Euclides da Cunha Paulista' },
      { id: 3443, name: 'Fartura' },
      { id: 3445, name: 'Fernando Prestes' },
      { id: 3444, name: 'Fernandópolis' },
      { id: 3446, name: 'Fernão' },
      { id: 3447, name: 'Ferraz de Vasconcelos' },
      { id: 3448, name: 'Flora Rica' },
      { id: 3449, name: 'Floreal' },
      { id: 3450, name: 'Flórida Paulista' },
      { id: 3451, name: 'Florínia' },
      { id: 3452, name: 'Franca' },
      { id: 3453, name: 'Francisco Morato' },
      { id: 3454, name: 'Franco da Rocha' },
      { id: 3455, name: 'Gabriel Monteiro' },
      { id: 3456, name: 'Gália' },
      { id: 3457, name: 'Garça' },
      { id: 3458, name: 'Gastão Vidigal' },
      { id: 3459, name: 'Gavião Peixoto' },
      { id: 3460, name: 'General Salgado' },
      { id: 3461, name: 'Getulina' },
      { id: 3462, name: 'Glicério' },
      { id: 3463, name: 'Guaiçara' },
      { id: 3464, name: 'Guaimbê' },
      { id: 3465, name: 'Guaíra' },
      { id: 3466, name: 'Guapiaçu' },
      { id: 3467, name: 'Guapiara' },
      { id: 3468, name: 'Guará' },
      { id: 3469, name: 'Guaraçaí' },
      { id: 3470, name: 'Guaraci' },
      { id: 3471, name: "Guarani d'Oeste" },
      { id: 3472, name: 'Guarantã' },
      { id: 3473, name: 'Guararapes' },
      { id: 3474, name: 'Guararema' },
      { id: 3475, name: 'Guaratinguetá' },
      { id: 3476, name: 'Guareí' },
      { id: 3477, name: 'Guariba' },
      { id: 3478, name: 'Guarujá' },
      { id: 3479, name: 'Guarulhos' },
      { id: 3480, name: 'Guatapará' },
      { id: 3481, name: 'Guzolândia' },
      { id: 3482, name: 'Herculândia' },
      { id: 3483, name: 'Holambra' },
      { id: 3484, name: 'Hortolândia' },
      { id: 3485, name: 'Iacanga' },
      { id: 3486, name: 'Iacri' },
      { id: 3487, name: 'Iaras' },
      { id: 3488, name: 'Ibaté' },
      { id: 3489, name: 'Ibirá' },
      { id: 3490, name: 'Ibirarema' },
      { id: 3491, name: 'Ibitinga' },
      { id: 3492, name: 'Ibiúna' },
      { id: 3493, name: 'Icém' },
      { id: 3494, name: 'Iepê' },
      { id: 3495, name: 'Igaraçu do Tietê' },
      { id: 3496, name: 'Igarapava' },
      { id: 3497, name: 'Igaratá' },
      { id: 3498, name: 'Iguape' },
      { id: 3500, name: 'Ilha Comprida' },
      { id: 3501, name: 'Ilha Solteira' },
      { id: 3499, name: 'Ilhabela' },
      { id: 3502, name: 'Indaiatuba' },
      { id: 3503, name: 'Indiana' },
      { id: 3504, name: 'Indiaporã' },
      { id: 3505, name: 'Inúbia Paulista' },
      { id: 3506, name: 'Ipaussu' },
      { id: 3507, name: 'Iperó' },
      { id: 3508, name: 'Ipeúna' },
      { id: 3509, name: 'Ipiguá' },
      { id: 3510, name: 'Iporanga' },
      { id: 3511, name: 'Ipuã' },
      { id: 3512, name: 'Iracemápolis' },
      { id: 3513, name: 'Irapuã' },
      { id: 3514, name: 'Irapuru' },
      { id: 3515, name: 'Itaberá' },
      { id: 3516, name: 'Itaí' },
      { id: 3517, name: 'Itajobi' },
      { id: 3518, name: 'Itaju' },
      { id: 3519, name: 'Itanhaém' },
      { id: 3520, name: 'Itaóca' },
      { id: 3521, name: 'Itapecerica da Serra' },
      { id: 3522, name: 'Itapetininga' },
      { id: 3523, name: 'Itapeva' },
      { id: 3524, name: 'Itapevi' },
      { id: 3525, name: 'Itapira' },
      { id: 3526, name: 'Itapirapuã Paulista' },
      { id: 3527, name: 'Itápolis' },
      { id: 3528, name: 'Itaporanga' },
      { id: 3529, name: 'Itapuí' },
      { id: 3530, name: 'Itapura' },
      { id: 3531, name: 'Itaquaquecetuba' },
      { id: 3532, name: 'Itararé' },
      { id: 3533, name: 'Itariri' },
      { id: 3534, name: 'Itatiba' },
      { id: 3535, name: 'Itatinga' },
      { id: 3536, name: 'Itirapina' },
      { id: 3537, name: 'Itirapuã' },
      { id: 3538, name: 'Itobi' },
      { id: 3539, name: 'Itu' },
      { id: 3540, name: 'Itupeva' },
      { id: 3541, name: 'Ituverava' },
      { id: 3542, name: 'Jaborandi' },
      { id: 3543, name: 'Jaboticabal' },
      { id: 3544, name: 'Jacareí' },
      { id: 3545, name: 'Jaci' },
      { id: 3546, name: 'Jacupiranga' },
      { id: 3547, name: 'Jaguariúna' },
      { id: 3548, name: 'Jales' },
      { id: 3549, name: 'Jambeiro' },
      { id: 3550, name: 'Jandira' },
      { id: 3551, name: 'Jardinópolis' },
      { id: 3552, name: 'Jarinu' },
      { id: 3553, name: 'Jaú' },
      { id: 3554, name: 'Jeriquara' },
      { id: 3555, name: 'Joanópolis' },
      { id: 3556, name: 'João Ramalho' },
      { id: 3557, name: 'José Bonifácio' },
      { id: 3558, name: 'Júlio Mesquita' },
      { id: 3559, name: 'Jumirim' },
      { id: 3560, name: 'Jundiaí' },
      { id: 3561, name: 'Junqueirópolis' },
      { id: 3562, name: 'Juquiá' },
      { id: 3563, name: 'Juquitiba' },
      { id: 3564, name: 'Lagoinha' },
      { id: 3565, name: 'Laranjal Paulista' },
      { id: 3566, name: 'Lavínia' },
      { id: 3567, name: 'Lavrinhas' },
      { id: 3568, name: 'Leme' },
      { id: 3569, name: 'Lençóis Paulista' },
      { id: 3570, name: 'Limeira' },
      { id: 3571, name: 'Lindóia' },
      { id: 3572, name: 'Lins' },
      { id: 3573, name: 'Lorena' },
      { id: 3574, name: 'Lourdes' },
      { id: 3575, name: 'Louveira' },
      { id: 3576, name: 'Lucélia' },
      { id: 3577, name: 'Lucianópolis' },
      { id: 3578, name: 'Luís Antônio' },
      { id: 3579, name: 'Luiziânia' },
      { id: 3580, name: 'Lupércio' },
      { id: 3581, name: 'Lutécia' },
      { id: 3582, name: 'Macatuba' },
      { id: 3583, name: 'Macaubal' },
      { id: 3584, name: 'Macedônia' },
      { id: 3585, name: 'Magda' },
      { id: 3586, name: 'Mairinque' },
      { id: 3587, name: 'Mairiporã' },
      { id: 3588, name: 'Manduri' },
      { id: 3589, name: 'Marabá Paulista' },
      { id: 3590, name: 'Maracaí' },
      { id: 3591, name: 'Marapoama' },
      { id: 3592, name: 'Mariápolis' },
      { id: 3593, name: 'Marília' },
      { id: 3594, name: 'Marinópolis' },
      { id: 3595, name: 'Martinópolis' },
      { id: 3596, name: 'Matão' },
      { id: 3597, name: 'Mauá' },
      { id: 3598, name: 'Mendonça' },
      { id: 3599, name: 'Meridiano' },
      { id: 3600, name: 'Mesópolis' },
      { id: 3601, name: 'Miguelópolis' },
      { id: 3602, name: 'Mineiros do Tietê' },
      { id: 3604, name: 'Mira Estrela' },
      { id: 3603, name: 'Miracatu' },
      { id: 3605, name: 'Mirandópolis' },
      { id: 3606, name: 'Mirante do Paranapanema' },
      { id: 3607, name: 'Mirassol' },
      { id: 3608, name: 'Mirassolândia' },
      { id: 3609, name: 'Mococa' },
      { id: 3610, name: 'Mogi das Cruzes' },
      { id: 3611, name: 'Mogi Guaçu' },
      { id: 3612, name: 'Moji Mirim' },
      { id: 3613, name: 'Mombuca' },
      { id: 3614, name: 'Monções' },
      { id: 3615, name: 'Mongaguá' },
      { id: 3616, name: 'Monte Alegre do Sul' },
      { id: 3617, name: 'Monte Alto' },
      { id: 3618, name: 'Monte Aprazível' },
      { id: 3619, name: 'Monte Azul Paulista' },
      { id: 3620, name: 'Monte Castelo' },
      { id: 3622, name: 'Monte Mor' },
      { id: 3621, name: 'Monteiro Lobato' },
      { id: 3623, name: 'Morro Agudo' },
      { id: 3624, name: 'Morungaba' },
      { id: 3625, name: 'Motuca' },
      { id: 3626, name: 'Murutinga do Sul' },
      { id: 3627, name: 'Nantes' },
      { id: 3628, name: 'Narandiba' },
      { id: 3629, name: 'Natividade da Serra' },
      { id: 3630, name: 'Nazaré Paulista' },
      { id: 3631, name: 'Neves Paulista' },
      { id: 3632, name: 'Nhandeara' },
      { id: 3633, name: 'Nipoã' },
      { id: 3634, name: 'Nova Aliança' },
      { id: 3635, name: 'Nova Campina' },
      { id: 3636, name: 'Nova Canaã Paulista' },
      { id: 3637, name: 'Nova Castilho' },
      { id: 3638, name: 'Nova Europa' },
      { id: 3639, name: 'Nova Granada' },
      { id: 3640, name: 'Nova Guataporanga' },
      { id: 3641, name: 'Nova Independência' },
      { id: 3643, name: 'Nova Luzitânia' },
      { id: 3644, name: 'Nova Odessa' },
      { id: 3642, name: 'Novais' },
      { id: 3645, name: 'Novo Horizonte' },
      { id: 3646, name: 'Nuporanga' },
      { id: 3647, name: 'Ocauçu' },
      { id: 3648, name: 'Óleo' },
      { id: 3649, name: 'Olímpia' },
      { id: 3650, name: 'Onda Verde' },
      { id: 3651, name: 'Oriente' },
      { id: 3652, name: 'Orindiúva' },
      { id: 3653, name: 'Orlândia' },
      { id: 3654, name: 'Osasco' },
      { id: 3655, name: 'Oscar Bressane' },
      { id: 3656, name: 'Osvaldo Cruz' },
      { id: 3657, name: 'Ourinhos' },
      { id: 3659, name: 'Ouro Verde' },
      { id: 3658, name: 'Ouroeste' },
      { id: 3660, name: 'Pacaembu' },
      { id: 3661, name: 'Palestina' },
      { id: 3662, name: 'Palmares Paulista' },
      { id: 3663, name: "Palmeira d'Oeste" },
      { id: 3664, name: 'Palmital' },
      { id: 3665, name: 'Panorama' },
      { id: 3666, name: 'Paraguaçu Paulista' },
      { id: 3667, name: 'Paraibuna' },
      { id: 3668, name: 'Paraíso' },
      { id: 3669, name: 'Paranapanema' },
      { id: 3670, name: 'Paranapuã' },
      { id: 3671, name: 'Parapuã' },
      { id: 3672, name: 'Pardinho' },
      { id: 3673, name: 'Pariquera-Açu' },
      { id: 3674, name: 'Parisi' },
      { id: 3675, name: 'Patrocínio Paulista' },
      { id: 3676, name: 'Paulicéia' },
      { id: 3677, name: 'Paulínia' },
      { id: 3678, name: 'Paulistânia' },
      { id: 3679, name: 'Paulo de Faria' },
      { id: 3680, name: 'Pederneiras' },
      { id: 3681, name: 'Pedra Bela' },
      { id: 3682, name: 'Pedranópolis' },
      { id: 3683, name: 'Pedregulho' },
      { id: 3684, name: 'Pedreira' },
      { id: 3685, name: 'Pedrinhas Paulista' },
      { id: 3686, name: 'Pedro de Toledo' },
      { id: 3687, name: 'Penápolis' },
      { id: 3688, name: 'Pereira Barreto' },
      { id: 3689, name: 'Pereiras' },
      { id: 3690, name: 'Peruíbe' },
      { id: 3691, name: 'Piacatu' },
      { id: 3692, name: 'Piedade' },
      { id: 3693, name: 'Pilar do Sul' },
      { id: 3694, name: 'Pindamonhangaba' },
      { id: 3695, name: 'Pindorama' },
      { id: 3696, name: 'Pinhalzinho' },
      { id: 3697, name: 'Piquerobi' },
      { id: 3698, name: 'Piquete' },
      { id: 3699, name: 'Piracaia' },
      { id: 3700, name: 'Piracicaba' },
      { id: 3701, name: 'Piraju' },
      { id: 3702, name: 'Pirajuí' },
      { id: 3703, name: 'Pirangi' },
      { id: 3704, name: 'Pirapora do Bom Jesus' },
      { id: 3705, name: 'Pirapozinho' },
      { id: 3706, name: 'Pirassununga' },
      { id: 3707, name: 'Piratininga' },
      { id: 3708, name: 'Pitangueiras' },
      { id: 3709, name: 'Planalto' },
      { id: 3710, name: 'Platina' },
      { id: 3711, name: 'Poá' },
      { id: 3712, name: 'Poloni' },
      { id: 3713, name: 'Pompéia' },
      { id: 3714, name: 'Pongaí' },
      { id: 3715, name: 'Pontal' },
      { id: 3716, name: 'Pontalinda' },
      { id: 3717, name: 'Pontes Gestal' },
      { id: 3718, name: 'Populina' },
      { id: 3719, name: 'Porangaba' },
      { id: 3720, name: 'Porto Feliz' },
      { id: 3721, name: 'Porto Ferreira' },
      { id: 3722, name: 'Potim' },
      { id: 3723, name: 'Potirendaba' },
      { id: 3724, name: 'Pracinha' },
      { id: 3725, name: 'Pradópolis' },
      { id: 3726, name: 'Praia Grande' },
      { id: 3727, name: 'Pratânia' },
      { id: 3728, name: 'Presidente Alves' },
      { id: 3729, name: 'Presidente Bernardes' },
      { id: 3730, name: 'Presidente Epitácio' },
      { id: 3731, name: 'Presidente Prudente' },
      { id: 3732, name: 'Presidente Venceslau' },
      { id: 3733, name: 'Promissão' },
      { id: 3734, name: 'Quadra' },
      { id: 3735, name: 'Quatá' },
      { id: 3736, name: 'Queiroz' },
      { id: 3737, name: 'Queluz' },
      { id: 3738, name: 'Quintana' },
      { id: 3739, name: 'Rafard' },
      { id: 3740, name: 'Rancharia' },
      { id: 3741, name: 'Redenção da Serra' },
      { id: 3742, name: 'Regente Feijó' },
      { id: 3743, name: 'Reginópolis' },
      { id: 3744, name: 'Registro' },
      { id: 3745, name: 'Restinga' },
      { id: 3746, name: 'Ribeira' },
      { id: 3747, name: 'Ribeirão Bonito' },
      { id: 3748, name: 'Ribeirão Branco' },
      { id: 3749, name: 'Ribeirão Corrente' },
      { id: 3750, name: 'Ribeirão do Sul' },
      { id: 3751, name: 'Ribeirão dos Índios' },
      { id: 3752, name: 'Ribeirão Grande' },
      { id: 3753, name: 'Ribeirão Pires' },
      { id: 3754, name: 'Ribeirão Preto' },
      { id: 3756, name: 'Rifaina' },
      { id: 3757, name: 'Rincão' },
      { id: 3758, name: 'Rinópolis' },
      { id: 3759, name: 'Rio Claro' },
      { id: 3760, name: 'Rio das Pedras' },
      { id: 3761, name: 'Rio Grande da Serra' },
      { id: 3762, name: 'Riolândia' },
      { id: 3755, name: 'Riversul' },
      { id: 3763, name: 'Rosana' },
      { id: 3764, name: 'Roseira' },
      { id: 3765, name: 'Rubiácea' },
      { id: 3766, name: 'Rubinéia' },
      { id: 3767, name: 'Sabino' },
      { id: 3768, name: 'Sagres' },
      { id: 3769, name: 'Sales' },
      { id: 3770, name: 'Sales Oliveira' },
      { id: 3771, name: 'Salesópolis' },
      { id: 3772, name: 'Salmourão' },
      { id: 3773, name: 'Saltinho' },
      { id: 3774, name: 'Salto' },
      { id: 3775, name: 'Salto de Pirapora' },
      { id: 3776, name: 'Salto Grande' },
      { id: 3777, name: 'Sandovalina' },
      { id: 3778, name: 'Santa Adélia' },
      { id: 3779, name: 'Santa Albertina' },
      { id: 3780, name: "Santa Bárbara d'Oeste" },
      { id: 3781, name: 'Santa Branca' },
      { id: 3782, name: "Santa Clara d'Oeste" },
      { id: 3783, name: 'Santa Cruz da Conceição' },
      { id: 3784, name: 'Santa Cruz da Esperança' },
      { id: 3785, name: 'Santa Cruz das Palmeiras' },
      { id: 3786, name: 'Santa Cruz do Rio Pardo' },
      { id: 3787, name: 'Santa Ernestina' },
      { id: 3788, name: 'Santa Fé do Sul' },
      { id: 3789, name: 'Santa Gertrudes' },
      { id: 3790, name: 'Santa Isabel' },
      { id: 3791, name: 'Santa Lúcia' },
      { id: 3792, name: 'Santa Maria da Serra' },
      { id: 3793, name: 'Santa Mercedes' },
      { id: 3796, name: "Santa Rita d'Oeste" },
      { id: 3797, name: 'Santa Rita do Passa Quatro' },
      { id: 3798, name: 'Santa Rosa de Viterbo' },
      { id: 3799, name: 'Santa Salete' },
      { id: 3794, name: 'Santana da Ponte Pensa' },
      { id: 3795, name: 'Santana de Parnaíba' },
      { id: 3800, name: 'Santo Anastácio' },
      { id: 3801, name: 'Santo André' },
      { id: 3802, name: 'Santo Antônio da Alegria' },
      { id: 3803, name: 'Santo Antônio de Posse' },
      { id: 3804, name: 'Santo Antônio do Aracanguá' },
      { id: 3805, name: 'Santo Antônio do Jardim' },
      { id: 3806, name: 'Santo Antônio do Pinhal' },
      { id: 3807, name: 'Santo Expedito' },
      { id: 3808, name: 'Santópolis do Aguapeí' },
      { id: 3809, name: 'Santos' },
      { id: 3810, name: 'São Bento do Sapucaí' },
      { id: 3811, name: 'São Bernardo do Campo' },
      { id: 3812, name: 'São Caetano do Sul' },
      { id: 3813, name: 'São Carlos' },
      { id: 3814, name: 'São Francisco' },
      { id: 3815, name: 'São João da Boa Vista' },
      { id: 3816, name: 'São João das Duas Pontes' },
      { id: 3817, name: 'São João de Iracema' },
      { id: 3818, name: "São João do Pau d'Alho" },
      { id: 3819, name: 'São Joaquim da Barra' },
      { id: 3820, name: 'São José da Bela Vista' },
      { id: 3821, name: 'São José do Barreiro' },
      { id: 3822, name: 'São José do Rio Pardo' },
      { id: 3823, name: 'São José do Rio Preto' },
      { id: 3824, name: 'São José dos Campos' },
      { id: 3825, name: 'São Lourenço da Serra' },
      { id: 3826, name: 'São Luís do Paraitinga' },
      { id: 3827, name: 'São Manuel' },
      { id: 3828, name: 'São Miguel Arcanjo' },
      { id: 3829, name: 'São Paulo' },
      { id: 3830, name: 'São Pedro' },
      { id: 3831, name: 'São Pedro do Turvo' },
      { id: 3832, name: 'São Roque' },
      { id: 3833, name: 'São Sebastião' },
      { id: 3834, name: 'São Sebastião da Grama' },
      { id: 3835, name: 'São Simão' },
      { id: 3836, name: 'São Vicente' },
      { id: 3837, name: 'Sarapuí' },
      { id: 3838, name: 'Sarutaiá' },
      { id: 3839, name: 'Sebastianópolis do Sul' },
      { id: 3840, name: 'Serra Azul' },
      { id: 3842, name: 'Serra Negra' },
      { id: 3841, name: 'Serrana' },
      { id: 3843, name: 'Sertãozinho' },
      { id: 3844, name: 'Sete Barras' },
      { id: 3845, name: 'Severínia' },
      { id: 3846, name: 'Silveiras' },
      { id: 3847, name: 'Socorro' },
      { id: 3848, name: 'Sorocaba' },
      { id: 3849, name: 'Sud Mennucci' },
      { id: 3850, name: 'Sumaré' },
      { id: 3852, name: 'Suzanápolis' },
      { id: 3851, name: 'Suzano' },
      { id: 3853, name: 'Tabapuã' },
      { id: 3854, name: 'Tabatinga' },
      { id: 3855, name: 'Taboão da Serra' },
      { id: 3856, name: 'Taciba' },
      { id: 3857, name: 'Taguaí' },
      { id: 3858, name: 'Taiaçu' },
      { id: 3859, name: 'Taiúva' },
      { id: 3860, name: 'Tambaú' },
      { id: 3861, name: 'Tanabi' },
      { id: 3862, name: 'Tapiraí' },
      { id: 3863, name: 'Tapiratiba' },
      { id: 3864, name: 'Taquaral' },
      { id: 3865, name: 'Taquaritinga' },
      { id: 3866, name: 'Taquarituba' },
      { id: 3867, name: 'Taquarivaí' },
      { id: 3868, name: 'Tarabai' },
      { id: 3869, name: 'Tarumã' },
      { id: 3870, name: 'Tatuí' },
      { id: 3871, name: 'Taubaté' },
      { id: 3872, name: 'Tejupá' },
      { id: 3873, name: 'Teodoro Sampaio' },
      { id: 3874, name: 'Terra Roxa' },
      { id: 3875, name: 'Tietê' },
      { id: 3876, name: 'Timburi' },
      { id: 3877, name: 'Torre de Pedra' },
      { id: 3878, name: 'Torrinha' },
      { id: 3879, name: 'Trabiju' },
      { id: 3880, name: 'Tremembé' },
      { id: 3881, name: 'Três Fronteiras' },
      { id: 3882, name: 'Tuiuti' },
      { id: 3883, name: 'Tupã' },
      { id: 3884, name: 'Tupi Paulista' },
      { id: 3885, name: 'Turiúba' },
      { id: 3886, name: 'Turmalina' },
      { id: 3887, name: 'Ubarana' },
      { id: 3888, name: 'Ubatuba' },
      { id: 3889, name: 'Ubirajara' },
      { id: 3890, name: 'Uchoa' },
      { id: 3891, name: 'União Paulista' },
      { id: 3892, name: 'Urânia' },
      { id: 3893, name: 'Uru' },
      { id: 3894, name: 'Urupês' },
      { id: 3895, name: 'Valentim Gentil' },
      { id: 3896, name: 'Valinhos' },
      { id: 3897, name: 'Valparaíso' },
      { id: 3898, name: 'Vargem' },
      { id: 3899, name: 'Vargem Grande do Sul' },
      { id: 3900, name: 'Vargem Grande Paulista' },
      { id: 3901, name: 'Várzea Paulista' },
      { id: 3902, name: 'Vera Cruz' },
      { id: 3903, name: 'Vinhedo' },
      { id: 3904, name: 'Viradouro' },
      { id: 3905, name: 'Vista Alegre do Alto' },
      { id: 3906, name: 'Vitória Brasil' },
      { id: 3907, name: 'Votorantim' },
      { id: 3908, name: 'Votuporanga' },
      { id: 3909, name: 'Zacarias' },
    ],
  },
  {
    id: 7,
    name: 'TO',
    fullname: 'Tocantins',
    cities: [
      { id: 443, name: 'Palmas' },
      { id: 311, name: 'Abreulândia' },
      { id: 312, name: 'Aguiarnópolis' },
      { id: 313, name: 'Aliança do Tocantins' },
      { id: 314, name: 'Almas' },
      { id: 315, name: 'Alvorada' },
      { id: 316, name: 'Ananás' },
      { id: 317, name: 'Angico' },
      { id: 318, name: 'Aparecida do Rio Negro' },
      { id: 319, name: 'Aragominas' },
      { id: 320, name: 'Araguacema' },
      { id: 321, name: 'Araguaçu' },
      { id: 322, name: 'Araguaína' },
      { id: 323, name: 'Araguanã' },
      { id: 324, name: 'Araguatins' },
      { id: 325, name: 'Arapoema' },
      { id: 326, name: 'Arraias' },
      { id: 327, name: 'Augustinópolis' },
      { id: 328, name: 'Aurora do Tocantins' },
      { id: 329, name: 'Axixá do Tocantins' },
      { id: 330, name: 'Babaçulândia' },
      { id: 331, name: 'Bandeirantes do Tocantins' },
      { id: 332, name: 'Barra do Ouro' },
      { id: 333, name: 'Barrolândia' },
      { id: 334, name: 'Bernardo Sayão' },
      { id: 335, name: 'Bom Jesus do Tocantins' },
      { id: 336, name: 'Brasilândia do Tocantins' },
      { id: 337, name: 'Brejinho de Nazaré' },
      { id: 338, name: 'Buriti do Tocantins' },
      { id: 339, name: 'Cachoeirinha' },
      { id: 340, name: 'Campos Lindos' },
      { id: 341, name: 'Cariri do Tocantins' },
      { id: 342, name: 'Carmolândia' },
      { id: 343, name: 'Carrasco Bonito' },
      { id: 344, name: 'Caseara' },
      { id: 345, name: 'Centenário' },
      { id: 347, name: 'Chapada da Natividade' },
      { id: 346, name: 'Chapada de Areia' },
      { id: 348, name: 'Colinas do Tocantins' },
      { id: 407, name: 'Colméia' },
      { id: 349, name: 'Combinado' },
      { id: 350, name: 'Conceição do Tocantins' },
      { id: 351, name: 'Couto Magalhães' },
      { id: 352, name: 'Cristalândia' },
      { id: 353, name: 'Crixás do Tocantins' },
      { id: 354, name: 'Darcinópolis' },
      { id: 355, name: 'Dianópolis' },
      { id: 356, name: 'Divinópolis do Tocantins' },
      { id: 357, name: 'Dois Irmãos do Tocantins' },
      { id: 358, name: 'Dueré' },
      { id: 359, name: 'Esperantina' },
      { id: 360, name: 'Fátima' },
      { id: 361, name: 'Figueirópolis' },
      { id: 362, name: 'Filadélfia' },
      { id: 363, name: 'Formoso do Araguaia' },
      { id: 364, name: 'Fortaleza do Tabocão' },
      { id: 365, name: 'Goianorte' },
      { id: 366, name: 'Goiatins' },
      { id: 367, name: 'Guaraí' },
      { id: 368, name: 'Gurupi' },
      { id: 369, name: 'Ipueiras' },
      { id: 370, name: 'Itacajá' },
      { id: 371, name: 'Itaguatins' },
      { id: 372, name: 'Itapiratins' },
      { id: 373, name: 'Itaporã do Tocantins' },
      { id: 374, name: 'Jaú do Tocantins' },
      { id: 375, name: 'Juarina' },
      { id: 376, name: 'Lagoa da Confusão' },
      { id: 377, name: 'Lagoa do Tocantins' },
      { id: 378, name: 'Lajeado' },
      { id: 379, name: 'Lavandeira' },
      { id: 380, name: 'Lizarda' },
      { id: 381, name: 'Luzinópolis' },
      { id: 382, name: 'Marianópolis do Tocantins' },
      { id: 383, name: 'Mateiros' },
      { id: 384, name: 'Maurilândia do Tocantins' },
      { id: 385, name: 'Miracema do Tocantins' },
      { id: 386, name: 'Miranorte' },
      { id: 387, name: 'Monte do Carmo' },
      { id: 388, name: 'Monte Santo do Tocantins' },
      { id: 390, name: 'Muricilândia' },
      { id: 391, name: 'Natividade' },
      { id: 392, name: 'Nazaré' },
      { id: 393, name: 'Nova Olinda' },
      { id: 394, name: 'Nova Rosalândia' },
      { id: 395, name: 'Novo Acordo' },
      { id: 396, name: 'Novo Alegre' },
      { id: 397, name: 'Novo Jardim' },
      { id: 398, name: 'Oliveira de Fátima' },
      { id: 443, name: 'Palmas' },
      { id: 399, name: 'Palmeirante' },
      { id: 389, name: 'Palmeiras do Tocantins' },
      { id: 400, name: 'Palmeirópolis' },
      { id: 401, name: 'Paraíso do Tocantins' },
      { id: 402, name: 'Paranã' },
      { id: 403, name: "Pau D'Arco" },
      { id: 404, name: 'Pedro Afonso' },
      { id: 405, name: 'Peixe' },
      { id: 406, name: 'Pequizeiro' },
      { id: 408, name: 'Pindorama do Tocantins' },
      { id: 409, name: 'Piraquê' },
      { id: 410, name: 'Pium' },
      { id: 411, name: 'Ponte Alta do Bom Jesus' },
      { id: 412, name: 'Ponte Alta do Tocantins' },
      { id: 413, name: 'Porto Alegre do Tocantins' },
      { id: 414, name: 'Porto Nacional' },
      { id: 415, name: 'Praia Norte' },
      { id: 416, name: 'Presidente Kennedy' },
      { id: 417, name: 'Pugmil' },
      { id: 418, name: 'Recursolândia' },
      { id: 419, name: 'Riachinho' },
      { id: 420, name: 'Rio da Conceição' },
      { id: 421, name: 'Rio dos Bois' },
      { id: 422, name: 'Rio Sono' },
      { id: 423, name: 'Sampaio' },
      { id: 424, name: 'Sandolândia' },
      { id: 425, name: 'Santa Fé do Araguaia' },
      { id: 426, name: 'Santa Maria do Tocantins' },
      { id: 427, name: 'Santa Rita do Tocantins' },
      { id: 428, name: 'Santa Rosa do Tocantins' },
      { id: 429, name: 'Santa Tereza do Tocantins' },
      { id: 430, name: 'Santa Terezinha do Tocantins' },
      { id: 431, name: 'São Bento do Tocantins' },
      { id: 432, name: 'São Félix do Tocantins' },
      { id: 433, name: 'São Miguel do Tocantins' },
      { id: 434, name: 'São Salvador do Tocantins' },
      { id: 435, name: 'São Sebastião do Tocantins' },
      { id: 436, name: 'São Valério da Natividade' },
      { id: 437, name: 'Silvanópolis' },
      { id: 438, name: 'Sítio Novo do Tocantins' },
      { id: 439, name: 'Sucupira' },
      { id: 440, name: 'Taguatinga' },
      { id: 441, name: 'Taipas do Tocantins' },
      { id: 442, name: 'Talismã' },
      { id: 444, name: 'Tocantínia' },
      { id: 445, name: 'Tocantinópolis' },
      { id: 446, name: 'Tupirama' },
      { id: 447, name: 'Tupiratins' },
      { id: 448, name: 'Wanderlândia' },
      { id: 449, name: 'Xambioá' },
    ],
  },
];

const getUFsList = () => UFS;

const getCitiesList = (iduf) => {
  const uf = UFS_CDDS.find((e) => e.id === Number.parseInt(iduf));
  return uf.cities;
};

const getCaptal = (iduf) => {
  const uf = UFS_CDDS.find((e) => e.id === Number.parseInt(iduf));
  return uf.cities[0];
};

const getStateName = (iduf) => {
  const uf = UFS.find((e) => e.id === Number.parseInt(iduf));
  return uf.name;
};

const getCityName = (iduf, idcdd, fullLocation = false) => {
  const uf = UFS_CDDS.find((e) => e.id === Number.parseInt(iduf));
  const cdd = uf.cities.find((e) => e.id === Number.parseInt(idcdd));
  return cdd.name + (fullLocation ? `/${uf.name}` : '');
};

export { getUFsList, getCitiesList, getStateName, getCityName, getCaptal };
