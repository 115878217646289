import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { selectCompanyData, selectCurrentBalance } from '../../stores/profileSlice';

import { ProfileStyled } from './ProfileStyled';
import { LAYOUT, EVENTS, LEADS } from '../../appConfig';

import { ButtonFlatM } from '../../components/misc/muimm';

import { MdDonutSmall, MdPlace } from 'react-icons/md';
import { AiFillHome } from 'react-icons/ai';
import { FaPhoneAlt } from 'react-icons/fa';
import { RiLogoutBoxLine } from 'react-icons/ri';

const { COLORS } = LAYOUT;
const { globalEvents } = EVENTS;
const iconSize = 30;

const Profile = () => {
  const companyData = useSelector(selectCompanyData);
  const creditBalance = parseInt(useSelector(selectCurrentBalance));

  return (
    <ProfileStyled>
      <div className="profile-block striped first-block">
        <div className="profile-block--avatar">
          <MdDonutSmall size={iconSize} />
        </div>
        <div className="profile-block--content">
          <div className="profile-block--text">
            <div className="profile-block--text-title">Saldo Atual</div>
            <div className={'credits-values ' + (creditBalance < LEADS.minCreditsAlert ? 'value-alert' : '')}>
              {creditBalance} crédito{creditBalance === 1 ? '' : 's'}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-block">
        <div className="profile-block--avatar">
          <MdPlace size={iconSize} />
        </div>
        <div className="profile-block--content">
          <div className="profile-block--text">
            <div className="profile-block--text-title">Nome Fantasia</div>
            <div className="profile-block--text-info">{companyData.name}</div>
          </div>
          <div className="profile-block--text">
            <div className="profile-block--text-title">Razão Social</div>
            <div className="profile-block--text-info">{companyData.corporateName}</div>
          </div>
          <div className="profile-block--text">
            <div className="profile-block--text-title">CNPJ</div>
            <div className="profile-block--text-info">{companyData.cnpjdados}</div>
          </div>
          <div className="profile-block--text">
            <div className="profile-block--text-title">Responsável</div>
            <div className="profile-block--text-info">{companyData.owner}</div>
          </div>
        </div>
      </div>

      <div className="profile-block striped">
        <div className="profile-block--avatar">
          <AiFillHome size={iconSize} />
        </div>
        <div className="profile-block--content">
          <div className="profile-block--text">
            <div className="profile-block--text-title">Contato</div>
            <div className="profile-block--text-info">{companyData.contact}</div>
          </div>
          <div className="profile-block--text">
            <div className="profile-block--text-title">E-mail</div>
            <div className="profile-block--text-info">{companyData.email}</div>
          </div>
        </div>
      </div>

      <div className="profile-block">
        <div className="profile-block--avatar">
          <FaPhoneAlt size={iconSize} />
        </div>
        <div className="profile-block--content">
          <div className="profile-block--text">
            <div className="profile-block--text-title">Endereço</div>
            {companyData.address !== '' && <div className="profile-block--text-info">{companyData.address}</div>}
            {companyData.complement !== '' && <div className="profile-block--text-info">{companyData.complement}</div>}
            {companyData.district !== '' && <div className="profile-block--text-info">{companyData.district}</div>}
            <div className="profile-block--text-info">{companyData.city}</div>
          </div>
        </div>
      </div>

      <div className="profile-block profile-logout striped">
        <div className="profile-block--avatar">
          <RiLogoutBoxLine size={iconSize} />
        </div>
        <div className="profile-block--content">
          <div className="profile-block--text">
            <div className="profile-block--text-title">Desconectar conta</div>
            <div>
              Lembre-se: Não é necessário desconectar de sua conta cada vez que parar de usar o aplicativo do Muda Muda (a menos que deseje conectar
              com outro CNPJ)
            </div>
            <ButtonFlatM
              color={COLORS.app.white}
              colorBg={COLORS.app.pink}
              width="100%"
              height="25px"
              fontSize="16px"
              margin="15px 0 0"
              padding="8px 2px"
              onClick={() => {
                globalEvents.logOut();
              }}
            >
              DESCONECTAR CONTA
            </ButtonFlatM>
          </div>
        </div>
      </div>
    </ProfileStyled>
  );
};

export default Profile;
