import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const LeadContentWindowStyled = styled.div`
  // largura assume a largura total da tela
  width: 100vw;
  height: 100vh;

  // posiciona o conteúdo fixado no topo da tela
  z-index: 10000;
  position: fixed;
  top: 0;
  // aplica a posição horizontal conforme o parametro passado por "isOpen"
  left: ${(props) => (props.isOpen ? '0' : '100vw')};

  // aplica animação de transição de 0.3s para a posição horizontal
  transition: left 0.3s;

  display: grid;
  grid-template-rows: 60px auto;
  grid-template-columns: 100%;
  grid-template-areas:
    'header'
    'window';

  // cor de fundo branco
  background-color: #fff;

  .content-header {
    grid-area: header;
    display: flex;
    justify-content: stretch;
    align-items: stretch;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
    z-index: 10;
  }

  .content-window {
    grid-area: window;

    .content-window--frame,
    .content-window--loading {
      width: 100%;
      height: 100%;
    }

    .content-window--frame {
      display: none;

      &.frame-loaded {
        display: block;
      }
    }

    .content-window--loading {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      padding-top: 80px;

      .window-loading--text {
        margin-top: 20px;
        color: ${COLORS.app.greyTextButton};
      }

      &.frame-loaded {
        display: none;
      }
    }
  }
`;

export { LeadContentWindowStyled };
