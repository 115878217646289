import styled from 'styled-components';
import { LAYOUT } from '../../appConfig';

const { COLORS } = LAYOUT;

const HeaderStyled = styled.div`
  width: 100%;
  // utiliza display grid com uma row e duas colunas para inserir o ícone à direita e o conteúdo à esquerda
  display: grid;
  grid-template-rows: 100%;
  grid-template-columns: 50px auto 50px;
  grid-template-areas: 'icon content addon';

  // aplica o background conforme o parametro passado
  background-color: ${(props) => (props.bgColor ? props.bgColor : COLORS.app.greyBg)};

  // ícone
  .header-icon {
    grid-area: icon;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // conteúdo
  .header-content {
    grid-area: content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: bold;
    font-size: 20px;
  }

  // add on
  .header-addon {
    grid-area: addon;
    display: flex;
    justify-content: center;
    align-items: center;

    // ao clicar no ícone interno faz o elemento girar 360 graus
    .header-addon--icon {
      height: 28px;
      transition: transform 0.3s;      
      
      &.rotate-icon {
        transform: rotate(360deg);
      }
  }
`;

export { HeaderStyled };
