import styled from 'styled-components';

import { LAYOUT } from '../../../appConfig';

const { COLORS } = LAYOUT;

const ModalConfigNotificationStyled = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  .modal-confignot--icon {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${COLORS.components.orange_2};
  }

  .modal-confignot--text {
    text-align: center;
    line-height: 1.3;
    margin: 15px 0 30px;
    font-size: 17px;

    .confignot-text--title {
      font-weight: bold;
      font-size: 20px;
      margin-bottom: 10px;
    }
  }

  .modal-confignot--btn {
    text-align: center;
    font-size: 20px;
    line-height: 1.2;

    .lc-text {
      text-transform: lowercase !important;
    }
  }
`;

export { ModalConfigNotificationStyled };
