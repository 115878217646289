import React from 'react';
import logotruck from '../../assets/images/truck.svg';

const LogoTruck = ({ width }) => {
  return (
    <img
      src={logotruck}
      alt="logo"
      width={width ?? '100%'}
    />
  );
};

export default LogoTruck;
